<template>
<div>
    <main class="form-signin w-100 m-auto loading-section">
        <div class="text-center loading-section">
                <div class="spinner-border" style="width: 4rem; height: 4rem;" role="status">
                    <span class="visually-hidden">{{ $t('loadingText') }}</span>
                </div>
            </div>
    </main>
    
    <main class="form-signin w-100 m-auto error-section">
        <section class="no-position">
            <i class="fa-solid fa-face-frown fs-1 d-flex justify-content-center mb-4 icon error-icon"></i>
            <h1 class="h3 mb-3 text-center">{{ $t('careerPage.error[0]') }}</h1>

            <small class="fs-5 d-block justify-content-center text-center error-text">{{ $t('careerPage.error[1]') }}</small>
        </section>
    </main>

    <div class="form-section">
        <div class="px-4 text-center mb-5">
            <h1 class="text-center mt-5 p-1" style="text-decoration: underline solid var(--zerduz-text-color) 10px; text-decoration-skip-ink: none; text-underline-offset: -0.25rem;">
                {{ $t('careerPage.header') }}</h1>
        </div>

        <div class="container mb-4 mt-5 col-md-10 col-lg-6 col-sm-12 col-12">
            <div class="row gx-4">
                <div class="col-md-6 mx-auto">
                    <form class="row g-3" @submit.prevent="submitForm">
                        <div class="col-md-12 col-lg-6 col-6">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control shadow-sm" id="name" v-model="formData.first_name" :placeholder="$t('careerPage.form.name')">
                                <label for="name">{{ $t('careerPage.form.name') }}</label>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-6 col-6">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control shadow-sm" id="last_name" v-model="formData.last_name" :placeholder="$t('careerPage.form.surname')">
                                <label for="last_name">{{ $t('careerPage.form.surname') }}</label>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6 col-md-12 col-sm-12">
                            <div class="form-floating mb-3">
                                <input type="email" class="form-control shadow-sm" id="email" v-model="formData.email" :placeholder="$t('careerPage.form.email')">
                                <label for="email">{{ $t('careerPage.form.email') }}</label>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6 col-md-12 col-sm-12">
                            <div class="form-floating mb-3">
                                <input type="tel" ref="phone" style="height: 56px;" @input="validatePhone" @keypress="isNumber($event)" v-model="formData.phone" class="form-control shadow-sm" id="phone">
                                <span v-if="isPhoneValid == false" class="mt-2 fw-normal text-danger"><span v-if="phoneValidText && phoneValidText != ''">{{ phoneValidText }}</span></span>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-floating mb-3">
                                <select class="form-select shadow-sm" v-model="formData.pid" id="positionSelect">
                                    <option v-for="position in positions" :key="position.id"
                                            :value="position.id">{{
                                                position.position }}</option>
                                </select>
                                <label for="positionSelect">{{ $t('careerPage.form.position') }}</label>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-floating mb-3">
                                <div class="input-group mb-3 shadow-sm">
                                    <label class="input-group-text" for="company-cv">CV</label>
                                    <label tabindex="0" class="form-control py-3" role="button" id="logo-input-label">
                                        <span class="text-break">{{ formData.cv ? formData.cv : $t('careerPage.selectFile') }}</span>
                                        <input type="file" accept=".pdf, .doc, .docx" id="company-cv" class="d-none" @change="onFileSelected" required>
                                    </label>
                                    <button v-if="formData.cv" class="btn btn-outline-primary text-zerduz" type="button" id="company-cv" @click="resetFileInput"><i class="fa-solid fa-xmark fa-2xl"></i></button>
                                </div>
                                <p v-if="fileSizeError" class="text-danger">{{ fileSizeError }}</p>
                            </div>
                        </div>
                        <div id="alertbox"></div>
                        <div class="col-12 text-center">
                            <button type="submit" class="btn btn-primary btn-lg rounded-pill shadow-sm">{{ $t('careerPage.form.btn') }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import $ from 'jquery';
import { jobApply } from '@/helpers/api';
import {
    alert
} from "@/helpers/funcs";
import {
    getOpenPositions
} from '@/helpers/api';

export default {
    name: "CareerPage",
    data() {
        return {
            token: window.localStorage.getItem('token'),
            userId: window.localStorage.getItem('userId'),
            formData: {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                pid: '',
                cv: '',
            },
            file: '',
            positions: null,
            phoneInputID: "#phone",
            iti: null,
            formattedPhoneNumber: "",
            isPhoneValid: null,
            phoneValidText: this.$t('errors.validation.phoneValidText[]'),
            fileSizeError: '', 
            maxFileSize: 5 * 1024 * 1024,
        };
    },
    methods: {
        validatePhone() {
            if (this.iti.isValidNumber()) {
                this.isPhoneValid = true;
                this.phoneValidText = "";
            } else {
                const errorCode = this.iti.getValidationError();
                if (errorCode == 1) {
                this.phoneValidText = this.$t('errors.validation.phoneValidText[1]');
                } else if (errorCode == 2) {
                this.phoneValidText = this.$t('errors.validation.phoneValidText[2]');
                } else if (errorCode == 3) {
                this.phoneValidText = this.$t('errors.validation.phoneValidText[3]');
                } else {
                this.phoneValidText = this.$t('errors.validation.phoneValidText[0]');
                }
                this.isPhoneValid = false;
            }
        },
        isNumber(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        async fetchData() {
            try {
                const {
                    success,
                    response
                } = await getOpenPositions();
                if (success) {
                    this.positions = response;
                    if (this.positions.data) {
                        if (this.positions.data["positions data"]) {
                            this.positions = this.positions.data["positions data"];
                            if (this.positions.length > 0) {
                                this.formData.pid = this.positions[0].id;
                                $('.loading-section').fadeOut(function () {
                                    $('.form-section').fadeIn();
                                });
                            } else {
                                $('.loading-section').fadeOut(function () {
                                    $('.error-section').fadeIn();
                                });
                            }
                        } else {
                            $('.loading-section').fadeOut(function () {
                                $('.error-section').fadeIn();
                            });
                        }
                    } else {
                        $('.loading-section').fadeOut(function () {
                            $('.error-section').fadeIn();
                        });
                    }
                }
            } catch (error) {
                $('.loading-section').fadeOut(function () {
                    $('.error-section').fadeIn();
                });
            }
        },
        async submitForm() {
            try {
                if (this.isPhoneValid) {
                    if (this.formData.phone.length > 0) {
                        if (this.formData.phone.charAt(0) === '0') {
                            this.formData.phone = this.formData.phone.slice(1);
                        }
                        var selectedCountryData = this.iti.getSelectedCountryData();
                        var countryDialCode = selectedCountryData.dialCode;
                        this.formattedPhoneNumber = `${countryDialCode}${this.formData.phone}`;
                        this.formattedPhoneNumber = this.formattedPhoneNumber.replace(/\s/g, '');
                    }
                
                    const { success } = await jobApply(this.formData.first_name, this.formData.last_name, this.formData.email, this.formattedPhoneNumber, this.formData.pid, this.file);
                    if (success) {
                        alert("#alertbox", this.$t('errors.career.applyIsSuccessfull'), "success", "mt-2");
                    } else {
                        alert("#alertbox", this.$t('errors.career.applyError'), "danger", "mt-2");
                    }
                } else {
                    this.$refs.phone.focus();
                    if (this.phoneValidText != null && this.phoneValidText != '') {
                        alert("#alertbox", this.phoneValidText, "danger", "mt-2");
                    }
                }
            } catch (error) {
                alert("#alertbox", this.$t('errors.career.applyError'), "danger", "mt-2");
            }
        },
        onFileSelected(event) {
            this.file = event.target.files[0];
            if (this.file && this.file.size > this.maxFileSize) {
                this.fileSizeError = this.$t('errors.career.fileSizeError');
                this.formData.cv = '';
            } else {
                this.formData.cv = this.file.name;
                this.fileSizeError = '';
            }
        },
        resetFileInput() {
            this.formData.cv = '';
        },
    },
    mounted() {
        this.fetchData();
        var input = document.querySelector(this.phoneInputID);
        this.iti = window.intlTelInput(input, {
        autoPlaceholder: "polite",
        customPlaceholder: function(placeholder) {
            if (placeholder.charAt(0) === '0') {
                return placeholder.slice(1);
            } else {
                return placeholder;
            }
        },
        preferredCountries: ["tr"],
        initialCountry: "auto",
        geoIpLookup: callback => {
            fetch("https://ipapi.co/json")
            .then(res => res.json())
            .then(data => callback(data.country_code))
            .catch(() => callback("tr"));
        },
        utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/18.1.5/js/utils.js"
        });
    }
};
</script>

<style scoped src="../assets/css/career.css"/>
<style scoped>
@import '../assets/css/intlTelInput.css';
</style>