<template>
<div>
    <div class="px-4 text-center">
        <h1 class="text-center mt-5 p-1">{{ $t('guidePage.header') }}</h1>
        <div class="col-lg-6 mx-auto">
            <p class="lead mb-5 fw-normal">{{ $t('guidePage.description') }}</p>
        </div>
    </div>

    <div class="container mb-4 mt-5 mx-auto">
        <transition name="fade" mode="in-out">
            <div v-if="!loading && !error" class="accordion accordion-flush" id="accordionFlushExample">
                <div class="accordion-item shadow-sm" v-for="guide in guides" :key="guide.slug">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#flush-' + guide.id" aria-expanded="false" :aria-controls="'flush-' + guide.id">
                            <span class="mt-3">
                                <h4 class="text-body-emphasis lh-1 mb-3 fw-normal">{{ guide.title }} <a :href="'/Guide/' + guide.slug" target="_blank"><span class="badge text-bg-light ms-2 px-2" id="new-tab"><i class="fa-solid fa-arrow-up-right-from-square fa-2xs"></i></span></a></h4>
                            </span>
                        </button>
                    </h2>
                    <div :id="'flush-' + guide.id" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body p-0">
                            <div>
                                <div class="row flex-lg-row-reverse align-items-center p-5">
                                    <div class="col-12 col-sm-12 col-lg-6 mx-auto">
                                        <iframe class="shadow" width="100%" height="315" :src="guide.video + '?modestbranding=0&showinfo=0'" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                    </div>
                                    <div class="col-lg-6" v-html="guide.content"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>

        <div class="text-center loading-section" v-if="loading">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">{{ $t('loadingText') }}</span>
            </div>
        </div>

        <main v-if="error && !loading" class="form-signin w-100 m-auto error-section">
            <section class="no-position">
                <h5 class="text-center">{{ $t('guidePage.error.title') }}</h5>

                <small class="fs-5 d-block justify-content-center text-center error-text">{{ $t('guidePage.error.text') }}</small>
            </section>
        </main>

    </div>
</div>
</template>

<script>
import {
    getGuides
} from '@/helpers/api';

export default {
    name: "GuidePage",
    data() {
        return {
            // guides: this.$tm('guidePage.guideElements'),
            guides: [],
            loading: true,
            error: false
        }
    },
    async mounted() {
        const {
            success,
            response
        } = await getGuides(this.$i18n.locale);

        if (success) {
            if (response && response.data && (response.data.length > 0)) {
                this.guides = response.data;

                this.$nextTick(() => {
                    this.error = false;
                    this.loading = false;
                });
            } else {
                this.error = true;
                this.loading = false;
            }
        } else {
            this.error = true;
            this.loading = false;
        }
    },
};
</script>

<style scoped src="../assets/css/guide.css"/>
