import { createRouter, createWebHistory } from 'vue-router';
import store from '../store'
import axios from 'axios';
import { sendViewEvent } from '@/helpers/tracker';
import { showToast } from '@/helpers/funcs';
import { i18n } from '@/main';

var lang = (data) => { 
  return i18n.global.t(data);
};

import HomeView from '@/views/Home.vue';
import Checkout from '@/views/Checkout.vue';
import Apps from '@/views/Apps.vue';
import PurchaseIT from '@/views/Purchase.vue';
import LoginPage from '@/views/Login.vue';
import RegisterPage from '@/views/Register.vue';
import Specs from '@/views/Specs.vue';
import Cart from '@/views/Cart.vue';
import GizlilikveCerez from '@/views/GizlilikveCerez.vue';
import TeslimatveIade from '@/views/TeslimatveIade.vue';
import MesafeliSatisSozlesmesi from '@/views/MesafeliSatisSozlesmesi.vue';
import KisiselVerilerinKorunmasi from '@/views/KisiselVerilerinKorunmasi.vue';
import SSS from '@/views/SSS.vue';
import Sellers from '@/views/Sellers.vue';
import Contact from '@/views/Contact.vue';
import ResetPass from '@/views/ResetPass.vue';
import NewPassword from '@/views/NewPass.vue';
import VerifyEmail from '@/views/VerifyEmail.vue';
import VerifySms from '@/views/VerifySms.vue';
import AccountOrders from '@/views/AccountOrders.vue';
import AccountOrderDetails from '@/views/AccountOrderDetails.vue';
import AccountSettings from '@/views/AccountSettings.vue';
import AccountSettingsPassword from '@/views/AccountSettingsPassword.vue';
import AccountSettingsCompany from '@/views/AccountSettingsCompany.vue';
import AccountHelp from '@/views/AccountHelp.vue';
import AccountAddresses from '@/views/AccountAddresses.vue';
import AccountAddressesAdd from '@/views/AccountAddressesAdd.vue';
import AccountAddressesEdit from '@/views/AccountAddressesEdit.vue';
import AccountZerduzCurrency from '@/views/AccountZerduzSettingsCurrency.vue';
import AboutUs from '@/views/About.vue';
import Career from '@/views/Career.vue';
import Guide from '@/views/Guide.vue';
import GuideDetails from '@/views/GuideDetails.vue';
import PaymentResult from '@/views/PaymentResult.vue';
import OrderTracking from '@/views/OrderTracking.vue';
import notFound from '@/views/404.vue';


const routes = [
  { path: '/', name: "Anasayfa", component: HomeView, meta: { title: "router[0].title" } },
  {
    path: '/Account', redirect: '/Account/Orders',
    children: [
      { path: 'Orders', name: "Siparişlerim", component: AccountOrders, meta: { requiresLogin: true, title: "router[1].title"} },
      {
        path: 'Order', redirect: '/Account/Orders',
        children: [
          { path: ':id', name: "Sipariş Detayları", component: AccountOrderDetails, meta: { requiresLogin: true, title: "router[2].title" } }
        ]
      },
      {
        path: 'Settings',
        children: [
          { path: '', name: "Ayarlar", component: AccountSettings, meta: { requiresLogin: true, title: "router[3].title" } },
          { path: 'Password', name: "Şifre Değiştir", component: AccountSettingsPassword, meta: { requiresLogin: true, title: "router[4].title" } },
          { path: 'Company', name: "Firma Bilgileri", component: AccountSettingsCompany, meta: { requiresLogin: true, title: "router[5].title" } }
        ]
      },
      { path: 'Help', name: "Yardım", component: AccountHelp, meta: { requiresLogin: true, title: "router[6].title" } },
      {
        path: 'Addresses',
        children: [
          { path: '', name: "Adreslerim", component: AccountAddresses, meta: { requiresLogin: true, title: "router[7].title" } },
          { path: 'Add', name: "Adres Ekle", component: AccountAddressesAdd, meta: { requiresLogin: true, title: "router[8].title" } },
          { path: 'Edit/:id', name: "Adres Düzenle", component: AccountAddressesEdit, meta: { requiresLogin: true, title: "router[9].title" } }
        ]
      },
      {
        path: 'Zerduz', redirect: '/Account/Zerduz/Currency',
        children: [
          { path: 'Currency', name: "Zerduz Ayarları", component: AccountZerduzCurrency, meta: { requiresLogin: true, title: "router[3].title" } },
        ]
      },
    ]
  },
  { path: '/Specs', name: "Özellikler", component: Specs, meta: { title: "router[10].title" } },
  { path: '/Checkout', name: "Ödeme", component: Checkout, meta: { title: "router[11].title" } },
  { path: '/Apps', name: "Uygulamalar", component: Apps, meta: { title: "router[12].title" } },
  { path: '/Purchase', name: "Satın Al", component: PurchaseIT, meta: { title: "router[13].title" } },
  { path: '/Login', name: "Giriş Yap", component: LoginPage, meta: { title: "router[14].title" } },
  { path: '/Register', name: "Kayıt Ol", component: RegisterPage, meta: { title: "router[15].title" } },
  { path: '/Cart', name: "Sepet", component: Cart, meta: { title: "router[16].title" } },
  { path: '/GizlilikveCerez', name: "Gizlilik ve Çerez", component: GizlilikveCerez, meta: { title: "router[17].title" } },
  { path: '/TeslimatveIade', name: "Teslimat ve İade", component: TeslimatveIade, meta: { title: "router[18].title" } },
  { path: '/MesafeliSatisSozlesmesi', name: "Mesafeli Satış Sözleşmesi", component: MesafeliSatisSozlesmesi, meta: { title: "router[19].title" } },
  { path: '/KisiselVerilerinKorunmasi', name: "Kişisel Verilerin Korunması", component: KisiselVerilerinKorunmasi, meta: { title: "router[20].title" } },
  { path: '/SSS', name: "Sıkça Sorulan Sorular", component: SSS, meta: { title: "router[21].title" } },
  { path: '/Sellers', name: "Bayiler", component: Sellers, meta: { title: "router[22].title" } },
  { path: '/Contact', name: "İletişim", component: Contact, meta: { title: "router[23].title" } },
  { path: '/Resetpass', name: "Şifremi Unuttum", component: ResetPass, meta: { title: "router[24].title" } },
  { path: '/new-password', name: "Yeni Şifre", component: NewPassword, meta: { title: "router[25].title" } },
  { path: '/verify-email', name: "E-Posta Onayı", component: VerifyEmail, meta: { title: "router[26].title" } },
  { path: '/verify-sms', name: "SMS Onayı", component: VerifySms, meta: { title: "router[27].title" } },
  { path: '/About', name: "Hakkımızda", component: AboutUs, meta: { title: "router[28].title" } },
  { path: '/Career', name: "Kariyer", component: Career, meta: { title: "router[29].title" } },
  { path: '/Guide', name: "Eğitim", component: Guide, meta: { title: "router[30].title" } },
  { path: '/Guide/:slug', name: "Eğitim Detayları", component: GuideDetails, meta: { title: "router[30].title" } },
  { path: '/PaymentResult', name: "Ödeme Sonucu", component: PaymentResult, meta: { requiresLogin: true, title: "router[31].title" } },
  { path: '/order-tracking', name: "Sipariş Takip", component: OrderTracking, meta: { title: "router[32].title" } },
  { path: '/Logout', name: "Çıkış Yap", },
  { path: "/404", name: "Sayfa Bulunamadı", component: notFound, meta: { title: "router[33].title" } },
];

export const router = createRouter({
  history: createWebHistory("/"),
  linkActiveClass: 'active',
  routes: routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
});

const pagesForNonAuthUsers = ['/login', '/register', '/new-password', '/resetpass'];

router.beforeEach(async (to, from, next) => {
  // console.log("To Name: ", to.name, " From Name: ", from.name);
  if (to.name !== from.name && from.name && to.name) {
    sendViewEvent(i18n.global.locale);
  }

  // var langChanged = false;
  if (to.query) {
    if (Object.keys(to.query).length > 0) {
      var langObject = Object.keys(to.query);
      if (langObject.includes('lang')) {
        var langs = ["tr", "en", "ar", "fr"];
        if (langs.includes(to.query.lang)) {
          i18n.global.locale = String(to.query.lang);
          localStorage.setItem('lang', String(to.query.lang));
          // langChanged = true;
          // console.log(langChanged);
        }
      }
    }
  }
  var token = localStorage.getItem('token');
  var userId = localStorage.getItem('userId');
  const expirationDate = localStorage.getItem('expirationDate');
  var expired = null;

  if (expirationDate) {
    const currentDate = new Date();
    expired = currentDate > new Date(Number(expirationDate));
  }

  if (expired == true) {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('cart');
    store.dispatch('changeCartItem', { cart: undefined });
    store.dispatch('logout');

    try {
      await axios.get('https://backend.zerduz.com/api/customer/logout', {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }
      });

      var previousRoute = to.path;
      if (previousRoute && !(to.matched.some(record => record.meta.requiresLogin)) && !(previousRoute == "/Cart" || previousRoute == "/Checkout")) {
        document.title = lang(to.meta.title) + " - Zerduz";
        next(previousRoute);
      } else if (previousRoute == "/Cart" || previousRoute == "/Checkout") {
        document.title = to.meta?.title ? lang(to.meta.title) + " - Zerduz" : 'Zerduz'
        router.go();
      } else {
        document.title = lang("router[14].title") + " - Zerduz"
        next('/Login');
      }
    } catch (error) {
      next();
    }
  }

  const requiresAuth = to.matched.some(record => record.meta.requiresLogin);
  const isLoginPage = pagesForNonAuthUsers.includes(to.path.toLowerCase());
  const isRouteExists = router.getRoutes().some(route => route.path.toLowerCase() === to.path.toLowerCase());

  if (requiresAuth && (!token || !userId)) {
    document.title = lang("router[14].title") + " - Zerduz";
    next({ path: '/Login', query: { r: to.fullPath } });
  } else if (isLoginPage && (token && userId)) {
    document.title = lang("router[1].title") + " - Zerduz"
    next('/Account');
  } else if ((token && userId) && to.path.toLowerCase() === '/logout') {
    previousRoute = from.path;
    try {
      await axios.get('https://backend.zerduz.com/api/customer/logout', {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }
      });
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      localStorage.removeItem('expirationDate');
      localStorage.removeItem('cart');
      store.dispatch('changeCartItem', { cart: undefined });
      store.dispatch('logout');
      showToast("Zerduz", "Başarıyla çıkış yapıldı!", 10000);
      if (previousRoute && !(from.matched.some(record => record.meta.requiresLogin)) && !(previousRoute == "/Cart" || previousRoute == "/Checkout")) {
        document.title = lang(from.meta.title) + " - Zerduz";
        next(previousRoute);
      } else if (previousRoute == "/Cart" || previousRoute == "/Checkout") {
        document.title = from.meta?.title ? lang(from.meta.title) + " - Zerduz" : 'Zerduz';
        router.go();
      } else if ((from.matched.some(record => record.meta.requiresLogin))) {
        document.title = lang("router[14].title") + " - Zerduz"
        next({ path: '/Login', query: { r: from.path } });
      } else {
        document.title = lang("router[0].title") + " - Zerduz"
        next('/');
      }
    } catch (error) {
      const authTimedOutErrors = ["Invalid token", "User not authorized or auth time out", "customer not activated"];
      if (authTimedOutErrors.includes(error.response.data.error)) {
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem('expirationDate');
        localStorage.removeItem('cart');
        store.dispatch('changeCartItem', { cart: undefined });
        store.dispatch('logout');
        showToast("Zerduz", "Başarıyla çıkış yapıldı!", 10000);
      }
      if (previousRoute && !(from.matched.some(record => record.meta.requiresLogin))) {
        document.title = lang(from.meta.title) + " - Zerduz"
        next(previousRoute);
      } else if (previousRoute && (from.matched.some(record => record.meta.requiresLogin))) {
        document.title = lang("router[14].title") + " - Zerduz"
        next('/Login');
      } else {
        document.title = lang("router[0].title") + " - Zerduz"
        next('/');
      }
    }
  } else if ((!token && !userId) && to.path.toLowerCase() === '/logout') {
    document.title = lang("router[33].title") + " - Zerduz"
    next('/Login');
  } else if (!isRouteExists && to.path.toLowerCase() != '/logout' && (!to.path.toLowerCase().includes('addresses/edit/') && !to.path.toLowerCase().includes('account/order/') && !to.path.toLowerCase().includes('guide/') )) {
    document.title = lang("router[14].title") + " - Zerduz"
    next('/404');
  } else {
    document.title = to.meta?.title ? lang(to.meta.title) + " - Zerduz" : 'Zerduz'
    next();
  }
});

router.afterEach(() => {
  window.enterTime = new Date();
  // console.log(window.enterTime);
});

export default router;
