<template>
<div>
    <div class="container py-3 main-section">
        <div class="row g-5">
            <div class="col-md-5 col-lg-4 order-md-last fixedElement rounded" style="/*transition: all 0.5 ease; position:fixed; right: 0; z-index:100;*/">
                <div class="mt-5 mt-lg-0">
                    <h4 class="justify-content-between align-items-center mb-3"><span class="text-primary">{{ $t('checkoutPage.header[0]') }}</span></h4>
                    <div class="card shadow-sm">
                        <div class="card-body p-4 pt-2">
                            <div class="table-responsive">
                                <table class="table table-borderless mb-0">
                                    <tbody>
                                        <tr>
                                            <td>{{ $t('productName') }}</td>
                                            <td class="text-end">{{ totalQuantity }} {{ $t('checkoutPage.summary.quantity') }}</td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('checkoutPage.summary.productTotalLabel') }}</td>
                                            <td class="text-end">{{ $store.state.price.currency }}{{ formatPrice(String((totalQuantity * $store.state.price.price.withoutTax).toFixed(2))) }}</td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('checkoutPage.summary.vatLabel') }}</td>
                                            <td class="text-end">{{ $store.state.price.currency }}{{ formatPrice(String(parseFloat(($store.state.price.price.tax) - $store.state.price.price.withoutTax) * totalQuantity).replace('.', ',')) }}</td>
                                        </tr>
                                        <!-- <tr>
                                                <td>İndirim Kuponu <br><small class="text-zerduz">ÖRNEK</small></td>
                                                <td class="text-end">- {{ $store.state.price.currency }}{{ (parseInt(totalQuantity) * $store.state.price.price.withoutTax) -
                                                    (parseInt(totalQuantity) *
                                                        $store.state.price.price.withoutTax) *
                                                    0.90 }}</td>
                                            </tr> -->
                                        <tr class="border-bottom">
                                            <td>{{ $t('checkoutPage.summary.shippingLabel') }}</td>
                                            <td class="text-end">{{ $store.state.price.currency }}{{ formatPrice(String((calculateCargo).toFixed(2))) }}</td>
                                        </tr>
                                        <tr class="bg-light">
                                            <th>{{ $t('checkoutPage.summary.totalLabel') }}</th>
                                            <td class="text-end">
                                                <span class="fw-bold text-zerduz">
                                                    {{ $store.state.price.currency }}{{ formatPrice(String(((parseFloat(totalQuantity) * $store.state.price.price.tax) + (calculateCargo)).toFixed(2))) }}
                                                </span>
                                                <!-- <span class="fw-bold text-zerduz">
                                                        {{ $store.state.price.currency }}{{ (parseInt(totalQuantity) * $store.state.price.price.withoutTax) - ((parseInt(totalQuantity) *
                                                            $store.state.price.price.withoutTax) - (parseInt(totalQuantity) * $store.state.price.price.withoutTax) * 0.90) + 10 }}
                                                    </span> -->
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="card shadow-sm">
                            <form>
                                <div class="input-group">
                                    <input type="text" class="form-control border-0" placeholder="İndirim Kuponu">
                                    <button type="submit" class="btn btn-light border-0">Uygula</button>
                                </div>
                            </form>

                        </div> -->
                    <!-- <div class="d-flex gap-2 justify-content-center shadow-sm">
                            <button class="btn btn-primary align-items-center btn-full-width btn-lg fw-medium"
                                type="button">Sepeti
                                Onayla</button>
                        </div> -->
                </div>
            </div>
            <div class="col-md-7 col-lg-8">
                <div class="mt-3">
                    <div class="progress px-1" style="height: 3px;">
                        <div class="progress-bar" role="progressbar" style="width: 0%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <div class="step-container d-flex justify-content-between">
                        <div class="step-circle" @click="displayStep(1)">1</div>
                        <div class="step-circle" @click="displayStep(2)">2</div>
                        <div class="step-circle" @click="displayStep(3)">3</div>
                    </div>
                </div>

                <div id="loading-spinner">
                    <div class="spinner-border m-5 d-flex mx-auto justify-content-center" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>

                <form id="multi-step-form">
                    <div class="step step-1">
                        <div class="login">
                            <h4 class="mb-3">{{ $t('checkoutPage.header[1]') }}</h4>
                            <main class="form-signin w-100 m-auto">
                                <form @submit.prevent="tryLogin" id="login-form">

                                    <div class="form-floating mb-3">
                                        <input type="text" class="form-control" id="mail" @input="checkInput" v-model="loginFormData.input" :placeholder="$t('loginPage.inputLabel')" required>
                                        <span v-if="isLoginInputValid == false" class="mt-3 fw-normal d-flex text-start text-danger px-3"><span v-if="loginValidationText && loginValidationText != ''">{{ loginValidationText }}</span></span>
                                        <label for="mail">{{ $t('checkoutPage.firstStep.login.email') }}</label>
                                    </div>
                                    <div class="form-floating mb-3">
                                        <input :type="showLoginPassword ? 'text' : 'password'" class="form-control" id="password" v-model="loginFormData.password" :placeholder="$t('checkoutPage.firstStep.login.password')" required>
                                        <span @click="toggleLoginPassword" toggle="#password-field" class="fa fa-fw field-icon toggle-password base-password text-zerduz" :class="{ 'fa-eye': !showLoginPassword, 'fa-eye-slash active': showLoginPassword }"></span>
                                        <label for="password">{{ $t('checkoutPage.firstStep.login.password') }}</label>
                                    </div>

                                    <div class="checkbox mb-3" id="remember-me-box">
                                        <label>
                                            <input type="checkbox" class="form-check-input" value="remember-me" id="remember-me" v-model="loginFormData.rememberme"> {{ $t('checkoutPage.firstStep.login.rememberMe') }}
                                        </label>
                                    </div>

                                    <div class="hstack gap-3">
                                        <button class="w-50 btn btn-outline-primary" type="button" @click="showRegisterForm">{{ $t('checkoutPage.firstStep.login.registerBtn') }}</button>
                                        <button class="w-50 btn btn-primary" type="submit">
                                            <span class="login-button-text">{{ $t('checkoutPage.firstStep.login.loginBtn') }}</span>
                                            <div class="btn-loading">
                                                <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                                <span class="visually-hidden" role="status">{{ $t('loadingText') }}</span>
                                            </div>
                                        </button>
                                    </div>

                                    <hr class="my-4">
                                    <div class="vstack">
                                        <small class="text-body-secondary">
                                            <router-link to="Resetpass" class="link-underline link-underline-opacity-0">{{ $t('checkoutPage.firstStep.login.forgotPassword') }}</router-link>
                                        </small>
                                    </div>
                                </form>
                            </main>
                        </div>
                        
                        <main class="form-signup">

                            <div class="card border-0">
                                <h4 class="fw-normal px-4 mt-4" id="register-back-button" @click="registerFormGoBack"><i class="fa-solid fa-arrow-left-long text-zerduz"></i>&nbsp;&nbsp;<small>{{ $t('checkoutPage.firstStep.register.goBack') }}</small></h4>
                                <div class="card-body m-2">
                                    <form ref="registerform" id="register-form">
                                        <div class="signup-step signup-step-1">
                                            <div class="row g-2">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <div class="form-floating mb-3">
                                                            <input type="text" class="form-control" required id="name" v-model="registerFormData.name"
                                                            :placeholder="$t('checkoutPage.firstStep.register.name')">
                                                            <label for="name">{{ $t('checkoutPage.firstStep.register.name') }}<span class="text-danger">*</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <div class="form-floating mb-3">
                                                            <input type="text" class="form-control" required id="surname" v-model="registerFormData.surname"
                                                            :placeholder="$t('checkoutPage.firstStep.register.surname')">
                                                            <label for="surname">{{ $t('checkoutPage.firstStep.register.surname') }}<span class="text-danger">*</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row g-2">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <div class="form-floating mb-3">
                                                            <input type="email" class="form-control" id="email" v-model="registerFormData.email"
                                                            :placeholder="$t('checkoutPage.firstStep.register.email')">
                                                            <label for="email">{{ $t('checkoutPage.firstStep.register.email') }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 justify-content-center d-flex">
                                                    <div class="form-group">
                                                        <div class="form-floating mb-3 register-phone">
                                                            <input type="tel" style="height: 56px;" class="form-control" id="phone" @input="validateRegisterPhone" @keypress="isNumber($event)" v-model="registerFormData.phone">
                                                            <p v-if="isRegisterPhoneValid == false" class="mt-2 fw-normal text-danger"><span v-if="registerPhoneValidText && registerPhoneValidText != ''">{{ registerPhoneValidText }}</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row g-2">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <div class="form-floating mb-3">
                                                            <input :type="showPassword ? 'text' : 'password'" class="form-control" required id="password" @input="validatePassword" v-model="registerFormData.password"
                                                            placeholder="123">
                                                            <span @click="togglePassword" toggle="#password-field" class="fa fa-fw field-icon toggle-password base-password text-zerduz" :class="{ 'fa-eye': !showPassword, 'fa-eye-slash active': showPassword }"></span>
                                                            <label for="password">{{ $t('checkoutPage.firstStep.register.password') }}<span class="text-danger">*</span></label>
                                                            <p v-if="isPasswordValid == false" class="mt-2 fw-normal text-danger">{{ $t('errors.validation.passwordValidText') }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <div class="form-floating mb-3">
                                                            <input :type="showPasswordConfirmation ? 'text' : 'password'" class="form-control" required id="password_confirmation"
                                                            v-model="registerFormData.password_confirmation" @input="validatePasswordConfirmation" placeholder="123">
                                                            <span @click="togglePasswordConfirmation" toggle="#password-field" class="fa fa-fw field-icon toggle-password text-zerduz confirmation" :class="{ 'fa-eye': !showPasswordConfirmation, 'fa-eye-slash active': showPasswordConfirmation }"></span>
                                                            <label for="password_confirmation">{{ $t('checkoutPage.firstStep.register.passwordConfirmation') }}<span class="text-danger">*</span></label>
                                                            <p v-if="isConfirmationPasswordValid == false" class="mt-2 fw-normal text-danger">{{ $t('errors.validation.passwordValidText') }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="signup-step signup-step-2">
                                            <div class="row g-2">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <div class="form-floating mb-3">
                                                            <input type="number" @keypress="isNumber($event)" @input="tcNoControl" class="form-control" id="tckno" v-model="registerFormData.tcno" placeholder="01234567890" maxlength="11">
                                                            <label for="tckno">{{ $t('checkoutPage.firstStep.register.id') }}</label>
                                                            <p v-if="isTcNoValid == false" class="mt-2 fw-normal text-danger">{{ tcNoValidText }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group justify-content-center d-flex">
                                                        <div class="form-floating mt-4 mt-md-0 mt-lg-0 mb-3">
                                                            <div class="form-check">
                                                            <input class="form-check-input" type="radio" value="1" name="flexRadioDefault"
                                                                id="personalAccount" v-model="registerFormData.accounttype">
                                                            <label class="form-check-label" for="personalAccount">
                                                                {{ $t('checkoutPage.firstStep.register.personalAccount') }}
                                                            </label>
                                                            </div>
                                                            <div class="form-check">
                                                            <input class="form-check-input" type="radio" value="2" name="flexRadioDefault" id="corporateAccount" v-model="registerFormData.accounttype">
                                                            <label class="form-check-label" for="corporateAccount">
                                                                {{ $t('checkoutPage.firstStep.register.corporateAccount') }}
                                                            </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row g-2">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <div class="form-floating mb-3" id="verginoTxtBox">
                                                            <input type="number" class="form-control" id="vergino" v-model="registerFormData.taxno"
                                                            placeholder="0123456789">
                                                            <label for="tckno">{{ $t('checkoutPage.firstStep.register.taxNo') }}</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <div class="form-floating mb-3" id="vergidairesiTxtBox">
                                                            <input type="text" class="form-control" id="vergidairesi" v-model="registerFormData.tax"
                                                            placeholder="Bakırköy Vergi Dairesi">
                                                            <label for="tckno">{{ $t('checkoutPage.firstStep.register.tax') }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="alertbox"></div>
                                        <div class="hstack gap-3 justify-content-center mt-3">
                                            <div class="p-2"><button class="btn btn-light shadow-sm" type="button" @click="prevRegisterStep"
                                                style="opacity: 0" id="prev-button"><i class="fa-solid fa-arrow-left-long"></i></button></div>
                                            <div class="p-2 text-zerduz ms-auto" id="dot-1" @click="displayRegisterStep(1)"><i class="fa-solid fa-circle-dot"
                                                id="dot"></i></div>
                                            <div class="p-2 text-secondary" id="dot-2" @click="displayRegisterStep(2)"><i class="fa-solid fa-circle fa-2xs"
                                                id="dot"></i>
                                            </div>
                                            <div class="p-2 ms-auto">
                                            <button class="btn btn-dark shadow-sm" type="button" @click="nextRegisterStep" id="next-button">
                                                <span class="login-button-text">{{ nextButtonText }}&numsp;<i class="fa-solid fa-arrow-right-long"></i></span>
                                                <div class="btn-loading">
                                                    <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                                    <span class="visually-hidden" role="status">{{ $t('loadingText') }}</span>
                                                </div>
                                            </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                </div>
                            
                        </main>
                    </div>

                    <div class="step step-2">
                        <div class="add-address">
                            <h4 class="mb-3"><i class="fa fa-chevron-left fa-2xs text-zerduz" @click="showAddAddressForm(true)" style="font-size:14px; cursor: pointer;"></i>
                                {{ $t('checkoutPage.secondStep.addAddress.header') }}</h4>
                            <form @submit.prevent="saveAddress">
                                <div class="row g-2 p-2">
                                    <div class="col-lg-4 col-md-6 col-sm-12">
                                        <div class="form-floating">
                                            <input type="text" class="form-control shadow-sm" v-model="addressData.address_name" placeholder="Ev" id="newAddressName">
                                            <label for="newAddressName">{{ $t('checkoutPage.secondStep.addAddress.form.addressName') }}</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12">
                                        <div class="form-floating">
                                            <select class="form-select" id="newAddressType" v-model="addressData.type">
                                                <option value="shipping">{{ $t('checkoutPage.secondStep.addAddress.form.addressType[0]') }}</option>
                                                <option value="billing">{{ $t('checkoutPage.secondStep.addAddress.form.addressType[1]') }}</option>
                                            </select>
                                            <label for="newAddressType">{{ $t('checkoutPage.secondStep.addAddress.form.addressType[2]') }}</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-12 col-sm-12">
                                        <div class="form-floating">
                                            <select v-model="addressData.selectedCountry" class="form-select shadow-sm" id="newAddressCountry" aria-label="Ülke Seçin">
                                                <option v-for="(country, index) in addressData.countries" :key="index" :value="country.name.common">
                                                {{ currentCountryISO3 && country.translations && country.translations[currentCountryISO3] ? country.translations[currentCountryISO3].common : country.name.common }}
                                                </option> 
                                            </select>
                                            <label for="newAddressCountry">{{ $t('checkoutPage.secondStep.addAddress.form.addressCountry') }}</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="row g-2 p-2">
                                    <div class="col-lg-4 col-md-12 col-sm-12">
                                        <div class="form-floating">
                                            <input type="text" class="form-control shadow-sm" v-model="addressData.first_name" :placeholder="$t('checkoutPage.secondStep.addAddress.form.addressCountry')" id="newAddressName">
                                            <label for="newAddressName">{{ $t('checkoutPage.secondStep.addAddress.form.name') }}</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-12 col-sm-12">
                                        <div class="form-floating">
                                            <input type="text" class="form-control shadow-sm" v-model="addressData.last_name" :placeholder="$t('checkoutPage.secondStep.addAddress.form.surname')" id="newAddressSurname">
                                            <label for="newAddressSurname">{{ $t('checkoutPage.secondStep.addAddress.form.surname') }}</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-12 col-sm-12">
                                        <div class="form-floating">
                                            <input type="tel" ref="newAddressPhone" style="height: 56px;" class="form-control shadow-sm" @input="validateNewAddressPhone" @keypress="isNumber($event)" v-model="addressData.phone" id="newAddressPhone">
                                            <span v-if="isNewAddressPhoneValid == false" class="mt-2 fw-normal text-danger"><span v-if="newAddressPhoneValidText && newAddressPhoneValidText != ''">{{ newAddressPhoneValidText }}</span></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row g-2 p-2">
                                    <div class="col-lg-4 col-md-12 col-sm-12">
                                        <div class="form-floating">
                                            <input type="text" class="form-control shadow-sm" v-model="addressData.city" :placeholder="$t('checkoutPage.secondStep.addAddress.form.city')" id="newAddressCity">
                                            <label for="newAddressCity">{{ $t('checkoutPage.secondStep.addAddress.form.city') }}</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-12 col-sm-12">
                                        <div class="form-floating">
                                            <input type="text" class="form-control shadow-sm" v-model="addressData.state" :placeholder="$t('checkoutPage.secondStep.addAddress.form.state')" id="newAddressState">
                                            <label for="newAddressState">{{ $t('checkoutPage.secondStep.addAddress.form.state') }}</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-12 col-sm-12">
                                        <div class="form-floating">
                                            <input type="number" class="form-control shadow-sm" v-model="addressData.zipcode" :placeholder="$t('checkoutPage.secondStep.addAddress.form.zipcode')" id="newAddressZipcode">
                                            <label for="newAddressZipcode">{{ $t('checkoutPage.secondStep.addAddress.form.zipcode') }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row g-2 p-2">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <div class="form-floating">
                                            <input type="text" class="form-control shadow-sm" v-model="addressData.district" :placeholder="$t('checkoutPage.secondStep.addAddress.form.district')" id="newAddressDistrict">
                                            <label for="newAddressDistrict">{{ $t('checkoutPage.secondStep.addAddress.form.district') }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row g-2 p-2">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <div class="form-floating">
                                            <textarea class="form-control shadow-sm" v-model="addressData.address1" :placeholder="$t('checkoutPage.secondStep.addAddress.form.address')" id="newAddressLine" style="height: 100px"></textarea>
                                            <label for="newAddressLine">{{ $t('checkoutPage.secondStep.addAddress.form.address') }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row g-2 p-2">
                                    <div id="alertbox-addaddress"></div>
                                    <div class="col-lg-6 mx-auto col-sm-12 d-grid">
                                        <button type="submit" class="btn btn-primary btn-block shadow-sm">{{ $t('checkoutPage.secondStep.addAddress.form.saveBtn') }}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="addresses-list">
                            <h4 class="mb-3">{{ $t('checkoutPage.secondStep.addressList.header') }}</h4>
                            <div v-if="allAddresses != [] || allAddresses.length != 0">
                                <div class="row row-cols-lg-3 row-cols-sm-1 row-cols-2 row-cols-md-2 g-4 p-1">
                                    <div class="col">
                                        <a @click="showAddAddressForm()" class="text-decoration-none">
                                            <div class="card h-100 border-dashed rounded-2">
                                                <div class="card-body d-flex align-items-center justify-content-center">
                                                    <h5 class="card-title text-center"><i class="fa fa-plus fa-xl m-3 opacity-25"></i><br>{{ $t('checkoutPage.secondStep.addressList.addressElements.new') }}
                                                    </h5>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="col opacity-0 user-select-none" v-if="allAddresses == [] || allAddresses.length == 0">
                                        <div class="card h-100 border-0 shadow-sm rounded-2" :class="{ 'active-card': activeCardIndex === 1 }">
                                            <div class="card-header bg-body border-0">
                                                Lorem Ipsum
                                            </div>
                                            <div class="card-body lh-1">
                                                <h6 class="card-title mb-3">Lorem Ipsum</h6>
                                                <p class="card-text">
                                                    Lorem ipsum dolor sit amet
                                                </p>
                                                <p class="card-text">
                                                    Lorem ipsum dolor sit amet
                                                </p>
                                                <p class="card-text">
                                                    Lorem ipsum dolor sit amet
                                                </p>
                                                <p class="card-text">
                                                    Lorem ipsum dolor sit amet
                                                </p>
                                                <p class="card-text">
                                                    {{ $t('checkoutPage.secondStep.addressList.phoneNumber') }} 00000
                                                </p>
                                            </div>
                                            <div class="card-footer d-flex justify-content-between bg-body border-0 my-2">
                                                <small class="text-body-secondary address-delete"><i class="fa fa-trash me-1"></i>{{ $t('checkoutPage.secondStep.addressList.deleteAddressBtn') }}</small>
                                                <a class="text-decoration-none"><small class="text-body-secondary address-edit">{{ $t('checkoutPage.secondStep.addressList.editAddressBtn') }}</small></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-for="address in allAddresses" :key="address.id" class="col">
                                        <div class="card h-100 border-0 shadow-sm" @click="toggleActiveCard(address.id, address.type)" :class="{
                                                    'active-card': (addressSelectType === 'both' && (addressSelectShipping === address.id || addressSelectBilling === address.id)) ||
                                                        (addressSelectType === 'onebyone' && (addressSelectShipping === address.id || addressSelectBilling === address.id))
                                                }">
                                            <div class="card-header border-0 d-flex justify-content-between">
                                                <span>{{ address.type == "billing" ? $t('checkoutPage.secondStep.addressList.addressElements.addressType[0]') : $t('checkoutPage.secondStep.addressList.addressElements.addressType[1]')
                                                    }}</span>
                                            </div>
                                            <div class="card-header bg-body border-0">
                                                {{ address.address_name }}
                                            </div>
                                            <div class="card-body lh-1">
                                                <h6 class="card-title mb-3">{{ address.first_name }} {{
                                                        address.last_name }}</h6>
                                                <p class="card-text">
                                                    {{ address.district }}
                                                </p>
                                                <p class="card-text">
                                                    {{ address.address1 }}
                                                </p>
                                                <p class="card-text">
                                                    {{ address.city }}/{{ address.state }} {{ address.country }} {{
                                                            address.zipcode }}
                                                </p>
                                                <p class="card-text">
                                                    {{ $t('checkoutPage.secondStep.addressList.addressElements.phoneNumber') }} {{ address.phone }}
                                                </p>
                                            </div>
                                            <div class="card-footer d-flex justify-content-between bg-body border-0 my-2">
                                                <small class="text-body-secondary address-delete" data-bs-toggle="modal" data-bs-target="#deleteModal" @click.stop="confirmDelete(address.type, address.id)"><i class="fa fa-trash me-1"></i>{{ $t('checkoutPage.secondStep.addressList.addressElements.deleteAddressBtn') }}</small>
                                                <a @click.stop="showEditAddressForm(false, address.type, address.id)" class="text-decoration-none"><small class="text-body-secondary address-edit">{{ $t('checkoutPage.secondStep.addressList.addressElements.editAddressBtn') }}</small></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="justify-content-center d-flex mt-2" id="address-list-next">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="billingAddressCheckbox" v-model="billingCheckbox" @change="billingCheckboxChange">
                                            <label class="form-check-label" for="billingAddressCheckbox">
                                                {{ $t('checkoutPage.secondStep.addressList.addressElements.addressCheckbox') }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div id="currency-alertbox"></div>
                                    <div class="justify-content-center d-flex mt-2" id="address-list-next">
                                        <button class="btn btn-outline-primary disabled" @click="nextStep" type="button">
                                            <span class="next-button-text">{{ $t('checkoutPage.secondStep.addressList.addressElements.nextBtn') }}&nbsp;<i class="fa-solid fa-chevron-right"></i></span>
                                            <div class="next-btn-loading">
                                                <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                                <span class="visually-hidden" role="status">{{ $t('loadingText') }}</span>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="edit-address">
                            <form @submit.prevent="editAddress">
                                <h4 class="mb-3"><i class="fa fa-chevron-left fa-2xs text-zerduz" @click="showEditAddressForm(true)" style="font-size:14px; cursor: pointer;"></i>&nbsp;{{ $t('checkoutPage.secondStep.editAddress.header') }}</h4>
                                <div class="row g-2 p-2">
                                    <div class="col-lg-4 col-md-6 col-sm-12">
                                        <div class="form-floating">
                                            <input type="text" class="form-control shadow-sm" placeholder="Ev" id="editAddressName" v-model="editAddressData.address_name">
                                            <label for="editAddressName">{{ $t('checkoutPage.secondStep.editAddress.form.addressName') }}</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12">
                                        <div class="form-floating">
                                            <select class="form-select" id="editAddressType" v-model="editAddressData.type">
                                                <option value="shipping">{{ $t('checkoutPage.secondStep.editAddress.form.addressType[0]') }}</option>
                                                <option value="billing">{{ $t('checkoutPage.secondStep.editAddress.form.addressType[1]') }}</option>
                                            </select>
                                            <label for="editAddressType">{{ $t('checkoutPage.secondStep.editAddress.form.addressType[2]') }}</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12">
                                        <div class="form-floating">
                                            <select v-model="editAddressSelectedCountry" class="form-select shadow-sm" id="editAddressCountry">
                                                <option v-for="(country, index) in addressData.countries" :key="index" :value="country.name.common">{{
                                                            country.name.common }}</option>
                                            </select>
                                            <label for="editAddressCountry">{{ $t('checkoutPage.secondStep.editAddress.form.addressCountry') }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row g-2 p-2">
                                    <div class="col-lg-4 col-md-12 col-sm-12">
                                        <div class="form-floating">
                                            <input type="text" class="form-control shadow-sm" placeholder="Ahmet" :id="$t('checkoutPage.secondStep.editAddress.form.name')" v-model="editAddressData.first_name">
                                            <label for="editAddressFirstName">{{ $t('checkoutPage.secondStep.editAddress.form.name') }}</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-12 col-sm-12">
                                        <div class="form-floating">
                                            <input type="text" class="form-control shadow-sm" :placeholder="$t('checkoutPage.secondStep.editAddress.form.surname')" id="editAddressLastName" v-model="editAddressData.last_name">
                                            <label for="editAddressLastName">{{ $t('checkoutPage.secondStep.editAddress.form.surname') }}</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-12 col-sm-12">
                                        <div class="form-floating">
                                            <input type="tel" style="height: 56px;" class="form-control shadow-sm" ref="editAddressPhone" id="editAddressPhone" @input="validateEditAddressPhone" @keypress="isNumber($event)" v-model="editAddressData.inputPhone">
                                            <span v-if="isEditAddressPhoneValid == false" class="mt-2 fw-normal text-danger"><span v-if="editAddressPhoneValidText && editAddressPhoneValidText != ''">{{ editAddressPhoneValidText }}</span></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row g-2 p-2">
                                    <div class="col-lg-4 col-md-12 col-sm-12">
                                        <div class="form-floating">
                                            <input type="text" class="form-control shadow-sm" :placeholder="$t('checkoutPage.secondStep.editAddress.form.city')" id="newAddressCity" v-model="editAddressData.city">
                                            <label for="newAddressCity">{{ $t('checkoutPage.secondStep.editAddress.form.city') }}</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-12 col-sm-12">
                                        <div class="form-floating">
                                            <input type="text" class="form-control shadow-sm" :placeholder="$t('checkoutPage.secondStep.editAddress.form.state')" id="newAddressState" v-model="editAddressData.state">
                                            <label for="newAddressState">{{ $t('checkoutPage.secondStep.editAddress.form.state') }}</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-12 col-sm-12">
                                        <div class="form-floating">
                                            <input type="number" class="form-control shadow-sm" :placeholder="$t('checkoutPage.secondStep.editAddress.form.zipcode')" id="newAddressZipcode" v-model="editAddressData.zipcode">
                                            <label for="newAddressZipcode">{{ $t('checkoutPage.secondStep.editAddress.form.zipcode') }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row g-2 p-2">
                                    <div class="col-lg-12 col-sm-12 col-sm-12">
                                        <div class="form-floating">
                                            <input type="text" class="form-control shadow-sm" :placeholder="$t('checkoutPage.secondStep.editAddress.form.district')" id="newAddressDistrict" v-model="editAddressData.district">
                                            <label for="newAddressDistrict">{{ $t('checkoutPage.secondStep.editAddress.form.district') }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row g-2 p-2">
                                    <div class="col-lg-12 col-sm-12">
                                        <div class="form-floating">
                                            <textarea class="form-control shadow-sm" :placeholder="$t('checkoutPage.secondStep.editAddress.form.address')" id="newAddressLine" style="height: 100px" v-model="editAddressData.address1"></textarea>
                                            <label for="newAddressLine">{{ $t('checkoutPage.secondStep.editAddress.form.address') }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row g-2 p-2">
                                    <div id="alertbox-editaddress"></div>
                                    <div class="col-lg-6 mx-auto col-sm-12 d-grid">
                                        <button type="submit" class="btn btn-primary btn-block shadow-sm">{{ $t('checkoutPage.secondStep.editAddress.form.editBtn') }}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div v-if="deleteAddressData != [] || deleteAddressData.length != 0">
                            <div class="modal fade" id="deleteModal" role="dialog" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h1 class="modal-title fs-5" id="deleteModalLabel">{{ $t('checkoutPage.secondStep.addressList.addressElements.confirmDelete') }}
                                                <span class="badge bg-secondary fw-normal">{{
                                                        deleteAddressData.address_name }}</span>
                                            </h1>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <h6 class="card-title mb-3">{{ deleteAddressData.first_name }} {{
                                                    deleteAddressData.last_name }}
                                            </h6>
                                            <p class="card-text">
                                                {{ deleteAddressData.district }}
                                            </p>
                                            <p class="card-text">
                                                {{ deleteAddressData.address1 }}
                                            </p>
                                            <p class="card-text">
                                                {{ deleteAddressData.city }}/{{ deleteAddressData.state }} {{ deleteAddressData.country }} {{ deleteAddressData.zipcode }}
                                            </p>
                                            <p class="card-text">
                                                {{ $t('checkoutPage.secondStep.addressList.addressElements.phoneNumber') }} {{ deleteAddressData.phone }}
                                            </p>
                                        </div>
                                        <div class="modal-footer gap-2">
                                            <div class="col-lg-5 mx-auto col-sm-6 col-md-12 d-grid">
                                                <button type="button" class="btn btn-light btn-block" data-bs-dismiss="modal">{{ $t('checkoutPage.secondStep.addressList.addressElements.closeBtn') }}</button>
                                            </div>
                                            <div class="col-lg-5 mx-auto col-sm-6 col-md-12 d-grid">
                                                <button type="button" class="btn btn-danger btn-block" @click="deleteAddress" data-bs-dismiss="modal">{{ $t('checkoutPage.secondStep.addressList.addressElements.deleteAddressBtn') }}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="step step-3">
                        <h4 class="mb-3">{{ $t('checkoutPage.thirdStep.header') }}</h4>
                        <div class="wrapper" id="app">
                            <div class="card-form">

                                <div class="card-form__inner">
                                    <h2 class="mb-4">{{ $t('checkoutPage.thirdStep.paymentAmount') }} <span class="fw-bold"><span class="text-zerduz">₺{{ formatPrice(String((((parseInt(totalQuantity) * $store.state.price.price.tax) + (calculateCargo)) * parseFloat(this.currency)).toFixed(2)).replace('.', ',')) }}</span> <small class="text-body-tertiary" style="font-size:14px;">({{ $store.state.price.currency }}{{ formatPrice(String((parseInt(totalQuantity) * $store.state.price.price.tax) + (calculateCargo))) }})</small></span></h2>
                                    <div class="card-form__row">
                                        <div class="card-form__col">
                                            <div class="form-check">
                                                <!-- Req -->
                                                <input class="form-check-input" type="checkbox" value="" id="sozlesmeCheckbox" v-model="sozlesmeCheckbox" required>
                                                <label class="form-check-label" for="sozlesmeCheckbox">
                                                    <div v-if="$t('checkoutPage.thirdStep.agreementText.first') == 'text'"> 
                                                        {{ $t('checkoutPage.thirdStep.agreementText.text') }}
                                                        <a class="link-underline pointer-link" data-bs-toggle="modal" data-bs-target="#exampleModal">{{ $t('checkoutPage.thirdStep.agreementText.link') }}</a>
                                                    </div>
                                                    <div v-if="$t('checkoutPage.thirdStep.agreementText.first') == 'link'"> 
                                                        <a class="link-underline pointer-link" data-bs-toggle="modal" data-bs-target="#exampleModal">{{ $t('checkoutPage.thirdStep.agreementText.link') }}</a>
                                                        {{ $t('checkoutPage.thirdStep.agreementText.text') }}
                                                    </div>
                                                    
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="orderAlertBox">
                                    </div>
                                    <!-- <div id="payment-popup" v-html="paymentForm"></div> -->
                                    <button class="card-form__button rounded-3" type="button" @click="finishOrder">
                                        {{ $t('checkoutPage.thirdStep.finishOrder') }}
                                    </button>

                                </div>
                                <small class="text-secondary text-opacity-50" style="font-size:10px">{{ $t('checkoutPage.thirdStep.currencyInfo').replace('USD', $store.state.price.currencyType) }} {{this.currency}}</small>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="exampleModalLabel">{{ $t('checkoutPage.thirdStep.agreement') }}</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <h3>1. TARAFLAR</h3>
                        <p>İşbu Mesafeli Satış Sözleşmesi ("<strong>Sözleşme</strong>"), Alıcı ve Satıcı arasında
                            aşağıda belirtilen hüküm
                            ve şartlar
                            çerçevesinde elektronik ortamda kurulmuştur. Alıcı ve Satıcı, Sözleşme kapsamında birlikte
                            <strong>“Taraflar”</strong>, ayrı ayrı
                            <strong>“Taraf”</strong> olarak anılacaktır.
                        </p>
                        <h3>2. TANIMLAR</h3>
                        <p>Sözleşme’nin uygulanmasında ve yorumlanmasında aşağıda yazılı terimler karşılarındaki yazılı
                            açıklamaları ifade
                            edeceklerdir.</p>
                        <table class="table table-light table-striped">
                            <tbody>
                                <tr>
                                    <td scope="row">ALICI:</td>
                                    <td>Bir Mal veya Hizmet’i ticari veya mesleki olmayan amaçlarla edinen, kullanan
                                        veya yararlanan gerçek
                                        kişiyi,</td>
                                </tr>
                                <tr>
                                    <td scope="row">Bakanlık:</td>
                                    <td>Türkiye Cumhuriyeti Ticaret Bakanlığı’nı,</td>
                                </tr>
                                <tr>
                                    <td scope="row">Banka:</td>
                                    <td>5411 sayılı Bankacılık Kanunu uyarınca kurulan lisanslı kuruluşları,</td>
                                </tr>
                                <tr>
                                    <td scope="row">ZERDUZ veya Elektronik Ticaret Aracı Hizmet Sağlayıcı:</td>
                                    <td>Oluşturduğu sistem ile Satıcı’nın Ürün/Hizmet’i satışa sunduğu Platform’u
                                        işleten ve Satıcı adına mesafeli
                                        sözleşme kurulmasına aracılık eden OBİM TEKNOLOJİ VE DIŞ TİCARET LİMİTED
                                        ŞİRKETİ’ni,</td>
                                </tr>
                                <tr>
                                    <td scope="row">Hizmet:</td>
                                    <td>Bir ücret veya menfaat karşılığında yapılan ya da yapılması taahhüt edilen Ürün
                                        sağlama dışındaki her
                                        türlü tüketici işleminin konusunu,</td>
                                </tr>
                                <tr>
                                    <td scope="row">Kanun:</td>
                                    <td>6502 sayılı Tüketicinin Korunması Hakkında Kanun’u,</td>
                                </tr>
                                <tr>
                                    <td scope="row">Kargo Şirketi:</td>
                                    <td>Ürün’ün Alıcı’ya ulaştırılmasını, iade süreçlerinde Alıcı’dan alınarak Satıcı
                                        veya ZERDUZ’a
                                        ulaştırılmasını sağlayan anlaşmalı kargo veya lojistik şirketini,</td>
                                </tr>
                                <tr>
                                    <td scope="row">Ön Bilgilendirme Formu:</td>
                                    <td>Sözleşme kurulmadan ya da buna karşılık herhangi bir teklif Alıcı tarafından
                                        kabul edilmeden önce Alıcı’yı
                                        Yönetmelik’te belirtilen asgari hususlar konusunda bilgilendirmek için
                                        hazırlanan formu,</td>
                                </tr>
                                <tr>
                                    <td scope="row">Platform:</td>
                                    <td>ZERDUZ’a ait www.zerduz.com adlı internet sitesini ve mobil uygulamasını,</td>
                                </tr>
                                <tr>
                                    <td scope="row">Satıcı:</td>
                                    <td>Kamu tüzel kişileri de dahil olmak üzere ticari veya mesleki amaçlarla
                                        tüketiciye Ürün/Hizmet sunan ya da
                                        Ürün/Hizmet sunanın adına ya da hesabına hareket eden ve Sözleşme’nin 5.
                                        maddesinde bilgileri bulunan gerçek
                                        ve/veya tüzel kişiyi,</td>
                                </tr>
                                <tr>
                                    <td scope="row">Sözleşme:</td>
                                    <td>Satıcı ve Alıcı arasında akdedilen Sözleşme’yi,</td>
                                </tr>
                                <tr>
                                    <td scope="row">ZERDUZ Teslimat Noktası:</td>
                                    <td>Alıcı’nın satın aldığı Ürünler’i kolayca teslim alabildiği anlaşmalı esnaf
                                        noktalarını, kargo şubelerini
                                        ve zincir mağazalarını,</td>
                                </tr>
                                <tr>
                                    <td scope="row">Ürün:</td>
                                    <td>Alışverişe konu olan taşınır eşya, konut veya tatil amaçlı taşınmaz mallar ile
                                        elektronik ortamda
                                        kullanılmak üzere hazırlanan yazılım, ses, görüntü ve benzeri her türlü gayri
                                        maddi malı,</td>
                                </tr>
                                <tr>
                                    <td scope="row">Yönetmelik:</td>
                                    <td>Mesafeli Sözleşmeler Yönetmeliği’ni ifade eder.</td>
                                </tr>
                            </tbody>
                        </table>
                        <h3>3. SÖZLEŞMENİN KONUSU ve KAPSAMI</h3>
                        <p><strong>3.1.</strong> Sözleşme’nin konusu Alıcı'nın, Platform’da, Ürün/Hizmet’in satın
                            alınmasına yönelik
                            elektronik olarak
                            sipariş verdiği, Sözleşme’de belirtilen niteliklere sahip Ürün/Hizmet’in satışı ve teslimi
                            ile ilgili olarak Kanun
                            ve Yönetmelik hükümleri gereğince Taraflar’ın hak ve yükümlülüklerinin belirlenmesi olup
                            Taraflar, Sözleşme
                            tahtında Kanun ve Yönetmelik’ten kaynaklanan yükümlülük ve sorumluluklarını bildiklerini ve
                            anladıklarını kabul,
                            beyan ve taahhüt ederler.</p>
                        <p><strong>3.2.</strong> Sözleşme’nin akdedilmesi Taraflar’ın ayrı ayrı ZERDUZ ile akdetmiş
                            oldukları sözleşmelerin
                            hükümlerinin
                            ifasını engellemeyecek olup, Taraflar, ZERDUZ’un Ürün/Hizmet’in satışına ve Sözleşme’ye
                            herhangi bir şekilde taraf
                            olmadığını ve Sözleşme kapsamında Taraflar’ın yükümlülüklerini yerine getirmeleri ile ilgili
                            Kanun ve Yönetmelik
                            çerçevesinde kendisine yüklenmiş olanlar hariç olmak üzere herhangi bir sorumluluğu ve
                            taahhüdü bulunmadığını
                            kabul, beyan ve taahhüt ederler.</p>
                        <p><strong>3.3.</strong> Mevzuat uyarınca aşağıdaki Ürün/Hizmet satışları Sözleşme’nin
                            kapsamında değildir.</p>
                        <ul class="list-unstyled">
                            <li class="ms-4">
                                <strong>a) </strong> Finansal hizmetler,
                            </li>
                            <li class="ms-4">
                                <strong>b) </strong> Otomatik makineler aracılığıyla yapılan satışlar,
                            </li>
                            <li class="ms-4">
                                <strong>c) </strong> Halka açık telefon vasıtasıyla telekomünikasyon operatörleriyle bu
                                telefonun
                                kullanımı,
                            </li>
                            <li class="ms-4">
                                <strong>d) </strong> Bahis, çekiliş, piyango ve benzeri şans oyunlarına ilişkin
                                hizmetler,
                            </li>
                            <li class="ms-4">
                                <strong>e) </strong> Taşınmaz malların veya bu mallara ilişkin hakların oluşumu, devri
                                veya kazanımı,
                            </li>
                            <li class="ms-4">
                                <strong>f) </strong> Konut kiralama,
                            </li>
                            <li class="ms-4">
                                <strong>g) </strong> Paket turlar,
                            </li>
                            <li class="ms-4">
                                <strong>h) </strong> Devre mülk, devre tatil, uzun süreli tatil hizmeti ve bunların
                                yeniden satımı veya
                                değişimi,
                            </li>
                            <li class="ms-4">
                                <strong>i) </strong> Yiyecek ve içecekler gibi günlük tüketim maddelerinin, satıcının
                                düzenli teslimatları
                                çerçevesinde tüketicinin meskenine veya işyerine götürülmesi,
                            </li>
                            <li class="ms-4">
                                <strong>j) </strong> Yolcu taşıma hizmetleri,
                            </li>
                            <li class="ms-4">
                                <strong>k) </strong> Ürünlerin montaj, bakım ve onarımı,
                            </li>
                            <li class="ms-4">
                                <strong>l) </strong> Bakımevi hizmetleri, çocuk, yaşlı ya da hasta bakımı gibi ailelerin
                                ve kişilerin
                                desteklenmesine yönelik sosyal hizmetler.
                            </li>
                            <li class="ms-4">
                                <strong>m) </strong> Kısa mesaj aracılığıyla kurulan ve eş zamanlı olarak tamamen ifa
                                edilen abonelik
                                içermeyen
                                katma değerli elektronik haberleşme Hizmetleri ile 23/6/1983 tarihli ve 2860 sayılı
                                Yardım Toplama Kanunu
                                kapsamındaki bağışlar ve kamu kurumlarınca sunulan katma değerli elektronik haberleşme
                                hizmetleri.
                            </li>
                        </ul>
                        <h3>4. ALICI’NIN ÖNCEDEN BİLGİLENDİRİLDİĞİ HUSUSLAR</h3>
                        <p>Alıcı, siparişin oluşturması ve Sözleşme’nin kurulmasından önce, gerek Platform’un ilgili
                            sayfaları ve
                            kısımlarındaki tüm genel-özel açıklamalar, gerek Sözleşme, gerek Ön Bilgilendirme Formu
                            gerekse de sair
                            şekillerde, aşağıda yer verilenler de dahil olmak üzere Sözleşme’nin akdi ve uygulaması ile
                            ilgili tüm hususlar
                            hakkında doğru ve eksiksiz olarak bilgilendirildiğini ve bunları okuyup anladığını kabul,
                            beyan ve taahhüt eder.
                        </p>
                        <ul class="list-unstyled">
                            <li class="ms-4">
                                <strong>a) </strong> Ürün/Hizmet’in temel nitelikleri,
                            </li>
                            <li class="ms-4">
                                <strong>b) </strong> Satıcı'nın adı veya unvanı, MERSİS numarası veya vergi kimlik
                                numarası ve iletişim
                                bilgileri ile diğer tanıtıcı bilgileri,
                            </li>
                            <li class="ms-4">
                                <strong>c) </strong> Ürün/Hizmet'in Platform’dan alınması sırasındaki satış işlemi
                                aşamaları ile yanlış
                                girilen
                                bilgilerin düzeltilmesine ilişkin amaca uygun araçlar-yöntemler,
                            </li>
                            <li class="ms-4">
                                <strong>d) </strong> Satıcı'nın mensubu olduğu Meslek Odası (İTO-İstanbul Ticaret Odası)
                                ve İTO'nun meslek
                                ile
                                ilgili öngördüğü davranış kuralları bilgisinin edinebileceği elektronik iletişim
                                bilgileri (Telefon: 444 0 486,
                                www.ito.org.tr)
                            </li>
                            <li class="ms-4">
                                <strong>e) </strong> ZERDUZ tarafından uygulanan Alıcı bilgileri için geçerli gizlilik,
                                veri
                                kullanımı-işleme ve
                                elektronik iletişim kuralları ile Alıcı’nın bu hususlarda ZERDUZ’a verdiği izinlerin
                                kapsamı, Alıcı’nın kanuni
                                hakları, Satıcı’nın hakları ve Taraflar’ın haklarını kullanım usulleri,
                            </li>
                            <li class="ms-4">
                                <strong>f) </strong> Ürün/Hizmet için Satıcı tarafından öngörülen gönderim
                                kısıtlamaları,
                            </li>
                            <li class="ms-4">
                                <strong>g) </strong> Ürün/Hizmet için kabul edilen ödeme yöntem-araçları, Ürün/Hizmet’in
                                vergiler dahil
                                toplam
                                satış fiyatı,
                            </li>
                            <li class="ms-4">
                                <strong>h) </strong> Ürün/Hizmet'in Alıcı’ya teslimine ilişkin usuller ile
                                nakliye-teslimat-kargo
                                masrafları
                                gibi ek masraflar hakkında bilgiler,
                            </li>
                            <li class="ms-4">
                                <strong>i) </strong> Ürün/Hizmet ile ilgili diğer ödeme/tahsilat ve teslimat bilgileri
                                ve süresi ile
                                Sözleşme'nin ifasına ilişkin diğer bilgiler ve Taraflar’ın bu hususlardaki
                                sorumlulukları,
                            </li>
                            <li class="ms-4">
                                <strong>j) </strong> Alıcı’nın cayma hakkını kullanamadığı durumlarda bu haktan
                                faydalanamayacağına veya
                                hakkın
                                süresinde kullanılmaması da dahil olmak üzere hangi koşullarda bu hakkı kaybedeceğine
                                ilişkin bilgi,
                            </li>
                            <li class="ms-4">
                                <strong>k) </strong> Alıcı’nın cayma hakkının olduğu durumlarda bu hakkını kullanma
                                şartları, süresi ve
                                usulü,
                                Satıcı’nın iade için öngördüğü Kargo Şirketi’ne ilişkin bilgi ve tüm mali hususlar (iade
                                yöntemi ile masrafı,
                                Sözleşme konusu bedelin iadesi ve iade sırasında Alıcı tarafından kazanılmış/kullanılmış
                                ödül puanları sebebiyle
                                yapılabilecek indirim ve mahsuplar dahil),
                            </li>
                            <li class="ms-4">
                                <strong>l) </strong> Cayma bildiriminin yapılacağı açık adres, faks numarası veya
                                elektronik posta
                                bilgileri,
                            </li>
                            <li class="ms-4">
                                <strong>m) </strong> Satıcı’nın talebi üzerine, varsa tüketici tarafından ödenmesi veya
                                sağlanması gereken
                                depozitolar ya da diğer mali teminatlar ve bunlara ilişkin şartlar,
                            </li>
                            <li class="ms-4">
                                <strong>n) </strong> Varsa dijital içeriklerin işlevselliğini etkileyebilecek teknik
                                koruma önlemleri,
                            </li>
                            <li class="ms-4">
                                <strong>o) </strong> Alıcı’nın Platform’da dönem dönem uygulanabilecek çeşitli
                                fırsatlara ilişkin
                                yararlanma
                                koşullarının (özel şartlar) detayları,
                            </li>
                            <li class="ms-4">
                                <strong>p) </strong> Satıcı’nın bildiği ya da makul olarak bilmesinin beklendiği,
                                dijital içeriğin hangi
                                donanım
                                ya da yazılımla birlikte çalışabileceğine ilişkin bilgi,
                            </li>
                            <li class="ms-4">
                                <strong>q) </strong> Mahiyetine göre Sözleşme'de yer alan diğer tüm satış şartları ile
                                Sözleşme Alıcı
                                tarafından
                                Platform’da onaylanarak kurulduktan sonra Sözleşme’nin Alıcı’nın talebi halinde Alıcı’ya
                                elektronik posta ile
                                gönderileceği ve Alıcı tarafından Sözleşme’ye üyelik hesabından erişilebileceğine
                                ilişkin bilgi,
                            </li>
                            <li class="ms-4">
                                <strong>r) </strong> Uyuşmazlık hallerinde Alıcı’nın, başvurularını Tüketici Mahkemesine
                                veya Tüketici
                                Hakem
                                Heyetine yapabileceğine ilişkin bilgi.
                            </li>
                        </ul>
                        <h3>5. ALICI, SATICI, ELEKTRONİK TİCARET ARACI HİZMET SAĞLAYICI VE FATURA BİLGİLERİ</h3>
                        <h5>ALICI BİLGİLERİ</h5>
                        <table class="table table-light table-striped">
                            <tbody>
                                <tr>
                                    <td scope="row">Teslim Edilecek Kişi:</td>
                                    <td>{{ contractData.first_name }} {{ contractData.last_name }}</td>
                                </tr>
                                <tr>
                                    <td scope="row">Teslimat Adresi:</td>
                                    <td>{{ contractData.shippingAddress.address1 }} {{
                                            contractData.shippingAddress.district }} {{ contractData.shippingAddress.state
}}
                                        / {{ contractData.shippingAddress.city }} /
                                        {{ contractData.shippingAddress.country }} {{
                                                contractData.shippingAddress.zipcode }}</td>
                                </tr>
                                <tr>
                                    <td scope="row">Telefon:</td>
                                    <td>{{ contractData.phone }}</td>
                                </tr>
                                <tr>
                                    <td scope="row">Faks:</td>
                                    <td>{{ contractData.phone }}</td>
                                </tr>
                                <tr>
                                    <td scope="row">E-posta/Kullanıcı Adı:</td>
                                    <td>{{ contractData.email }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <h5>ELEKTRONİK TİCARET SATICI BİLGİLERİ</h5>
                        <table class="table table-light table-striped">
                            <tbody>
                                <tr>
                                    <td scope="row">Elektronik Ticaret Satıcı Unvanı</td>
                                    <td>OBİM TEKNOLOJİ VE DIŞ TİCARET LİMİTED ŞİRKETİ</td>
                                </tr>
                                <tr>
                                    <td scope="row">Elektronik Ticaret Satıcı Adresi</td>
                                    <td>Yenibosna Merkez Mahallesi Ladin Sokak No:36/11 Bahçelievler İstanbul</td>
                                </tr>
                                <tr>
                                    <td scope="row">Elektronik Ticaret Satıcı Mersis Numarası</td>
                                    <td>xxxx</td>
                                </tr>
                                <tr>
                                    <td scope="row">Elektronik Ticaret Satıcı Vergi Kimlik Numarası</td>
                                    <td>Yenibosna VD- 6321356656</td>
                                </tr>
                                <tr>
                                    <td scope="row">Elektronik Ticaret Satıcı Telefonu</td>
                                    <td>0850 340 6666</td>
                                </tr>
                                <tr>
                                    <td scope="row">Elektronik Ticaret Satıcı Faks Numarası</td>
                                    <td>0850 340 6666</td>
                                </tr>
                                <tr>
                                    <td scope="row">Elektronik Ticaret Satıcı Şikâyet/Öneri Kanalları</td>
                                    <td>0850 340 6666 telefon numarası üzerinden ve Platform’da yer alan “ZERDUZ Asistan’a
                                        Sor” başlıklı alandan
                                        şikayet ve öneriler iletilebilecektir.</td>
                                </tr>
                            </tbody>
                        </table>
                        <h5>FATURA BİLGİLERİ</h5>
                        <table class="table table-light table-striped">
                            <tbody>
                                <tr>
                                    <td scope="row">Ticari Unvanı / Adı ve Soyadı</td>
                                    <td>{{ contractData.billingData.first_name }} {{ contractData.billingData.last_name
                                        }}</td>
                                </tr>
                                <tr>
                                    <td scope="row">Vergi Dairesi ve Vergi Kimlik Numarası</td>
                                    <td>{{ contractData.billingData.tcno }}</td>
                                </tr>
                                <tr>
                                    <td scope="row">Adres</td>
                                    <td>{{ contractData.billingData.address1 }} {{ contractData.billingData.district }}
                                        {{ contractData.billingData.state }} / {{ contractData.billingData.city }} / {{
                                                contractData.billingData.country }} {{ contractData.billingData.zipcode }}</td>
                                </tr>
                                <tr>
                                    <td scope="row">Telefon</td>
                                    <td>{{ contractData.billingData.phone }}</td>
                                </tr>
                                <tr>
                                    <td scope="row">Faks</td>
                                    <td>{{ contractData.billingData.phone }}</td>
                                </tr>
                                <tr>
                                    <td scope="row">E-posta/Kullanıcı Adı</td>
                                    <td>{{ contractData.billingData.email }}</td>
                                </tr>
                                <tr>
                                    <td scope="row">Fatura Teslim</td>
                                    <td>Fatura sipariş teslimatı sırasında teslimat adresine sipariş ile birlikte
                                        ve/veya e-fatura yöntemiyle
                                        elektronik posta adresine teslim edilecektir.</td>
                                </tr>
                            </tbody>
                        </table>
                        <h3>6. ÜRÜN/HİZMET BİLGİLERİ</h3>
                        <p><strong>6.1.</strong> Ürün/Hizmet’in temel özellikleri (türü, miktarı, marka/modeli, rengi,
                            adedi, fiyatı)
                            Platform’da yer almakta
                            olup Platform üzerinden detaylı şekilde incelenebilecektir.
                        </p>
                        <p>
                            <strong>6.2.</strong>Ürün/Hizmet karşılığında ödenecek tüm tutarlar (tüm vergiler dahil
                            satış fiyatı, kargo bedeli, taksit
                            farkı tutarı, açık pazar ve/veya diğer butiklerinden eş zamanlı olarak gerçekleştirilen
                            alışverişlerde hak
                            kazanılan toplam indirim tutarı vb.) aşağıdaki tabloda gösterilmiştir.
                        </p>
                        <table class="table table-light table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">Ürün/Hizmet Açıklaması</th>
                                    <th scope="col">Adet</th>
                                    <th scope="col">Peşin Fiyatı</th>
                                    <th scope="col">Ara Toplam (KDV Dahil)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td scope="row">Zerduz Finansal Asistan</td>
                                    <td>{{ totalQuantity }}</td>
                                    <td>{{ totalQuantity * $store.state.price.price.withoutTax }} USD</td>
                                    <td>{{ totalQuantity * $store.state.price.price.withoutTax }} USD</td>
                                </tr>
                                <tr>
                                    <td scope="row">Kargo Tutarı</td>
                                    <td>-</td>
                                    <td>{{ calculateCargo }} USD</td>
                                    <td>{{ calculateCargo }} USD</td>
                                </tr>
                                <tr>
                                    <td scope="row"></td>
                                    <td></td>
                                    <td>Toplam</td>
                                    <td>{{ (totalQuantity * $store.state.price.price.withoutTax) + (calculateCargo) }} USD</td>
                                </tr>
                                <tr>
                                    <td scope="row">Kargo Hariç Toplam Ürün Bedeli</td>
                                    <td></td>
                                    <td></td>
                                    <td>{{ totalQuantity * $store.state.price.price.withoutTax }} USD</td>
                                </tr>
                                <tr>
                                    <td scope="row">Kargo Ücreti</td>
                                    <td></td>
                                    <td></td>
                                    <td>{{ calculateCargo }} USD</td>
                                </tr>
                                <tr>
                                    <td scope="row">Taksit Farkı</td>
                                    <td></td>
                                    <td></td>
                                    <td>0 USD</td>
                                </tr>
                                <tr>
                                    <td scope="row">Toplam Sipariş Bedeli</td>
                                    <td></td>
                                    <td></td>
                                    <td>{{ (totalQuantity * $store.state.price.price.withoutTax) + (calculateCargo) }} USD</td>
                                </tr>
                                <tr>
                                    <td scope="row">Ödeme Şekli ve Planı</td>
                                    <td></td>
                                    <td></td>
                                    <td>Tek Çekim</td>
                                </tr>
                                <tr>
                                    <td scope="row">Teslimat Adresi</td>
                                    <td></td>
                                    <td></td>
                                    <td>{{ contractData.shippingAddress.address1 }} {{
                                            contractData.shippingAddress.district }} {{ contractData.shippingAddress.state
    }} / {{ contractData.shippingAddress.city }} / {{
    contractData.shippingAddress.country }} {{ contractData.shippingAddress.zipcode
    }}</td>
                                </tr>
                                <tr>
                                    <td scope="row">Teslim Edilecek Kişi</td>
                                    <td></td>
                                    <td></td>
                                    <td>{{ contractData.first_name }} {{ contractData.last_name }}</td>
                                </tr>
                                <tr>
                                    <td scope="row">Fatura Adresi</td>
                                    <td></td>
                                    <td></td>
                                    <td>{{ contractData.billingData.address1 }} {{ contractData.billingData.district }}
                                        {{ contractData.billingData.state }} / {{ contractData.billingData.city }} / {{
                                                contractData.billingData.country }} {{ contractData.billingData.zipcode }}</td>
                                </tr>
                                <tr>
                                    <td scope="row">Sipariş Tarihi</td>
                                    <td></td>
                                    <td></td>
                                    <td>{{ currentDateFormat }}</td>
                                </tr>
                                <tr>
                                    <td scope="row">Teslim Şekli</td>
                                    <td></td>
                                    <td></td>
                                    <td>KARGO</td>
                                </tr>
                                <tr>
                                    <td scope="row">Teslimat Süresi*</td>
                                    <td></td>
                                    <td></td>
                                    <td>En geç 30 gün</td>
                                </tr>
                                <tr>
                                    <td scope="row">Kargo Şirketi’ne Teslim Süresi**</td>
                                    <td></td>
                                    <td></td>
                                    <td>{{ shippingDateFormat }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>*Sözleşme ve ilgili mevzuat hükümlerinde yer alan istisnalar saklıdır.
                            <br>**Belirtilen süre teslimatın taahhüdü değildir, satıcı tarafından kargo şirketine teslim
                            edilme süresini ifade
                            eder.
                        </p>
                        <p><strong>6.3.</strong>SÖZ KONUSU ÜRÜN BEDELİ, “ZERDUZ ALICI GÜVENCESİ” KAPSAMINDA SATICI
                            ADINA, ZERDUZ TARAFINDAN ALICI’DAN TAHSİL
                            EDİLMEKTEDİR. ALICI MALIN BEDELİNİ ZERDUZ'A ÖDEMEKLE, ÜRÜN BEDELİNİ SATICI’YA ÖDEMİŞ
                            SAYILACAK VE BİR DAHA ÖDEME
                            YÜKÜMLÜLÜĞÜ ALTINA GİRMEYECEKTİR. ALICI’NIN İLGİLİ MEVZUAT KAPSAMINDA İADE HAKLARI SAKLIDIR.
                        </p>
                        <h3>7. GENEL HÜKÜMLER</h3>
                        <p><strong>7.1.</strong> Satıcı, Ürün/Hizmet’i eksiksiz, siparişte belirtilen niteliklere uygun
                            ve varsa garanti belgeleri, kullanım
                            kılavuzları ile mevzuat gereği Ürün/Hizmet’le birlikte teslim etmesi gereken sair bilgi ve
                            belgeler ile teslim
                            etmeyi kabul, beyan ve taahhüt eder.</p>
                        <p><strong>7.2.</strong> Ürün, Alıcı veya Alıcı tarafından belirlenen üçüncü kişiye, taahhüt
                            edilen teslim süresi içerisinde ve her
                            halükârda 30 (otuz) günlük yasal süreyi aşmamak koşulu ile, Alıcı’nın Platform’da belirtmiş
                            olduğu teslimat
                            adresine Kargo Şirketi tarafından teslim edilir. Bu süre içerisinde Satıcı’nın edimini
                            yerine getirmemesi
                            durumunda Alıcı Sözleşme'yi feshedebilecektir. Ancak Alıcı’nın isteği veya kişisel
                            ihtiyaçları doğrultusunda
                            hazırlanan Ürün/Hizmet satışlarında teslim süresi ilgili 30 (otuz) günü aşabilecektir.
                            Ayrıca, sipariş durumu “Ön
                            Sipariş” veya “Sipariş Üzerine Üretim” olarak belirtilen Ürün/Hizmet için teslimat süresi de
                            30 (otuz) günü
                            aşabilecek olup Alıcı, Alıcı’nın isteği veya kişisel ihtiyaçları doğrultusunda hazırlanan
                            “Sipariş Üzerine Üretim”
                            ya da “Ön Sipariş” statüsünde olan bir Ürün/Hizmet satın aldığında teslimatın 30 (otuz) gün
                            içerisinde yapılmaması
                            dolayısıyla Sözleşme’yi feshedemeyecektir.</p>
                        <p><strong>7.3.</strong> Satıcı Ürün’ü Kargo Şirketi aracılığı ile Alıcı’ya göndermekte ve
                            teslim ettirmektedir. Kargo Şirketi’nin
                            Alıcı’nın bulunduğu yerde şubesi olmaması halinde Alıcı’nın Ürün’ü Kargo Şirketi’nin Satıcı
                            tarafından bildirilen
                            yakın bir diğer şubesinden teslim alması gerekmektedir.</p>
                        <p><strong>7.4.</strong> Alıcı’nın sipariş esnasında Ürün’ü ZERDUZ Teslimat Noktası’na teslim
                            edilmesini seçmesi halinde, Ürün, Kargo
                            Şirketi tarafından Alıcı’nın seçmiş olduğu teslimat noktasına taahhüt edilen süre içerisinde
                            ve her halükârda en
                            geç 30 (otuz) günlük yasal süre içerisinde teslim edilecektir. Ürün’ün ZERDUZ Teslimat
                            Noktası’na bırakılmasının
                            ardından Alıcı, seçmiş olduğu teslimat adresi bilgilerinde kayıtlı olan telefon numarasına
                            gelen kod ile en geç 3
                            (üç) gün içerisinde Ürün’ü teslim alacaktır.</p>
                        <p><strong>7.5.</strong> Alıcı’nın herhangi bir nedenle Ürün/Hizmet’i teslim almaması halinde,
                            Alıcı’nın Ürün/Hizmet’i iade ettiği
                            kabul edilecek olup bu halde, varsa teslimat masrafları da dâhil olmak üzere Alıcı’dan
                            tahsil edilen tüm ödemeler
                            yasal süresi içerisinde Alıcı’ya iade edilecektir.</p>
                        <p><strong>7.6.</strong> Alıcı veya Alıcı tarafından belirlenen üçüncü kişinin teslim anında
                            adreste bulunmaması durumunda Alıcı'nın
                            Ürün/Hizmet’i geç teslim almasından ve/veya hiç teslim almamasından kaynaklanan zararlardan
                            ve giderlerden Satıcı
                            sorumlu değildir.</p>
                        <p><strong>7.7.</strong> Ürün/Hizmet’in teslimat masrafları aksine bir hüküm yoksa Alıcı’ya
                            aittir. Satıcı, Platform’da teslimat
                            ücretinin kendisince karşılanacağını beyan etmişse teslimat masrafları Satıcı'ya ait
                            olacaktır.</p>
                        <p><strong>7.8.</strong> Satıcı, Sözleşme'den doğan ifa yükümlülüğünün süresi dolmadan Alıcı’yı
                            Platform üzerinden bilgilendirmek ve
                            açıkça onayını almak suretiyle muadil bir Ürün/Hizmet tedarik edebilecektir.</p>
                        <p><strong>7.9.</strong> Ürün/Hizmet ediminin yerine getirilmesinin imkansızlaştığı hallerde
                            Satıcı’nın bu durumu öğrendiği tarihten
                            itibaren 3 (üç) gün içinde Alıcı’ya yazılı olarak veya veri saklayıcısı ile bildirmesi ve
                            varsa teslimat
                            masrafları da dâhil olmak üzere tahsil edilen tüm ödemeleri bildirim tarihinden itibaren en
                            geç 14 (ondört) gün
                            içinde iade etmesi zorunludur. Ürün/Hizmet’in stokta bulunmaması durumu, Ürün/Hizmet
                            ediminin yerine
                            getirilmesinin imkânsızlaşması olarak kabul edilmez.</p>
                        <p><strong>7.10.</strong> Alıcı, Ürün’ü teslim almadan önce muayene edecek; ezik, kırık,
                            ambalajı yırtılmış vb. hasarlı, ayıplı veya
                            eksik Ürün/Hizmet’i teslim almayacaktır. Teslim alınan Ürün/Hizmet’in hasarsız ve sağlam
                            olduğu kabul edilecektir.
                            Teslimden sonra Ürün’ün özenle korunması borcu, Alıcı’ya aittir. Cayma hakkı kullanılacaksa
                            Ürün/Hizmet
                            kullanılmamalı ve Ürün/Hizmet faturası ve teslim sırasında Alıcı’ya iletilen diğer tüm
                            belgeler (örneğin garanti
                            belgesi, kullanım kılavuzu vb.) ile birlikte iade edilmesi gerekmektedir.</p>
                        <p><strong>7.11.</strong> Alıcı, Sözleşme konusu bedeli ödemekle yükümlü olup, herhangi bir
                            nedenle Sözleşme konusu bedelin
                            ödenmemesinin ve/veya Banka kayıtlarında iptal edilmesinin Satıcı’nın Ürün/Hizmet’i teslim
                            yükümlülüğü ile
                            Sözleşme’den kaynaklanan sair yükümlülüklerinin sona ereceğini kabul, beyan ve taahhüt eder.
                            Alıcı, herhangi bir
                            sebeple Banka tarafından başarısız kodu gönderilen ancak buna rağmen Banka tarafından
                            Satıcı’ya yapılan ödemelere
                            ilişkin olarak, Satıcı’nın herhangi bir sorumluluğunun bulunmadığını kabul, beyan ve taahhüt
                            eder.</p>
                        <p><strong>7.12.</strong> Alıcı, Ürün’ün teslim edilmesinden sonra Alıcı’ya ait kredi kartının
                            yetkisiz kişilerce haksız kullanılması
                            sonucunda Sözleşme konusu bedelin ilgili Banka tarafından Satıcı’ya ödenmemesi halinde,
                            Alıcı Ürün’ü 3 (üç) gün
                            içerisinde iade masrafları Alıcı’ya ait olacak şekilde Satıcı’ya iade edeceğini kabul, beyan
                            ve taahhüt eder.</p>
                        <p><strong>7.13.</strong> Sözleşme kapsamında herhangi bir nedenle Alıcı’ya bedel iadesi
                            yapılması gereken durumlarda, Alıcı, ödemeyi
                            kredi kartı ile yapmış olması halinde, Satıcı tarafından kredi kartına iade edilen tutarın
                            banka tarafından Alıcı
                            hesabına yansıtılmasına ilişkin ortalama sürecin 2 (iki) ile 3 (üç) haftayı bulabileceğini,
                            bu tutarın Satıcı
                            tarafından Banka’ya iadesinden sonra Alıcı’nın hesaplarına yansıması halinin tamamen Banka
                            işlem süreci ile ilgili
                            olduğunu ve olası gecikmelerde Banka’nın sorumlu olduğunu ve bunlar için ZERDUZ’u ve
                            Satıcı’yı sorumlu
                            tutamayacağını kabul, beyan ve taahhüt eder.</p>
                        <p><strong>7.14.</strong> Alıcı ile sipariş esnasında kullanılan kredi kartı hamilinin aynı kişi
                            olmaması veya Ürün/Hizmet’in
                            Alıcı’ya tesliminden evvel, siparişte kullanılan kredi kartına ilişkin güvenlik açığı tespit
                            edilmesi halinde,
                            kredi kartı hamiline ilişkin kimlik ve iletişim bilgilerini, siparişte kullanılan kredi
                            kartının bir önceki aya
                            ait ekstresini yahut kart hamilinin Banka’dan kredi kartının kendisine ait olduğuna ilişkin
                            yazıyı ibraz etmesini
                            Alıcı’dan talep edilebilecektir. Alıcı’nın talebe konu bilgi/belgeleri temin etmesine kadar
                            geçecek sürede sipariş
                            dondurulacak olup, söz konusu taleplerin 24 (yirmidört) saat içerisinde karşılanmaması
                            halinde ise Satıcı,
                            siparişi iptal etme hakkını haizdir.</p>
                        <p><strong>7.15.</strong> Alıcı Sözleşme konusu bedeli ZERDUZ Cüzdan aracılığıyla ödemesi ve bu
                            siparişe ilişkin cayma hakkını
                            kullanması halinde cayma hakkından kaynaklı bedel iadesi ZERDUZ Cüzdan’a tek seferde
                            yapılabilecektir.</p>
                        <p><strong>7.16.</strong> Sipariş edilmeyen Ürün/Hizmet’in gönderilmesi durumunda, Alıcı’ya
                            karşı herhangi bir hak ileri sürülemez.
                            Bu hallerde, Alıcı’nın sessiz kalması ya da söz konusu Ürün/Hizmet’i kullanmış olması,
                            sözleşmenin kurulmasına
                            yönelik kabul beyanı olarak yorumlanamaz.</p>
                        <p><strong>7.17.</strong> Alıcı’nın sipariş edebileceği Ürün/Hizmet adetlerine Platform’dan
                            yapılacak duyurularla kısıt
                            getirilebilir. Alıcı’nın Platform’dan yapılan duyurularda belirtilen adetin üzerinde
                            Ürün/Hizmet sipariş etmek
                            istemesi halinde sipariş vermesi engellenebilecek, siparişi verdikten sonra belirtilen
                            adedin üstünde sipariş
                            verdiğinin tespit edebilmesi halinde ise belirtilen adedin üstündeki siparişleri iptal
                            edilebilecek ve bu halde
                            varsa iptal edilen siparişlere ilişkin teslimat masrafları da dâhil olmak üzere tahsil
                            edilen tüm ödemeler yasal
                            süresi içerisinde Alıcı’ya iade edilecektir. Alıcı işbu hususları kabul ederek siparişini
                            oluşturduğunu, adet
                            sınırlamasını geçen siparişlerinin engellenebileceği ve iptal edilebileceğini kabul, beyan
                            ve taahhüt eder.</p>
                        <p><strong>7.18.</strong> Satıcı’nın herhangi bir sebeple tedarik edemediği siparişler,
                            Alıcı’nın onayının alınması halinde,
                            mevzuattaki yasal teslim süresini aşmamak ve Alıcı’nın Ürün/Hizmet ile aynı özellikleri haiz
                            olmak kaydıyla, bir
                            başka satıcıya aktarılabilecektir. Böyle bir durumda Ürün/Hizmet yeni satıcı tarafından
                            Alıcı’ya gönderilecek olup
                            Sözleşme yeni satıcı ile Alıcı arasında kurulmuş olacaktır. Bu halde, Alıcı’ya herhangi bir
                            ek bedel, ücret
                            ve/veya masraf yansıtılmayacaktır.</p>
                        <p><strong>7.19.</strong> Alıcı tüketici sıfatıyla talep, şikayet ve önerilerini yukarıda yer
                            alan Satıcı iletişim bilgilerini
                            kullanmak suretiyle ve/veya Platform’un sağladığı kanallarla (0 212 331 0 200 telefon
                            numarası üzerinden ve
                            “ZERDUZ Asistan’a Sor” başlıklı alandan) ulaştırabilecektir.</p>
                        <h3>8. ÖZEL ŞARTLAR</h3>
                        <p><strong>8.1.</strong> Alıcı, aksi belirtilmedikçe, Platform’da birden fazla butikten tek bir
                            sepette alışveriş yapabilecektir.
                            Aynı sepet içerisinde farklı butikten alınan her bir Ürün/Hizmet için Satıcı tarafından
                            birden fazla fatura
                            kesilebilecektir. Şüpheye mahal bırakmamak bakımından belirtilmelidir ki, Satıcı, Alıcı’nın
                            farklı butiklerden
                            aldığı Ürün/Hizmet’in teslimatını mevzuattaki yasal süre içerisinde kalmak koşuluyla farklı
                            zamanlarda
                            gerçekleştirebilecektir.</p>
                        <p><strong>8.2.</strong> Alıcı’nın vereceği siparişlerde kurumsal fatura seçeneğini seçmesi
                            durumunda Satıcı, Alıcı tarafından
                            Platform üzerinden bildirilecek vergi kimlik numarası ve vergi dairesi bilgilerini
                            kullanarak kurumsal fatura
                            düzenleyecektir. Faturada yer alması gereken bilgilerin doğru, güncel ve eksiksiz girilmesi
                            tamamen Alıcı’nın
                            sorumluluğunda olup, bu sebeple doğabilecek tüm zararlardan bizzat Alıcı sorumludur.</p>
                        <p><strong>8.3.</strong> Platform üzerinden kredi kart ile ödeme yapılması halinde, Banka
                            tarafından kampanyalar düzenlenerek Alıcı
                            tarafından seçilen taksit adedinin daha üstünde bir taksit adedi uygulanabilecek veya taksit
                            erteleme gibi ek
                            hizmetler sunulabilecektir. Bu tür kampanyalar Banka’nın inisiyatifindedir. Alıcı’nın kredi
                            kartının hesap kesim
                            tarihinden itibaren sipariş toplamı taksit adedine bölünerek kredi kartı özetine Banka
                            tarafından yansıtılacaktır.
                            Banka taksit tutarlarını küsurat farklarını dikkate alarak aylara eşit olarak
                            dağıtmayabilir. Detaylı ödeme
                            planlarının oluşturulması Banka’nın inisiyatifindedir.</p>
                        <p><strong>8.4.</strong> Dijital ürünler fiziki gönderime uygun olmayıp, teslimat ürün
                            niteliğine göre şartlarında belirtilen şekilde
                            gerçekleştirilecektir. Sözleşme’nin yer alıp da teslimat şekilleri vb. gibi fiziki ürünler
                            için geçerli olan
                            düzenlemeler dijital ürünlere uygulanmayacak olup bu maddelerdeki düzenlemeler uygulanabilir
                            olduğu ölçüde ürün
                            şartlarında belirtilen koşul ve açıklamalara uygun olacak şeklinde yorumlanmalıdır.</p>
                        <p><strong>8.5</strong>. Sipariş verilen Ürün’ün elektrikli motosiklet olması halinde kurulumu
                            gerçekleştikten veya tescil işlemi
                            yapılıp ruhsatlandıktan sonra Platform üzerinden iadesi mümkün olmamaktadır.</p>
                        <p><strong>8.6.</strong> Platformda satışa sunulan Ürün/Hizmetler yalnızca Satıcı tarafından
                            belirlenen sınırlı lokasyonlara
                            (il/ilçe/bölge) teslim edilmek üzere satışa sunulabilecek olup, sipariş sürecinde Alıcı'nın
                            bu ürün/hizmetler için
                            teslimat adresini Satıcı tarafından belirlenmiş olan lokasyonlardan biri dışında seçmesi
                            halinde ilgili sipariş
                            verilemeyecek/satın alım gerçekleşmeyecektir.</p>
                        <p><strong>8.7.</strong> Türkiye Cumhuriyeti resmi kamu kurum ve kuruluşları ile koordineli
                            yürütülen "Depreme Yardım Seferberliği"
                            ve benzeri seferberlik ve yardım işlemleriyle bağlantılı siparişlerde (ö: koli yardımı,
                            vb.), Mesafeli Sözleşmeler
                            Yönetmeliği’nin 15/1-h maddesi gereği cayma hakkı kullanılamayacaktır.</p>
                        <h3>9. KİŞİSEL VERİLERİN KORUNMASI VE FİKRİ-SINAİ HAKLAR</h3>
                        <p><strong>9.1.</strong> Satıcı, işbu sözleşme kapsamındaki kişisel verileri sadece
                            Ürün/Hizmet’in sunulması amacıyla sınırlı olarak
                            ve 6698 sayılı Kişisel Verilerin Korunması Kanunu’na, (“KVKK”) ikincil mevzuata ve Kişisel
                            Verileri Koruma Kurulu
                            kararlarına uygun olarak işleyecektir. Satıcı, Platform üzerinden eriştiği kişisel veriler
                            haricinde Alıcı’nın
                            kişisel verilerini işlemeyeceğini ve Platform üzerinden sağlanan yöntemler dışında Alıcı ile
                            harici olarak
                            iletişime geçmeyeceğini kabul, beyan ve taahhüt eder.</p>
                        <p><strong>9.2.</strong> Alıcı, işbu Sözleşme kapsamında sağladığı kişisel verilerin doğru,
                            eksiksiz ve güncel olduğunu kontrol
                            etmekle, bu bilgileri üçüncü kişilerle paylaşmamak, ilgisiz kişilerce erişilememesi için
                            virüs ve benzeri zararlı
                            uygulamalara ilişkin olanlar dahil gerekli tedbirleri almak ve söz konusu kişisel verilerin
                            güvenliğini sağlamakla
                            yükümlü olduğunu, aksi halde doğacak zararlardan ve üçüncü kişilerden gelen taleplerden
                            bizzat sorumlu olduğunu
                            kabul, beyan ve taahhüt eder.</p>
                        <p><strong>9.3.</strong> Platform’a ait her türlü bilgi ve içerik ile bunların düzenlenmesi,
                            revizyonu ve kısmen/tamamen kullanımı
                            konusunda; Satıcı'nın anlaşmasına göre diğer üçüncü şahıslara ait olanlar hariç; tüm
                            fikri-sınai haklar ve
                            mülkiyet hakları ZERDUZ’a aittir.</p>
                        <h3>10. CAYMA HAKKI</h3>
                        <p><strong>10.1.</strong> Alıcı, 15 (onbeş) gün içinde herhangi bir gerekçe göstermeksizin ve
                            cezai şart ödemeksizin Sözleşme’den
                            cayma hakkına sahiptir.</p>
                        <p><strong>10.2.</strong> Cayma hakkı süresi, Hizmet için Sözleşme’nin kurulduğu gün; Ürün için
                            Alıcı’nın veya Alıcı tarafından
                            belirlenen üçüncü kişinin Ürün’ü teslim aldığı gün başlar. Ancak Alıcı, Sözleşme’nin
                            kurulmasından Ürün teslimine
                            kadar olan süre içinde de cayma hakkını kullanabilir.</p>
                        <p><strong>10.3.</strong> Cayma hakkı süresinin belirlenmesinde;</p>
                        <ul class="list-unstyled">
                            <li class="ms-4">
                                <strong>a) </strong> Tek sipariş konusu olup ayrı ayrı teslim edilen Ürün’de, Alıcı veya
                                Alıcı tarafından
                                belirlenen üçüncü kişinin son Ürün’ü teslim aldığı gün,
                            </li>
                            <li class="ms-4">
                                <strong>b) </strong> Birden fazla parçadan oluşan Ürün’de, Alıcı veya Alıcı tarafından
                                belirlenen üçüncü
                                kişinin son parçayı teslim aldığı gün,
                            </li>
                            <li class="ms-4">
                                <strong>c) </strong> Belirli bir süre boyunca Ürün’ün düzenli tesliminin yapıldığı
                                durumlarda, Alıcı veya
                                Alıcı tarafından belirlenen üçüncü kişinin ilk Ürün’ü teslim aldığı gün
                                esas alınır.
                            </li>
                        </ul>
                        <p><strong>10.4.</strong> Ürün teslimi ile Hizmet ifasının birlikte olduğu durumlarda, Ürün
                            teslimine ilişkin cayma hakkı hükümleri
                            uygulanır.</p>
                        <p><strong>10.5.</strong> Satıcı;</p>
                        <ul class="list-unstyled">
                            <li class="ms-4">
                                <strong>a) </strong> Ürün'ün teslimi veya Hizmet’in ifasından önce Alıcı’nın cayma
                                hakkını kullanması
                                durumunda cayma hakkının kullanıldığına ilişkin bildirimin kendisine ulaştığı tarihten
                                itibaren,
                            </li>
                            <li class="ms-4">
                                <strong>b) </strong> Ürün’ün tesliminden sonra Alıcı’nın cayma hakkını kullanması
                                durumunda, cayma
                                bildiriminin kendisine ulaştığı tarih itibarıyla bedel Satıcı’ya aktarılmamışsa cayma
                                hakkına konu Ürün’ün, iade
                                için öngörülen Kargo Şirketi’ne teslim edildiği tarihten veya iade için öngörülenin
                                haricinde bir Kargo Şirketi
                                ile iade edilmesi durumunda da Satıcı’ya ulaştığı tarihten itibaren,
                            </li>
                            <li class="ms-4">
                                <strong>c) </strong> Alıcı’nın siparişinin yasal süre içerisinde teslim edilememesi
                                nedeniyle Sözleşme’yi
                                fesih hakkını kullanması durumunda fesih bildiriminin kendisine ulaştığı tarihten
                                itibaren
                                15 (onbeş) gün içinde, tahsil ettiği Sözleşme konusu bedeli ile teslimat masraflarının
                                Alıcı’ya iadesinden
                                sorumludur.
                            </li>
                        </ul>
                        <p><strong>10.6.</strong> Cayma hakkı bildiriminin ve Sözleşme’ye ilişkin diğer bildirimlerin
                            mevzuata uygun ve süresi içerisinde
                            Platform’da belirtilen ZERDUZ’a ve/veya Satıcı’ya ait iletişim kanallarından yapılması
                            şarttır. Cayma bildiriminin
                            yapılacağı iletişim kanallarına https://www.zerduz.com/contact linkinden
                            ulaşılabilmektedir.</p>
                        <p><strong>10.7.</strong> Cayma hakkının kullanılması halinde:</p>
                        <ul class="list-unstyled">
                            <li class="ms-4">
                                <strong>a) </strong> Alıcı cayma hakkını kullanmasından itibaren 14 (ondört) gün
                                içerisinde Ürün’ü
                                Satıcı'ya Kargo Şirketi’yle geri gönderir.
                            </li>
                            <li class="ms-4">
                                <strong>b) </strong> Cayma hakkı kapsamında iade edilecek Ürün kutusu, ambalajı, varsa
                                standart
                                aksesuarları ve varsa Ürün ile birlikte hediye edilen diğer Ürünler’in de eksiksiz ve
                                hasarsız olarak iade
                                edilmesi gerekmektedir.
                            </li>
                        </ul>
                        <p><strong>10.8.</strong> Alıcı cayma süresi içinde Ürün’ü, işleyişine, teknik özelliklerine ve
                            kullanım talimatlarına uygun bir
                            şekilde kullandığı takdirde meydana gelen değişiklik ve bozulmalardan sorumlu değildir.</p>
                        <p><strong>10.9.</strong> Cayma hakkının kullanılmasını takip eden 14 (ondört) gün içerisinde
                            Sözleşme konusu bedeller Alıcı’ya
                            Alıcı’nın ödeme yöntemi ile iade edilmektedir. Ürün/Hizmet, Satıcı'ya iade edilirken,
                            Ürün/Hizmet’in teslimi
                            sırasında Alıcı’ya ibraz edilmiş olan orijinal faturanın da Alıcı tarafından iade edilmesi
                            gerekmektedir.
                            Alıcı’nın kurumsal fatura istemesi halinde ilgili Ürün/Hizmet iadesi için iade faturası
                            kesmesi veya mümkünse
                            süresi içerisinde ticari faturayı kendi sistemlerinden reddetmesi gerekmektedir.</p>
                        <p><strong>10.10.</strong> Alıcı iade edeceği Ürün/Hizmet’i Ön Bilgilendirme Formu’nda
                            belirtilen Satıcı'nın Kargo Şirketi ile
                            Satıcı'ya gönderdiği sürece iade kargo bedeli Satıcı'ya aittir. İade için Alıcı’nın
                            bulunduğu yerde Satıcı’nın
                            Kargo Şirketi şubesi bulunmaması halinde, Alıcı Ürün’ü herhangi bir Kargo Şirketi’yle
                            gönderebilecektir. Bu halde
                            iade kargo bedeli ve Ürün’ün kargo sürecinde uğrayacağı hasardan Satıcı sorumludur.</p>
                        <p><strong>10.11.</strong> Alıcı cayma hakkını işbu maddede belirtilen süre ve usuller dahilinde
                            kullanacak olup aksi halde cayma
                            hakkını kaybedecektir.</p>
                        <h3>11. CAYMA HAKKININ KULLANILAMAYACAĞI HALLER</h3>
                        <p><strong>11.1.</strong> Alıcı aşağıdaki sözleşmelerde cayma hakkını kullanamaz:</p>
                        <ul class="list-unstyled">
                            <li class="ms-4">
                                <strong>a) </strong>Fiyatı finansal piyasalardaki dalgalanmalara bağlı olarak değişen ve
                                Satıcı veya ZERDUZ’un
                                kontrolünde olmayan mal veya hizmetlere ilişkin sözleşmeler,
                            </li>
                            <li class="ms-4">
                                <strong>b) </strong> Tüketicinin istekleri veya kişisel ihtiyaçları doğrultusunda
                                hazırlanan mallara ilişkin
                                sözleşmeler,
                            </li>
                            <li class="ms-4">
                                <strong>c) </strong> Çabuk bozulabilen veya son kullanma tarihi geçebilecek malların
                                teslimine ilişkin
                                sözleşmeler,
                            </li>
                            <li class="ms-4">
                                <strong>d) </strong> Tesliminden sonra ambalaj, bant, mühür, paket gibi koruyucu
                                unsurları açılmış olan
                                mallardan; iadesi
                                sağlık ve hijyen açısından uygun olmayanların teslimine ilişkin sözleşmeler,
                            </li>
                            <li class="ms-4">
                                <strong>e) </strong> Tesliminden sonra başka ürünlerle karışan ve doğası gereği
                                ayrıştırılması mümkün olmayan
                                mallara
                                ilişkin sözleşmeler,
                            </li>
                            <li class="ms-4">
                                <strong>f) </strong> Ürünün tesliminden sonra ambalaj, bant, mühür, paket gibi koruyucu
                                unsurları açılmış olması
                                halinde
                                maddi ortamda sunulan kitap, dijital içerik ve bilgisayar sarf malzemelerine ilişkin
                                sözleşmeler,
                            </li>
                            <li class="ms-4">
                                <strong>g) </strong> Abonelik sözleşmesi kapsamında sağlananlar dışında gazete ve dergi
                                gibi süreli yayınların
                                teslimine
                                ilişkin sözleşmeler,
                            </li>
                            <li class="ms-4">
                                <strong>h) </strong> Belirli bir tarihte veya dönemde yapılması gereken, konaklama, eşya
                                taşıma, araba kiralama,
                                yiyecek
                                içecek tedariki ve eğlence veya dinlenme amacıyla yapılan boş zamanın
                                değerlendirilmesine ilişkin
                                sözleşmeler,
                            </li>
                            <li class="ms-4">
                                <strong>i) </strong>Elektronik ortamda anında ifa edilen hizmetler ile Alıcı’ya anında
                                teslim edilen gayri maddi
                                mallara
                                ilişkin sözleşmeler,
                            </li>
                            <li class="ms-4">
                                <strong>j) </strong> Cayma hakkı süresi sona ermeden önce, Alıcı’nın onayı ile ifasına
                                başlanan hizmetlere
                                ilişkin
                                sözleşmeler,
                                cayma hakkı kullanılamayacak; bu siparişler bakımından Platform üzerinden iade kodu da
                                oluşturulamayacaktır.
                            </li>
                        </ul>
                        <p><strong>11.2.</strong> Ürün/Hizmet’in Yönetmelik'in uygulama alanı dışında bırakılmış olan
                            (Sözleşme’nin 3.3. maddesinde
                            listelenmiştir) Ürün/Hizmet türlerinden müteşekkil olması halinde Alıcı ve Satıcı arasındaki
                            hukuki ilişkiye
                            Yönetmelik hükümleri uygulanamaması sebebiyle cayma hakkı kullanılamayacak; bu siparişler
                            bakımından Platform
                            üzerinden iade kodu da oluşturulamayacaktır.</p>
                        <p><strong>11.3.</strong> Platform üzerinden elektronik kod satın alındığı durumlarda bahse konu
                            siparişlerde Yönetmelik gereği cayma
                            hakkı söz konusu olmayacaktır. Bu siparişler bakımından Platform üzerinden iade kodu da
                            oluşturulamayacaktır.</p>
                        <h3>12. UYUŞMAZLIKLARIN ÇÖZÜMÜ</h3>
                        <p><strong>12.1.</strong> Sözleşme’nin uygulanmasında, Bakanlık’ça ilan edilen değerlere uygun
                            olarak Alıcı’nın Ürün/Hizmet’i satın
                            aldığı ve ikametğahının bulunduğu yerdeki Tüketici Hakem Heyetleri ile Tüketici Mahkemeleri
                            yetkilidir.</p>
                        <h3>13. BİLDİRİMLER ve DELİL SÖZLEŞMESİ</h3>
                        <p><strong>13.1.</strong> Sözleşme tahtında Taraflar arasında yapılacak her türlü yazışma,
                            mevzuatta sayılan zorunlu haller dışında,
                            yazılı olarak yapılacaktır.</p>
                        <p><strong>13.2.</strong> Alıcı, Sözleşme'den doğabilecek ihtilaflarda ZERDUZ’un ve Satıcı’nın
                            ticari defter, bilgisayar, kayıt ve
                            diğer belgelerinin bağlayıcı, kesin ve münhasır delil teşkil edeceğini, bu maddenin Hukuk
                            Muhakemeleri Kanunu'nun
                            193. maddesi anlamında delil sözleşmesi niteliğinde olduğunu kabul, beyan ve taahhüt eder.
                        </p>
                        <h3>14. YÜRÜRLÜK</h3>
                        <p><strong>14.1.</strong> 14 (on dört) maddeden ibaret Sözleşme, Taraflar’ca okunarak, işlem
                            tarihinde, Alıcı tarafından elektronik
                            ortamda onaylanmak suretiyle akdedilmiş ve yürürlüğe girmiştir. Sözleşme’nin bir nüshası
                            Alıcı’nın üyelik
                            hesabında mevcuttur ve talep edilmesi halinde ayrıca elektronik posta ile de
                            gönderilebilecektir.</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-bs-dismiss="modal">{{ $t('checkoutPage.thirdStep.close') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="success-section">
        <div class="container py-3 mt-5">
            <div class="px-4 mt-4 text-center">
                <i class="fa-regular fa-circle-check display-1 mb-3 p-1" id="page-header-check"></i>
                <h1 class="text-center p-1" id="page-header-text" style="background-color: green;">Siparişiniz Başarıyla
                    Alındı!</h1>
                <div class="col-lg-6 mx-auto">
                    <span class="badge bg-success fs-6 mt-3 mb-3">Sipariş Numarası: #-</span>
                </div>
                <div class="col-lg-6 mx-auto">
                    <p class="lead mb-5">Size sipariş bilgilerinizi içeren bir e-posta gönderdik</p>
                </div>
            </div>
        </div>

        <div class="container pb-3 mb-5">
            <div class="col-md-5 col-lg-4 mx-auto rounded p-4">
                <h4 class="justify-content-between align-items-center mb-3">
                    <span class="text-primary text-center">Sipariş Özeti</span>
                </h4>
                <ul class="list-group margin-inline-0 mb-3">
                    <li class="list-group-item d-flex justify-content-between lh-sm">
                        <div>
                            <h6 class="my-0">Zerduz</h6>
                            <small class="text-body-secondary">Vergiler Dahil</small>
                        </div>
                        <span class="text-body-secondary">- Adet</span>
                        <span class="text-body-secondary">$-</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between lh-sm">
                        <div>
                            <h6 class="my-0">Kargo ücreti</h6>
                        </div>
                        <span class="text-body-secondary">{{ $store.state.price.currency }}{{ calculateCargo }}</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between">
                        <span>Toplam</span>
                        <strong>$-</strong>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import $ from 'jquery';
import Countries from "@/helpers/countries";
import {
    register,
    login,
    getCartData,
    addAddress,
    getAddresses,
    deleteAddress,
    updateAddress,
    getAccount,
    checkoutOnly,
    updateCart,
    USDCurrency
} from '@/helpers/api';
import {
    alert,
    authTimedOut,
    validateTcNo,
    destroyAlerts
} from "@/helpers/funcs";
import {
    mask
} from 'vue-the-mask';
import { parsePhoneNumber } from 'libphonenumber-js';
import { isPossiblePhoneNumber } from 'libphonenumber-js';

export default {
    name: "CheckoutView",
    directives: {
        mask
    },
    data() {
        return {
            Countries,
            phoneInputID: "#phone",
            iti: null,
            phoneInputID2: "#newAddressPhone",
            iti2: null,
            phoneInputID3: "#editAddressPhone",
            iti3: null,
            token: window.localStorage.getItem('token'),
            userId: window.localStorage.getItem('userId'),
            cartData: [],
            cartItems: [],
            currentStep: 1,
            loginFormData: {
                input: '',
                mail: '',
                phone: '',
                password: '',
                rememberme: false
            },
            registerFormData: {
                name: '',
                surname: '',
                email: '',
                password: '',
                password_confirmation: '',
                tcno: '',
                phone: '',
                accounttype: 1,
                taxno: null,
                tax: '',
            },
            addressData: {
                address_name: '',
                type: 'shipping',
                address1: '',
                city: '',
                state: '',
                district: '',
                zipcode: '',
                country_code: 'tur',
                user_id: parseInt(window.localStorage.getItem('userId')),
                first_name: '',
                last_name: '',
                phone: '',
                default: false,
                selectedCountry: 'Turkey',
            },
            isActive: false,
            currentCardBackground: Math.floor(Math.random() * 25 + 1),
            cardName: "",
            cardNumber: "",
            cardMonth: "",
            cardYear: "",
            cardCvv: "",
            minCardYear: new Date().getFullYear(),
            amexCardMask: "#### ###### #####",
            otherCardMask: "#### #### #### ####",
            cardNumberTemp: "",
            isCardFlipped: false,
            focusElementStyle: null,
            isInputFocused: false,
            addresses: [],
            deleteIndex: null,
            deleteAddressData: [],
            editIndex: null,
            editAddressData: [],
            editAddressSelectedCountry: null,
            addressSelectType: "both",
            addressSelectShipping: null,
            addressSelectBilling: null,
            billingCheckbox: true,
            contractData: {
                first_name: '',
                last_name: '',
                phone: '',
                email: '',
                tcno: '',
                shippingAddress: {
                    address1: '',
                    city: '',
                    country: '',
                    district: '',
                    state: '',
                    zipcode: '',
                },
                billingAddress: {
                    address1: '',
                    city: '',
                    country: '',
                    district: '',
                    state: '',
                    zipcode: '',
                },
                billingData: {
                    first_name: '',
                    last_name: '',
                    tcno: '',
                    phone: '',
                    email: '',
                    address1: '',
                    city: '',
                    country: '',
                    district: '',
                    state: '',
                    zipcode: ''
                },
            },
            accountDetails: [],
            currentDate: new Date(),
            daysToAddForShipping: 3,
            sozlesmeCheckbox: false,
            firstPopup: true,
            currency: '0',
            cargo: {
                total: 0,
                base: 5,
                quantityThreshold: 0
            },
            nextButtonText: this.$t('checkoutPage.nextButtonText[0]'),
            registerCurrentStep: 1,
            stepElementHeight: null,
            finishOrderClicked: false,
            isPasswordValid: null,
            isConfirmationPasswordValid: null,
            showPassword: false,
            showPasswordConfirmation: false,
            currentCountryISO3: "",
            showLoginPassword: false,
            isRegisterPhoneValid: null,
            registerPhoneValidText: this.$t('errors.validation.phoneValidText[0]'),
            isNewAddressPhoneValid: null,
            newAddressPhoneValidText: this.$t('errors.validation.phoneValidText[0]'),
            isEditAddressPhoneValid: null,
            editAddressPhoneValidText: this.$t('errors.validation.phoneValidText[0]'),
            isTcNoValid: null,
            tcNoValidText: this.$t('errors.validation.tcnoValidText'),
            formattedPhoneNumber: "",
            isLoginInputValid: null,
            loginValidationText: '',
            paymentForm: ''
        }
    },
    computed: {
        calculateCargo() {
            this.changeTotalCargoAmount(this.cargo.base + (this.totalQuantity * this.cargo.quantityThreshold));
            return (this.cargo.base + (this.totalQuantity * this.cargo.quantityThreshold))
        },
        currentDateFormat() {
            return this.formatDate(this.currentDate)
        },
        shippingDateFormat() {
            const futureDate = new Date(this.currentDate);
            futureDate.setDate(futureDate.getDate() + this.daysToAddForShipping);
            return this.formatDate(futureDate);
        },
        allAddresses() {
            var addressArray = [];

            if (typeof this.addresses === "object" && this.addresses != null && this.addresses.length !== 0 && (Object.keys(this.addresses.data.billing_address).length > 0 || Object.keys(this.addresses.data.shipping_address).length > 0)) {

                for (let i = 0; i < this.addresses.data.billing_address.length; i++) {
                    const item = this.addresses.data.billing_address[i];

                    if (item && typeof item === "object" && item !== null) {
                        addressArray = addressArray.concat(item);
                    }
                }

                for (let i = 0; i < this.addresses.data.shipping_address.length; i++) {
                    const item = this.addresses.data.shipping_address[i];

                    if (item && typeof item === "object" && item !== null) {
                        addressArray = addressArray.concat(item);
                    }
                }
            }

            return addressArray;
        },
        filteredCardName() {
            return this.cardName.replace(/\s\s+/g, ' ').split('');
        },
        getCardType() {
            let number = this.cardNumber;
            let re = new RegExp("^4");
            if (number.match(re) != null) return "visa";

            re = new RegExp("^(34|37)");
            if (number.match(re) != null) return "amex";

            re = new RegExp("^5[1-5]");
            if (number.match(re) != null) return "mastercard";

            re = new RegExp("^6011");
            if (number.match(re) != null) return "discover";

            re = new RegExp('^9792');
            if (number.match(re) != null) return 'troy';

            return "visa"; // default type
        },
        generateCardNumberMask() {
            return this.getCardType === "amex" ? this.amexCardMask : this.otherCardMask;
        },
        minCardMonth() {
            if (this.cardYear === this.minCardYear) return new Date().getMonth() + 1;
            return 1;
        },
        totalQuantity() {
            if (this.getToken() != null && this.getUserId() != null) {
                if (this.cartData.data && this.cartData.data != []) {
                    var cartItem = {
                        productId: 1,
                        cartQuantity: this.cartData.data,
                        id: this.userId
                    };
                    return parseInt(cartItem.cartQuantity);
                } else {
                    const cartData = localStorage.getItem('cart');
                    if (cartData) {
                        var parsedCartData = JSON.parse(cartData);
                        return parsedCartData.reduce((total, currentItem) => {
                            return parseInt(currentItem.cartQuantity);
                        }, 0);
                    }
                }
            } else {
                const cartData = localStorage.getItem('cart');
                if (cartData) {
                    parsedCartData = JSON.parse(cartData);
                    return parsedCartData.reduce((total, currentItem) => {
                        return parseInt(currentItem.cartQuantity);
                    }, 0);
                }
            }
            return false;
        }
    },
    watch: {
        cardYear() {
            if (this.cardMonth < this.minCardMonth) {
                this.cardMonth = "";
            }
        },
        '$store.state.price.currencyType' () {
            this.updateCurrency();
        }
    },
    methods: {
        checkInput() {
            if (this.isEmail(this.loginFormData.input)) {
                this.loginFormData.mail = this.loginFormData.input;
                this.loginFormData.phone = '';

                this.isLoginInputValid = true;
                this.loginValidationText = "";
            } else if (this.isPhoneNumber(this.loginFormData.input)) {
                this.loginFormData.phone = this.loginFormData.input;
                this.loginFormData.mail = '';

                this.isLoginInputValid = true;
                this.loginValidationText = "";
            } else {
                if (this.isPhoneNumber("+" + this.loginFormData.input)) {
                    this.loginFormData.phone = "+" + this.loginFormData.input;
                    this.loginFormData.mail = '';

                    this.isLoginInputValid = true;
                    this.loginValidationText = "";
                } else {
                    this.isLoginInputValid = false;
                    this.loginValidationText = this.$t('errors.login.inputCheckInputError');
                }
                
            }
        },
        isEmail(value) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(value);
        },
        isPhoneNumber(value) {
            var validation = isPossiblePhoneNumber(value);
            return validation;
        },
        tcNoControl() {
            const { validate, text } = validateTcNo(String(this.registerFormData.tcno));
            if (validate == false) {
                this.tcNoValidText = text;
                this.isTcNoValid = false;
            } else if (validate == true) {
                this.isTcNoValid = true;
            } else {
                this.isTcNoValid = true;
            }
        },
        validateRegisterPhone() {
            if (this.registerFormData.phone != null && this.registerFormData.phone != '') {
                if (this.iti.isValidNumber()) {
                this.isRegisterPhoneValid = true;
                this.registerPhoneValidText = "";
                } else {
                const errorCode = this.iti.getValidationError();
                if (errorCode == 1) {
                    this.registerPhoneValidText = this.$t('errors.validation.phoneValidText[1]');
                } else if (errorCode == 2) {
                    this.registerPhoneValidText = this.$t('errors.validation.phoneValidText[2]');
                } else if (errorCode == 3) {
                    this.registerPhoneValidText = this.$t('errors.validation.phoneValidText[3]');
                } else {
                    this.registerPhoneValidText = this.$t('errors.validation.phoneValidText[0]');
                }
                this.isRegisterPhoneValid = false;
                }
            } else {
                this.isRegisterPhoneValid = null;
                this.registerPhoneValidText = "";
            }
        },
        validateNewAddressPhone() {
            if (this.addressData.phone != null && this.addressData.phone != '') {
                if (this.iti2.isValidNumber()) {
                this.isNewAddressPhoneValid = true;
                this.newAddressPhoneValidText = "";
                } else {
                const errorCode = this.iti2.getValidationError();
                if (errorCode == 1) {
                    this.newAddressPhoneValidText = this.$t('errors.validation.phoneValidText[1]');
                } else if (errorCode == 2) {
                    this.newAddressPhoneValidText = this.$t('errors.validation.phoneValidText[2]');
                } else if (errorCode == 3) {
                    this.newAddressPhoneValidText = this.$t('errors.validation.phoneValidText[3]');
                } else {
                    this.newAddressPhoneValidText = this.$t('errors.validation.phoneValidText[0]');
                }
                this.isNewAddressPhoneValid = false;
                }
            } else {
                this.isNewAddressPhoneValid = null;
                this.newAddressPhoneValidText = "";
            }
        },
        validateEditAddressPhone() {
            if (this.editAddressData.inputPhone != null && this.editAddressData.inputPhone != '') {
                if (this.iti3.isValidNumber()) {
                    this.isEditAddressPhoneValid = true;
                    this.editAddressPhoneValidText = "";
                } else {
                    const errorCode = this.iti3.getValidationError();
                    if (errorCode == 1) {
                        this.editAddressPhoneValidText = this.$t('errors.validation.phoneValidText[1]');
                    } else if (errorCode == 2) {
                        this.editAddressPhoneValidText = this.$t('errors.validation.phoneValidText[2]');
                    } else if (errorCode == 3) {
                        this.editAddressPhoneValidText = this.$t('errors.validation.phoneValidText[3]');
                    } else {
                        this.editAddressPhoneValidText = this.$t('errors.validation.phoneValidText[0]');
                    }
                    this.isEditAddressPhoneValid = false;
                }
            } else {
                this.isEditAddressPhoneValid = null;
                this.editAddressPhoneValidText = "";
            }
        },
        isNumber(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        toggleLoginPassword() {
            this.showLoginPassword = !this.showLoginPassword;
        },
        togglePasswordConfirmation() {
            this.showPasswordConfirmation = !this.showPasswordConfirmation;
        },
        togglePassword() {
            this.showPassword = !this.showPassword;
        },
        validatePassword() {
            const regex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
            this.isPasswordValid = regex.test(this.registerFormData.password);
        },
        validatePasswordConfirmation() {
            const regex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
            this.isConfirmationPasswordValid = regex.test(this.registerFormData.password_confirmation);
        },
        async updateCurrency() {
            const {
                success,
                currency
            } = await this.fetchCurrency();
            if (success) {
                if (currency) {
                    if (currency != "" && parseFloat(currency) > 0) {
                        this.currency = currency;
                        return {
                            success: true,
                            currency: currency
                        };
                    } else {
                        return {
                            success: false,
                            currency: currency
                        };
                    }
                } else {
                    return {
                        success: false,
                        currency: null
                    };
                }
            } else {
                return {
                    success: false,
                    currency: null
                };
            }
        },
        changeTotalCargoAmount(amount) {
            this.cargo.total = amount;
            return amount;
        },
        formatPrice(price) {
            if (price) {
                const cleanedPrice = price.replace(/[^\d,.-]/g, '');
                const floatValue = parseFloat(cleanedPrice.replace(',', '.'));

                var decimalPart = (floatValue % 1 === 0) ? '00' : (floatValue % 1).toFixed(2).split('.')[1];

                const integerPart = Math.floor(floatValue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                if (decimalPart == '00') {
                    decimalPart = null;
                }
                const result = `${integerPart}${decimalPart ? ',' + decimalPart : ''}`;

                return result;
            } else {
                return 0;
            }
        },
        generatePayorderID() {
            const newPayorderID = "Z" + Math.floor(Math.random() * 1000000000000).toString();
            return newPayorderID;
        },
        getToken() {
            return localStorage.getItem('token') || null;
        },
        getUserId() {
            return localStorage.getItem('userId') || null;
        },
        async checkoutAndMakeOrder(token, product_id, quantity, unit_price, kargo_price, ship_address, bill_address) {
            try {
                var ship_bill = true;
                if (this.addressSelectType == "both") {
                    ship_bill = true;
                    ship_address = this.addressSelectShipping;
                    bill_address = this.addressSelectBilling;
                } else if (this.addressSelectType == "onebyone") {
                    ship_bill = false;
                    ship_address = this.addressSelectShipping;
                    bill_address = this.addressSelectBilling;
                }

                var payorder_id = JSON.parse(localStorage.getItem('cart'))[0].payorder_id;

                const {
                    success: checkoutSuccess,
                    message,
                    response
                } = await checkoutOnly(token, product_id, quantity, parseFloat(parseFloat((unit_price * parseFloat(this.currency))).toFixed(2)), kargo_price, ship_address, bill_address, ship_bill, payorder_id, parseFloat(this.currency), this.$store.state.price.currencyType);

                if (checkoutSuccess) {
                    if (response.data) {
                        if (response.data.payform) {
                            if (response.data.payform != null) {
                                // const existingPopup = $('#payment-popup');

                                // if (existingPopup.length) {
                                //     existingPopup.remove();
                                // }
                                // $('body').append('<div id="payment-popup">' + response.data.payhtml + '</div>');
                                // if (this.firstPopup) {
                                //     this.firstPopup = false;
                                // } else {
                                //     console.log("31");
                                //     console.log($('#payment-popup').text())
                                //     var scriptFunction = new Function($('#payment-popup').text());
                                //     scriptFunction();
                                //     // eval($('#payment-popup').text());
                                // }
                                
                                this.paymentForm = response.data.payform;
                                // eval($('#payment-popup script').text());
                                window.location.href = this.paymentForm;
                            }
                        }
                    }
                } else {
                    if (response.response.data) {
                        const authTimedOutErrors = ["Invalid token", "User not authorized or auth time out", "customer not activated"];
                        if (response.response.data.error) {
                            if (response.response.data.error == "internal server error" || response.response.data.error == "order already paid or payorderID exists, crete new payorderID" || response.response.data.error.includes('request already in process. Please wait.')) {
                                if (localStorage.getItem('cart')) {
                                    var newPayorderID = this.generatePayorderID();
                                    var currentCartData = JSON.parse(localStorage.getItem('cart'));
                                    if (currentCartData.length > 0) {
                                        currentCartData[0].payorder_id = newPayorderID;
                                        localStorage.setItem('cart', JSON.stringify(currentCartData));
                                        this.finishOrder();
                                    }
                                }
                            } else if (authTimedOutErrors.includes(response.response.data.error)) {
                                this.displayStep(1);
                                authTimedOut(true, '/Checkout');
                            } else if (response.response.data.error == "form Payment initialization was not successful, reason: Fiyat bilgisi 100,000 değerinden fazla veya eşit olamaz") {
                                alert("#orderAlertBox", this.$t('errors.payment.amountLimit'), "danger", "mt-2");
                            }
                        }
                    }
                }

                return {
                    success: checkoutSuccess,
                    message: message,
                    response: response
                };

            } catch (error) {
                this.$showToast("Zerduz", this.$t("paymentResultPage.error.title"), 5000);
            }
        },
        formatDate(date) {
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const year = date.getFullYear();
            return `${day}.${month}.${year}`;
        },
        async deleteAddress() {
            try {
                const {
                    success,
                    message
                } = await deleteAddress(this.getToken(), this.deleteIndex);
                if (success) {
                    const billingIndex = this.addresses.data.billing_address.findIndex(address => address.id === this.deleteIndex);
                    if (billingIndex !== -1) {
                        this.addresses.data.billing_address.splice(billingIndex, 1);
                    }

                    const shippingIndex = this.addresses.data.shipping_address.findIndex(address => address.id === this.deleteIndex);
                    if (shippingIndex !== -1) {
                        this.addresses.data.shipping_address.splice(shippingIndex, 1);
                    }

                } else {
                    this.$showToast("Zerduz", message, 5000);
                }
            } catch (error) {
                this.$showToast("Zerduz", this.$t("errors.api.deleteAddresses.error"), 5000);
            }
        },
        confirmDelete(address_type, index) {
            this.deleteIndex = index;
            address_type = address_type == "billing" ? "billing_address" : "shipping_address";
            const address = this.addresses.data[address_type].find(item => item.id === index);

            if (address) {
                this.deleteAddressData = address;
            }
        },
        flipCard(status) {
            this.isCardFlipped = status;
        },
        focusInput(e) {
            this.isInputFocused = true;
            let targetRef = e.target.dataset.ref;
            let target = this.$refs[targetRef];
            this.focusElementStyle = {
                width: `${target.offsetWidth}px`,
                height: `${target.offsetHeight}px`,
                transform: `translateX(${target.offsetLeft}px) translateY(${target.offsetTop}px)`
            };
        },
        blurInput() {
            let vm = this;
            setTimeout(() => {
                if (!vm.isInputFocused) {
                    vm.focusElementStyle = null;
                }
            }, 300);
            vm.isInputFocused = false;
        },
        toggleActiveCard(index, type = null) {
            if (type != null) {
                type = type == "billing" ? "billing_address" : "shipping_address";
            }
            if (this.addressSelectType == "both") {
                if ((this.addressSelectBilling != null && this.addressSelectShipping == null) || (this.addressSelectBilling == null && this.addressSelectShipping != null) || (this.addressSelectBilling == null && this.addressSelectShipping == null)) {
                    if (this.addressSelectBilling === index) {
                        this.addressSelectBilling = null;
                        $('#address-list-next button').addClass('disabled');
                    } else if (this.addressSelectShipping === index) {
                        this.addressSelectShipping = null;
                        $('#address-list-next button').addClass('disabled');
                    } else {
                        if (type == "billing_address") {
                            this.addressSelectBilling = index;
                            this.addressSelectShipping = null;
                        } else if (type == "shipping_address") {
                            this.addressSelectShipping = index;
                            this.addressSelectBilling = null;
                        }

                        var addressData = this.addresses.data[type].find(item => item.id === index);

                        if (addressData) {
                            this.contractData.first_name = addressData.first_name;
                            this.contractData.last_name = addressData.last_name;
                            this.contractData.phone = addressData.phone;
                            this.contractData.email = this.accountDetails.email;
                            this.contractData.tcno = this.accountDetails.tcno;
                            if (type == "billing_address") {
                                this.contractData.billingAddress.address1 = addressData.address1;
                                this.contractData.billingAddress.city = addressData.city;
                                this.contractData.billingAddress.country = addressData.country;
                                this.contractData.billingAddress.district = addressData.district;
                                this.contractData.billingAddress.state = addressData.state;
                                this.contractData.billingAddress.zipcode = String(addressData.zipcode);
                            } else if (type == "shipping_address") {
                                this.contractData.shippingAddress.address1 = addressData.address1;
                                this.contractData.shippingAddress.city = addressData.city;
                                this.contractData.shippingAddress.country = addressData.country;
                                this.contractData.shippingAddress.district = addressData.district;
                                this.contractData.shippingAddress.state = addressData.state;
                                this.contractData.shippingAddress.zipcode = String(addressData.zipcode);
                            }

                            this.contractData.billingData.first_name = addressData.first_name;
                            this.contractData.billingData.last_name = addressData.last_name;
                            this.contractData.billingData.tcno = this.accountDetails.tcno;
                            this.contractData.billingData.phone = addressData.phone;
                            this.contractData.billingData.email = this.accountDetails.email;
                            this.contractData.billingData.address1 = addressData.address1;
                            this.contractData.billingData.city = addressData.city;
                            this.contractData.billingData.country = addressData.country;
                            this.contractData.billingData.district = addressData.district;
                            this.contractData.billingData.state = addressData.state;
                            this.contractData.billingData.zipcode = String(addressData.zipcode);
                        }

                        $('#address-list-next button').removeClass('disabled');
                    }
                } else {
                    $('#address-list-next button').addClass('disabled');
                }
            } else if (this.addressSelectType == "onebyone") {
                if (type == "billing_address") {
                    if (this.addressSelectBilling == null) {
                        this.addressSelectBilling = index;
                    } else {
                        if (this.addressSelectBilling == index) {
                            this.addressSelectBilling = null;
                            $('#address-list-next button').addClass('disabled');
                        } else {
                            this.addressSelectBilling = index;
                            $('#address-list-next button').addClass('disabled');
                        }
                    }
                } else if (type == "shipping_address") {
                    if (this.addressSelectShipping == null) {
                        this.addressSelectShipping = index;
                    } else {
                        if (this.addressSelectShipping == index) {
                            this.addressSelectShipping = null;
                            $('#address-list-next button').addClass('disabled');
                        } else {
                            this.addressSelectShipping = index;
                            $('#address-list-next button').addClass('disabled');
                        }
                    }
                }
                if (this.addressSelectBilling != null && this.addressSelectShipping != null) {
                    $('#address-list-next button').removeClass('disabled');
                } else if (this.addressSelectBilling == null && this.addressSelectShipping == null && !$('#address-list-next button').hasClass('disabled')) {
                    $('#address-list-next button').addClass('disabled');
                }
            }
        },
        billingCheckboxChange() {
            if (this.billingCheckbox == true) {
                this.addressSelectType = "both";
                if (this.addressSelectBilling != null) {
                    this.addressSelectBilling = null;
                }
                if (this.addressSelectBilling == null && this.addressSelectShipping == null && !$('#address-list-next button').hasClass('disabled')) {
                    $('#address-list-next button').addClass('disabled');
                }
                if ((this.addressSelectBilling != null || this.addressSelectShipping != null) && $('#address-list-next button').hasClass('disabled')) {
                    $('#address-list-next button').removeClass('disabled');
                }
            } else {
                this.addressSelectType = "onebyone";
                if (this.addressSelectBilling != null) {
                    this.addressSelectBilling = null;
                }
                if (this.addressSelectShipping != null) {
                    this.addressSelectShipping = null;
                }
                if ((this.addressSelectBilling == null || this.addressSelectShipping == null) && !$('#address-list-next button').hasClass('disabled')) {
                    $('#address-list-next button').addClass('disabled');
                }
            }
        },
        changeDot(currStep, nextStep) {
            $("#dot-" + currStep).removeClass("text-zerduz");
            $("#dot-" + currStep + " i").removeClass("fa-circle-dot");
            $("#dot-" + currStep).addClass("text-secondary");
            $("#dot-" + currStep + " i").addClass("fa-circle fa-2xs");

            $("#dot-" + nextStep).addClass("text-zerduz");
            $("#dot-" + nextStep + " i").addClass("fa-circle-dot");
            $("#dot-" + nextStep).removeClass("text-secondary");
            $("#dot-" + nextStep + " i").removeClass("fa-circle fa-2xs");
        },
        nextRegisterStep() {
            if (this.registerCurrentStep < 2) {
                $('body').css('overflow-x', 'hidden');
                $(".signup-step-" + this.registerCurrentStep).addClass("animate__animated animate__fadeOutLeft");
                this.registerCurrentStep++;

                if (this.registerCurrentStep === 1) {
                    if ($('.prev-button').css('opacity') !== 0) {
                        $("#prev-button").animate({
                            opacity: 0
                        }, 500);
                    }
                    if ($('.next-button').css('opacity') === 0) {
                        $("#next-button").animate({
                            opacity: 1
                        }, 500);
                    }
                    $("#prev-button").animate({
                        opacity: 0
                    }, 500);
                    $("#next-button").animate({
                        opacity: 1
                    }, 500);
                    this.nextButtonText = this.$t('checkoutPage.nextButtonText[0]');
                    this.headerText = this.$t('checkoutPage.nextButtonText[1]');
                } else if (this.registerCurrentStep === 2) {
                    if ($('.prev-button').css('opacity') === 0) {
                        $("#prev-button").animate({
                            opacity: 1
                        }, 500);
                    }
                    if ($('.next-button').css('opacity') === 0) {
                        $("#next-button").animate({
                            opacity: 1
                        }, 500);
                    }
                    $("#next-button").animate({
                        opacity: 1
                    }, 500);
                    $("#prev-button").animate({
                        opacity: 1
                    }, 500);
                    this.nextButtonText = this.$t('checkoutPage.nextButtonText[1]');
                    this.headerText = this.$t('checkoutPage.nextButtonText[1]');
                }
                setTimeout(() => {
                    this.changeDot(this.registerCurrentStep - 1, this.registerCurrentStep);
                    $(".signup-step").removeClass("animate__animated animate__fadeOutLeft").hide();
                    $(".signup-step-" + this.registerCurrentStep).show().addClass("animate__animated animate__fadeInRight");
                }, 500);
            } else if (this.registerCurrentStep == 2) {
                var registerIsCalled = false;
                $('.login-button-text').fadeOut(async () => {
                    $('.btn-loading').fadeIn();
                    if (this.$refs.registerform.checkValidity()) {
                        if (this.isPasswordValid && this.isConfirmationPasswordValid && ((this.registerFormData.tcno != null && this.registerFormData.tcno != '' && this.isTcNoValid) || (this.registerFormData.tcno == null || this.registerFormData.tcno == '')) && ((this.registerFormData.phone != null && this.registerFormData.phone != '' && this.isRegisterPhoneValid) ||  (this.registerFormData.phone == null || this.registerFormData.phone == ''))) {
                            if (!registerIsCalled) {
                                registerIsCalled = true;
                                this.tryRegister();
                            }
                            destroyAlerts();
                        } else if ((!this.isPasswordValid || !this.isConfirmationPasswordValid || (this.isRegisterPhoneValid == false && (this.registerFormData.phone != null && this.registerFormData.phone != '')))) {
                            destroyAlerts();
                            this.displayRegisterStep(1, true);
                            $('.btn-loading').fadeOut(200, function () {
                                $('.login-button-text').fadeIn();
                            });
                        } else {
                            destroyAlerts();
                            $('.btn-loading').fadeOut(200, function () {
                                $('.login-button-text').fadeIn();
                            });
                        }
                    } else {
                        var invalidFields = Array.from(this.$refs.registerform.elements).filter(field => !field.checkValidity());
                        var requiredFields = ['name', 'surname', 'password', 'password_confirmation'];
                        if (this.registerFormData.phone == '' && this.registerFormData.email != '') {
                            requiredFields.push('email');
                        } else if ((this.registerFormData.phone != '' && this.registerFormData.email == '')) {
                            requiredFields.push('phone');
                        } else {
                            requiredFields.push('phone');
                        }
                        var goToFirstStep = false;

                        invalidFields.forEach(item => {
                            if (requiredFields.includes(item.id)) {
                                goToFirstStep = true;
                            }
                        });
                        if (goToFirstStep == true) {
                            this.displayRegisterStep(1, false);
                            $('.btn-loading').fadeOut(200, function () {
                                $('.login-button-text').fadeIn();
                            });
                        }
                        this.$refs.registerform.reportValidity();
                    }
                });
            }
        },
        prevRegisterStep() {
            if (this.registerCurrentStep > 1) {
                $('body').css('overflow-x', 'hidden');
                $(".signup-step-" + this.registerCurrentStep).addClass("animate__animated animate__fadeOutRight");
                this.registerCurrentStep--;
                if (this.registerCurrentStep === 1) {
                    if ($('.prev-button').css('opacity') !== 0) {
                        $("#prev-button").animate({
                            opacity: 0
                        }, 500);
                    }
                    if ($('.next-button').css('opacity') === 0) {
                        $("#next-button").animate({
                            opacity: 1
                        }, 500);
                    }
                    $("#prev-button").animate({
                        opacity: 0
                    }, 500);
                    $("#next-button").animate({
                        opacity: 1
                    }, 500);
                    this.nextButtonText = this.$t('checkoutPage.nextButtonText[0]');
                    this.headerText = this.$t('checkoutPage.nextButtonText[1]');
                } else if (this.registerCurrentStep === 2) {
                    if ($('.prev-button').css('opacity') === 0) {
                        $("#prev-button").animate({
                            opacity: 1
                        }, 500);
                    }
                    if ($('.next-button').css('opacity') === 0) {
                        $("#next-button").animate({
                            opacity: 1
                        }, 500);
                    }
                    $("#next-button").animate({
                        opacity: 1
                    }, 500);
                    $("#prev-button").animate({
                        opacity: 1
                    }, 500);
                    this.nextButtonText = this.$t('checkoutPage.nextButtonText[2]');
                    this.headerText = this.$t('checkoutPage.nextButtonText[1]');
                }
               setTimeout(() => {
                    this.changeDot(this.registerCurrentStep + 1, this.registerCurrentStep);
                    $(".signup-step").removeClass("animate__animated animate__fadeOutRight").hide();

                    var data = {
                        step: "checkout/registerFirstStep",
                        data: { operation: "view14", stepNumber: this.currentStep }
                    };
                    this.$sendFunnel(data, this.$i18n.locale);

                    $(".signup-step-" + this.registerCurrentStep).show().addClass("animate__animated animate__fadeInLeft");
                }, 500);
            }
        },
        registerFormGoBack() {
            this.registerCurrentStep = 1;
            $(".form-signup").addClass("animate__animated animate__fadeOutRight");
            setTimeout(() => {
                $(".form-signup").removeClass("animate__animated animate__fadeOutRight").hide();

                var data = {
                    step: "checkout/login",
                    data: { operation: "view24", stepNumber: this.currentStep }
                };
                this.$sendFunnel(data, this.$i18n.locale);
                
                $(".form-signin").show().addClass("animate__animated animate__fadeInLeft");
            }, 500);
        },
        displayRegisterStep(stepNumber, animation = true) {
            if (stepNumber === 2 || stepNumber === 1) {
                if (stepNumber === 1) {
                    if ($('.prev-button').css('opacity') !== 0) {
                        $("#prev-button").animate({
                            opacity: 0
                        }, 500);
                    }
                    if ($('.next-button').css('opacity') === 0) {
                        $("#next-button").animate({
                            opacity: 1
                        }, 500);
                    }
                    $("#prev-button").animate({
                        opacity: 0
                    }, 500);
                    $("#next-button").animate({
                        opacity: 1
                    }, 500);
                    this.nextButtonText = this.$t('checkoutPage.nextButtonText[0]');
                } else if (stepNumber === 2) {
                    if ($('.prev-button').css('opacity') === 0) {
                        $("#prev-button").animate({
                            opacity: 1
                        }, 500);
                    }
                    if ($('.next-button').css('opacity') === 0) {
                        $("#next-button").animate({
                            opacity: 1
                        }, 500);
                    }
                    $("#next-button").animate({
                        opacity: 1
                    }, 500);
                    $("#prev-button").animate({
                        opacity: 1
                    }, 500);
                    this.nextButtonText = this.$t('checkoutPage.nextButtonText[2]');
                }
                if (animation) {
                    $('body').css('overflow-x', 'hidden');
                    $(".signup-step-" + this.registerCurrentStep).addClass("animate__animated animate__fadeOutLeft");
                    setTimeout(() => {
                        this.changeDot(this.registerCurrentStep, stepNumber);
                        $(".signup-step").removeClass("animate__animated animate__fadeOutLeft").hide();

                        var data = {
                            step: (stepNumber == 2) ? "checkout/registerSecondStep" : "checkout/registerFirstStep",
                            data: { operation: "view22", stepNumber: stepNumber }
                        };
                        this.$sendFunnel(data, this.$i18n.locale);
                        $(".signup-step-" + stepNumber).show().addClass("animate__animated animate__fadeInRight");
                        this.registerCurrentStep = stepNumber;
                    }, 500);
                } else {
                    if (stepNumber >= 1 && stepNumber <= 3) {
                        $(".signup-step-" + this.registerCurrentStep).hide();

                        var data = {
                            step: (stepNumber == 2) ? "checkout/registerSecondStep" : "checkout/registerFirstStep",
                            data: { operation: "view23", stepNumber: stepNumber }
                        };
                        this.$sendFunnel(data, this.$i18n.locale);

                        $(".signup-step-" + stepNumber).show();
                        this.registerCurrentStep = stepNumber;
                    }
                }
            }
        },
        async displayStep(stepNumber, animation = true) {
            if (stepNumber >= 1 && stepNumber <= 3) {
                if (this.$store.getters.isAuthenticated) {
                    if (stepNumber === 3 || stepNumber === 2) {
                        if (stepNumber === 3) {
                            if (this.addressSelectType == "both" && (this.addressSelectShipping == null && this.addressSelectBilling == null) ) {
                                $('.step-' + this.currentStep).addClass('shake-animation');

                                setTimeout(() => {
                                    $('.step-' + this.currentStep).removeClass('shake-animation');
                                }, 1000);
                            } else if (this.addressSelectType == "onebyone" && this.addressSelectBilling == null && this.addressSelectShipping == null) {
                                $('.step-' + this.currentStep).addClass('shake-animation');

                                setTimeout(() => {
                                    $('.step-' + this.currentStep).removeClass('shake-animation');
                                }, 1000);
                            } else {
                                if (this.addressSelectType == "both" && (this.addressSelectShipping != null || this.addressSelectBilling != null)) {
                                    if (animation) {
                                        const {
                                            success,
                                            currency,
                                            message
                                        } = await this.fetchCurrency();
                                        if (success) {
                                            if (currency != "" && parseFloat(currency) > 0) {
                                                this.currency = currency;
                                                setTimeout(() => {
                                                    $(".step").removeClass("animate__animated animate__fadeOutLeft").hide();
                                                    $(".step-" + stepNumber).show().addClass("animate__animated animate__fadeInRight");
                                                    
                                                    var data = {
                                                        step: "checkout/finishOrder",
                                                        data: { operation: "view1", stepNumber: stepNumber }
                                                    };
                                                    this.$sendFunnel(data, this.$i18n.locale);

                                                    $(".step-" + stepNumber).on('animationend', function () {
                                                        $(this).removeClass("animate__animated animate__fadeInRight");
                                                    });
                                                    this.currentStep = stepNumber;
                                                    this.updateProgressBar();
                                                }, 500);
                                            } else {
                                                alert("#currency-alertbox", message, "danger", "mt-2");
                                            }
                                        } else {
                                            const {
                                                success: successAgain,
                                                currency: currencyAgain,
                                                message: messageAgain
                                            } = await this.fetchCurrency();
                                            if (successAgain) {
                                                if (currencyAgain != "" && parseFloat(currencyAgain) > 0) {
                                                    this.currency = currencyAgain;
                                                    $(".step-" + this.currentStep).addClass("animate__animated animate__fadeOutLeft");
                                                    setTimeout(() => {
                                                        $(".step").removeClass("animate__animated animate__fadeOutLeft").hide();
                                                        $(".step-" + stepNumber).show().addClass("animate__animated animate__fadeInRight");

                                                        var data = {
                                                            step: "checkout/finishOrder",
                                                            data: { operation: "view2", stepNumber: stepNumber }
                                                        };
                                                        this.$sendFunnel(data, this.$i18n.locale);

                                                        $(".step-" + stepNumber).on('animationend', function () {
                                                            $(this).removeClass("animate__animated animate__fadeInRight");
                                                        });
                                                        this.currentStep = stepNumber;
                                                        this.updateProgressBar();
                                                    }, 500);
                                                } else {
                                                    alert("#currency-alertbox", messageAgain, "danger", "mt-2");
                                                }
                                            } else {
                                                alert("#currency-alertbox", messageAgain, "danger", "mt-2");
                                            }
                                        }
                                    }   
                                } else if (this.addressSelectType == "onebyone" && (this.addressSelectBilling != null && this.addressSelectShipping != null)) {
                                    if (animation) {
                                        $(".step-" + this.currentStep).addClass("animate__animated animate__fadeOutLeft");
                                        setTimeout(() => {
                                            $(".step").removeClass("animate__animated animate__fadeOutLeft").hide();
                                            $(".step-" + stepNumber).show().addClass("animate__animated animate__fadeInRight");

                                            var data = {
                                                step: "checkout/finishOrder",
                                                data: { operation: "view3", stepNumber: stepNumber }
                                            };
                                            this.$sendFunnel(data, this.$i18n.locale);

                                            $(".step-" + stepNumber).on('animationend', function () {
                                                $(this).removeClass("animate__animated animate__fadeInRight");
                                            });
                                            this.currentStep = stepNumber;
                                            this.updateProgressBar();
                                        }, 500);
                                    }
                                } else {
                                    $('.step-' + this.currentStep).addClass('shake-animation');

                                    setTimeout(() => {
                                        $('.step-' + this.currentStep).removeClass('shake-animation');
                                    }, 1000);
                                }
                            }
                        } else {
                            if (animation) {
                                $(".step-" + this.currentStep).addClass("animate__animated animate__fadeOutLeft");
                                setTimeout(() => {
                                    $(".step").removeClass("animate__animated animate__fadeOutLeft").hide();
                                    $(".step-" + stepNumber).show().addClass("animate__animated animate__fadeInRight");

                                    var data = {
                                        step: "checkout/addressSelect",
                                        data: { operation: "view4", stepNumber: stepNumber }
                                    };
                                    this.$sendFunnel(data, this.$i18n.locale);

                                    $(".step-" + stepNumber).on('animationend', function () {
                                        $(this).removeClass("animate__animated animate__fadeInRight");
                                    });
                                    this.currentStep = stepNumber;
                                    this.updateProgressBar();
                                }, 500);
                            } else {
                                if (stepNumber >= 1 && stepNumber <= 3) {
                                    $(".step-" + this.currentStep).hide();

                                    var data = {
                                        step: "checkout/addressSelect",
                                        data: { operation: "view5", stepNumber: stepNumber }
                                    };
                                    this.$sendFunnel(data, this.$i18n.locale);

                                    $(".step-" + stepNumber).show();
                                    this.currentStep = stepNumber;
                                    this.updateProgressBar();
                                }
                            }
                        }
                    } else {
                        $('.step-' + this.currentStep).addClass('shake-animation');

                        setTimeout(() => {
                            $('.step-' + this.currentStep).removeClass('shake-animation');
                        }, 1000);
                    }
                } else {
                    if (stepNumber == 1 && this.currentStep == 3) {
                        $(".step-" + this.currentStep).addClass("animate__animated animate__fadeOutLeft");
                        setTimeout(() => {
                            $(".step").removeClass("animate__animated animate__fadeOutLeft").hide();
                            $(".step-" + stepNumber).show().addClass("animate__animated animate__fadeInRight");

                            var data = {
                                step: "checkout/login",
                                data: { operation: "view6", stepNumber: stepNumber }
                            };
                            this.$sendFunnel(data, this.$i18n.locale);

                            $(".step-" + stepNumber).on('animationend', function () {
                                $(this).removeClass("animate__animated animate__fadeInRight");
                            });
                            this.currentStep = stepNumber;
                            this.updateProgressBar();
                        }, 500);
                    } else {
                        $('.step-' + this.currentStep).addClass('shake-animation');

                        setTimeout(() => {
                            $('.step-' + this.currentStep).removeClass('shake-animation');
                        }, 1000);
                    }
                }
            }
        },
        async fetchCurrency() {
            try {
                const {
                    success,
                    currency
                } = await USDCurrency(this.$store.state.price.currencyType);
                if (success) {
                    return {
                        success,
                        currency
                    };
                } else {
                    return {
                        message: this.$t('errors.payment.errorOccuredWhenFetchingCurrency')
                    };
                }
            } catch (error) {
                return {
                    message: this.$t('errors.payment.errorOccuredWhenFetchingCurrency')
                };
            }
        },
        async nextStep() {
            if (this.currentStep < 3) {
                if (this.currentStep == 2) {
                    const {
                        success,
                        currency,
                        message
                    } = await this.fetchCurrency();
                    if (success) {
                        if (currency != "" && parseFloat(currency) > 0) {
                            this.currency = currency;
                            $(".step-" + this.currentStep).addClass("animate__animated animate__fadeOutLeft");
                            this.currentStep++;
                            this.interval = setInterval(this.updateCurrency, 7500);
                            setTimeout(() => {
                                $(".step").removeClass("animate__animated animate__fadeOutLeft").hide();
                                $(".step-" + this.currentStep).show().addClass("animate__animated animate__fadeInRight");

                                var data = {
                                    step: "checkout/finishOrder",
                                    data: { operation: "view7", stepNumber: this.currentStep }
                                };
                                this.$sendFunnel(data, this.$i18n.locale);

                                $(".step-" + this.currentStep).on('animationend', function () {
                                    $(this).removeClass("animate__animated animate__fadeInRight");
                                });
                                this.updateProgressBar();
                            }, 500);
                        } else {
                            alert("#currency-alertbox", message, "danger", "mt-2");
                        }
                    } else {
                        const {
                            success: successAgain,
                            currency: currencyAgain,
                            message: messageAgain
                        } = await this.fetchCurrency();
                        if (successAgain) {
                            if (currencyAgain != "" && parseFloat(currencyAgain) > 0) {
                                this.currency = currencyAgain;
                                $(".step-" + this.currentStep).addClass("animate__animated animate__fadeOutLeft");
                                this.currentStep++;
                                this.interval = setInterval(this.updateCurrency, 7500);
                                setTimeout(() => {
                                    $(".step").removeClass("animate__animated animate__fadeOutLeft").hide();
                                    $(".step-" + this.currentStep).show().addClass("animate__animated animate__fadeInRight");

                                    var data = {
                                        step: "checkout/finishOrder",
                                        data: { operation: "view8", stepNumber: this.currentStep }
                                    };
                                    this.$sendFunnel(data, this.$i18n.locale);

                                    $(".step-" + this.currentStep).on('animationend', function () {
                                        $(this).removeClass("animate__animated animate__fadeInRight");
                                    });
                                    this.updateProgressBar();
                                }, 500);
                            } else {
                                alert("#currency-alertbox", messageAgain, "danger", "mt-2");
                            }
                        } else {
                            alert("#currency-alertbox", messageAgain, "danger", "mt-2");
                        }
                    }
                } else {
                    $(".step-" + this.currentStep).addClass("animate__animated animate__fadeOutLeft");
                    this.currentStep++;
                    setTimeout(() => {
                        $(".step").removeClass("animate__animated animate__fadeOutLeft").hide();
                        $(".step-" + this.currentStep).show().addClass("animate__animated animate__fadeInRight");
                        
                        var data = {
                            step: "checkout/addressSelect",
                            data: { operation: "view9", stepNumber: this.currentStep }
                        };
                        this.$sendFunnel(data, this.$i18n.locale);

                        $(".step-" + this.currentStep).on('animationend', function () {
                            $(this).removeClass("animate__animated animate__fadeInRight");
                        });
                        this.updateProgressBar();
                    }, 500);
                }
            }
        },
        prevStep() {
            if (this.currentStep > 1) {
                if (this.$store.getters.isAuthenticated) {
                    if (this.currentStep === 3) {
                        $(".step-" + this.currentStep).addClass("animate__animated animate__fadeOutRight");
                        this.currentStep--;
                        setTimeout(() => {
                            $(".step").removeClass("animate__animated animate__fadeOutRight").hide();
                            $(".step-" + this.currentStep).show().addClass("animate__animated animate__fadeInLeft");

                            var data = {
                                step: "checkout/addressSelect",
                                data: { operation: "view10", stepNumber: this.currentStep }
                            };
                            this.$sendFunnel(data, this.$i18n.locale);

                            $(".step-" + this.currentStep).on('animationend', function () {
                                $(this).removeClass("animate__animated animate__fadeInLeft");
                            });
                            this.updateProgressBar();
                        }, 500);
                    }
                } else {
                    $(".step-" + this.currentStep).addClass("animate__animated animate__fadeOutRight");
                    this.currentStep--;
                    setTimeout(() => {
                        $(".step").removeClass("animate__animated animate__fadeOutRight").hide();
                        $(".step-" + this.currentStep).show().addClass("animate__animated animate__fadeInLeft");

                        var data = {
                            step: "checkout/login",
                            data: { operation: "view11", stepNumber: this.currentStep }
                        };
                        this.$sendFunnel(data, this.$i18n.locale);

                        $(".step-" + this.currentStep).on('animationend', function () {
                            $(this).removeClass("animate__animated animate__fadeInLeft");
                        });
                        this.updateProgressBar();
                    }, 500);
                }
            }
        },
        updateProgressBar() {
            var progressPercentage = ((this.currentStep - 1) / 2) * 100;
            $(".progress-bar").css("width", progressPercentage + "%");
        },
        showRegisterForm() {
            $(".form-signin").addClass("animate__animated animate__fadeOutRight");
            setTimeout(() => {
                $(".form-signin").removeClass("animate__animated animate__fadeOutRight").hide();
                var funnelData = {
                    step: "checkout/register",
                    data: { operation: "view13", stepNumber: this.currentStep }
                };
                this.$sendFunnel(funnelData, this.$i18n.locale);
                $(".form-signup").show().addClass("animate__animated animate__fadeInLeft");
            }, 500);
        },
        async tryRegister() {
            var pore = 0;
            if (this.registerFormData.email && this.registerFormData.phone) {
                pore = 1;
            } else if (this.registerFormData.phone && !this.registerFormData.email) {
                pore = 1;
            } else if (!this.registerFormData.phone && this.registerFormData.email) {
                pore = 0;
            }   
    
            if (this.registerFormData.phone.length > 0) {
                if (this.registerFormData.phone.charAt(0) === '0') {
                    this.registerFormData.phone = this.registerFormData.phone.slice(1);
                }
                var selectedCountryData = this.iti.getSelectedCountryData();
                var countryDialCode = selectedCountryData.dialCode;
                this.formattedPhoneNumber = `${countryDialCode}${this.registerFormData.phone}`;
                this.formattedPhoneNumber = this.formattedPhoneNumber.replace(/\s/g, '');
            }

            const {
                success,
                message
            } = await register(
                this.registerFormData.name,
                this.registerFormData.surname,
                this.registerFormData.email,
                this.registerFormData.password,
                this.registerFormData.password_confirmation,
                String(this.registerFormData.tcno),
                this.formattedPhoneNumber,
                parseInt(this.registerFormData.accounttype),
                parseInt(this.registerFormData.taxno),
                this.registerFormData.tax,
                pore
            );

            if (success) {
                alert("#alertbox", message, "success", "mt-2");
            } else {
                alert("#alertbox", message, "danger", "mt-2");
            }
            $('.btn-loading').fadeOut(200, function () {
                $('.login-button-text').fadeIn();
            });
        },
        async tryLogin() {
            var loginIsCalled = false;
            $('.login-button-text').fadeOut(async () => {
                $('.btn-loading').fadeIn();
                if (!loginIsCalled) {
                    loginIsCalled = true;
                    if (this.isLoginInputValid) {
                        var phone = this.loginFormData.phone;
                        if (phone.length > 0) {
                            if (phone.charAt(0) === '0') {
                                phone = phone.slice(1);
                            }
                            if (phone.charAt(0) === '+') {
                                phone = phone.slice(1);
                            }
                            phone = phone.trim().replace(/\s/g, '');
                        }
                        if ((this.loginFormData.mail && this.loginFormData.mail != '') || (phone && phone != '')) {
                            if (this.loginFormData.password && this.loginFormData.password != '') {
                                const {
                                    success,
                                    message,
                                    token,
                                    userId,
                                    first_name,
                                    last_name,
                                    cart
                                } = await login(this.loginFormData.mail, phone, this.loginFormData.password);
                                if (success) {
                                    window.localStorage.setItem('token', token);
                                    window.localStorage.setItem('userId', userId);
                                    this.token = token;
                                    this.userId = userId;

                                    var expirationDate = new Date();
                                    if (this.loginFormData.rememberme) {
                                        expirationDate.setDate(expirationDate.getDate() + 30);
                                    } else {
                                        expirationDate.setDate(expirationDate.getDate() + 2);
                                    }

                                    this.cartData = JSON.parse(localStorage.getItem('cart') || '[]');
                                    var operation = true;
                                    var currentQuantity = parseInt(this.cartData[0].cartQuantity);
                                    if (cart && cart.length > 0) {
                                        if (cart[0].quantity > 0) {
                                            if (this.cartData[0].cartQuantity > cart[0]) {
                                                var index = this.cartData[0].cartQuantity - cart[0].quantity;
                                                currentQuantity = index;
                                                operation = true;
                                            } else if (this.cartData[0].cartQuantity < cart[0].quantity) {
                                                index = this.cartData[0].cartQuantity - cart[0].quantity;
                                                currentQuantity = this.cartData[0].cartQuantity;
                                                operation = false;
                                            }
                                        }
                                    }
                                    await updateCart(token, 1, currentQuantity, this.cartData[0].payorder_id, operation);
                                    
                                    window.localStorage.setItem('expirationDate', expirationDate.getTime());
                                    var userName = success ? (first_name && last_name ? `${first_name} ${last_name}` : first_name) : '';
                                    this.$store.dispatch('login', {
                                        token: token,
                                        userId: userId,
                                        username: userName,
                                        expirationdate: expirationDate.getTime()
                                    });
                                    alert("#remember-me-box", message, "success", "mt-3");

                                    this.fetchAccountData(token);
                                    this.fetchAdresses();

                                    setTimeout(() => {
                                        this.nextStep();
                                    }, 2000, this);
                                } else {
                                    alert("#remember-me-box", message, "danger", "mt-2");
                                }
                            } else {
                                alert("#remember-me-box", this.$t('errors.login.passwordCantBeEmpty'), "danger", "mt-2");
                            }
                        } else {
                            alert("#remember-me-box", this.$t('errors.login.emailCantBeEmpty'), "danger", "mt-2");
                        }
                    } else {
                        alert("#remember-me-box", this.$t('errors.login.inputCheckInputError'), "danger", "mt-2");
                    }
                }
                
                $('.btn-loading').fadeOut(function () {
                    $('.login-button-text').fadeIn();
                });
            });
        },
        async fetchAdresses() {
            try {
                const {
                    success,
                    data,
                    message
                } = await getAddresses(this.getToken());
                if (success) {
                    this.addresses = data;
                } else {
                    this.$showToast("Zerduz", message, 5000);
                }
            } catch (error) {
                this.$showToast("Zerduz", this.$t("errors.api.getAddresses.error"), 5000);
            }
        },
        async saveAddress() {
            if (this.isNewAddressPhoneValid) {
                if (this.addressData.phone.length > 0) {
                    if (this.addressData.phone.charAt(0) === '0') {
                        this.addressData.phone = this.addressData.phone.slice(1);
                    }
                    var selectedCountryData = this.iti.getSelectedCountryData();
                    var countryDialCode = selectedCountryData.dialCode;
                    this.formattedPhoneNumber = `${countryDialCode}${this.addressData.phone}`;
                    this.formattedPhoneNumber = this.formattedPhoneNumber.replace(/\s/g, '');
                }

                const {
                    success,
                    message,
                    response
                } = await addAddress(this.getToken(), this.addressData.address_name, this.addressData.type, this.addressData.address1, this.addressData.city, this.addressData.state, this.addressData.district, String(this.addressData.zipcode), this.addressData.country_code, this.addressData.user_id, this.addressData.first_name, this.addressData.last_name, this.formattedPhoneNumber, this.addressData.default, this.addressData.selectedCountry);
                if (success) {
                    if (response.data.type) {
                        var newAddressObject = {
                            id: response.data.ID,
                            customer_id: response.data.customer_id,
                            user_id: response.data.user_id,
                            address_name: response.data.address_name,
                            address1: response.data.address1,
                            country: response.data.country,
                            first_name: response.data.first_name,
                            last_name: response.data.last_name,
                            phone: response.data.phone,
                            city: response.data.city,
                            state: response.data.state,
                            district: response.data.district,
                            zipcode: response.data.zipcode,
                            type: response.data.type,
                        };
                        if (response.data.type == "shipping") {
                            this.addresses.data.shipping_address.push(newAddressObject);
                        } else if (response.data.type == "billing") {
                            this.addresses.data.billing_address.push(newAddressObject);
                        }
                    }
                    setTimeout(() => {
                        if ($('.add-address').css('display') !== 'none') {
                            $(".add-address").addClass("animate__animated animate__fadeOutRight");
                            setTimeout(() => {
                                $(".add-address").removeClass("animate__animated animate__fadeOutRight").hide();

                                var data = {
                                    step: "checkout/addressSelect",
                                    data: { operation: "view18", stepNumber: this.currentStep }
                                };
                                this.$sendFunnel(data, this.$i18n.locale);

                                $(".addresses-list").show().addClass("animate__animated animate__fadeInLeft");
                                $('.addresses-list').on('animationend', function () {
                                    $(this).removeClass("animate__animated animate__fadeInLeft");
                                });
                            }, 500);
                        }
                    }, 5000);
                    alert("#alertbox-addaddress", message, "success", "mt-2");
                } else {
                    alert("#alertbox-addaddress", message, "danger", "mt-2");
                }
            } else {
                this.$refs.newAddressPhone.focus();
                alert("#alertbox-addaddress", this.newAddressPhoneValidText, "danger", "mt-2");
            }
        },
        async editAddress() {
            if (this.isEditAddressPhoneValid || this.isEditAddressPhoneValid == null) {
                if (this.editAddressData.inputPhone.length > 0) {
                    if (this.editAddressData.inputPhone.charAt(0) === '0') {
                        this.editAddressData.inputPhone = this.editAddressData.inputPhone.slice(1);
                    }
                    var selectedCountryData = this.iti.getSelectedCountryData();
                    var countryDialCode = selectedCountryData.dialCode;
                    this.formattedPhoneNumber = `${countryDialCode}${this.editAddressData.inputPhone}`;
                    this.formattedPhoneNumber = this.formattedPhoneNumber.replace(/\s/g, '');
                }
                const {
                    success,
                    message
                } = await updateAddress(this.getToken(), this.editIndex, this.editAddressData.address_name, this.editAddressData.type, this.editAddressData.address1, this.editAddressData.city, this.editAddressData.state, this.editAddressData.district, String(this.editAddressData.zipcode), this.editAddressData.country_code, this.editAddressData.first_name, this.editAddressData.last_name, this.formattedPhoneNumber, this.editAddressSelectedCountry, false);
                if (success) {
                    var address_type = this.editAddressData.type == "billing" ? "billing_address" : "shipping_address";
                    var address = this.addresses.data[address_type].find(item => item.id === this.editAddressData.id);

                    if (address) {
                        this.editAddressData.phone = this.formattedPhoneNumber;
                        address = this.editAddressData;
                        address.country = this.editAddressSelectedCountry;
                    }
                    setTimeout(() => {
                        if ($('.edit-address').css('display') !== 'none') {
                            $(".edit-address").addClass("animate__animated animate__fadeOutRight");
                            $(".edit-address").addClass("animate__animated animate__fadeOutRight");
                            setTimeout(() => {
                                $(".edit-address").removeClass("animate__animated animate__fadeOutRight").hide();

                                var data = {
                                    step: "checkout/addressSelect",
                                    data: { operation: "view19", stepNumber: this.currentStep }
                                };
                                this.$sendFunnel(data, this.$i18n.locale);

                                $(".addresses-list").show().addClass("animate__animated animate__fadeInLeft");
                            }, 500);
                        }
                    }, 5000);
                    alert("#alertbox-editaddress", message, "success", "mt-2");
                } else {
                    alert("#alertbox-editaddress", message, "danger", "mt-2");
                }
            } else {
                this.$refs.newAddressPhone.focus();
                alert("#alertbox-addaddress", this.newAddressPhoneValidText, "danger", "mt-2");
            }
        },
        async finishOrder() {
            if (this.sozlesmeCheckbox) {
                if (this.getToken() != null && this.getUserId() != null) {
                    var data = {
                        step: "checkout/finishOrderBtn",
                        data: { operation: "view20", stepNumber: this.currentStep }
                    };
                    this.$sendFunnel(data, this.$i18n.locale);

                    if (this.finishOrderClicked == false) {
                        this.finishOrderClicked = true;
                        clearInterval(this.interval);
                    } else if (this.finishOrderClicked == true) {
                        const {
                            success: currencySuccess
                        } = this.updateCurrency();
                        if (currencySuccess == false) {
                            this.updateCurrency();
                        }
                    }

                    var ship_address = this.addressSelectShipping;
                    var bill_address = this.addressSelectBilling;
                    
                    const {
                        success,
                        response
                    } = this.checkoutAndMakeOrder(this.getToken(), 1, this.totalQuantity, this.$store.state.price.price.tax, parseFloat((this.calculateCargo * parseFloat(this.currency)).toFixed(2)), ship_address, bill_address);
                    if (success == true) {
                        if (response.data.info == "ORDER CREATED ") {
                            data = {
                                step: "checkout/finishOrderShowPaymentForm",
                                data: { operation: "view21", stepNumber: this.currentStep }
                            };
                            this.$sendFunnel(data, this.$i18n.locale);
                            this.paymentForm = response.data.payform;
                            // eval(document.getElementById('payment-popup').textContent);
                            window.location.href = this.paymentForm;
                            // $('body').append(response.data.payform);
                        }
                    }
                } else {
                    this.displayStep(1);
                }
            } else {
                alert("#orderAlertBox", this.$t('errors.payment.agreementShouldBeConfirmed'), "danger", "mt-2");
            }
        },
        showAddAddressForm(homepage = false) {
            if (homepage == false) {
                $(".addresses-list").addClass("animate__animated animate__fadeOutRight");
                setTimeout(() => {
                    $(".addresses-list").removeClass("animate__animated animate__fadeOutRight").hide();
                    $(".add-address").show().addClass("animate__animated animate__fadeInLeft");

                    var data = {
                        step: "checkout/addAddress",
                        data: { operation: "view15", stepNumber: this.currentStep }
                    };
                    this.$sendFunnel(data, this.$i18n.locale);

                    $('.add-address').on('animationend', function () {
                        $(this).removeClass("animate__animated animate__fadeInLeft");
                    });
                }, 500);
            } else {
                $(".add-address").addClass("animate__animated animate__fadeOutRight");
                setTimeout(() => {
                    $(".add-address").removeClass("animate__animated animate__fadeOutRight").hide();
                    $(".addresses-list").show().addClass("animate__animated animate__fadeInLeft");

                    var data = {
                        step: "checkout/addressSelect",
                        data: { operation: "view16", stepNumber: this.currentStep }
                    };
                    this.$sendFunnel(data, this.$i18n.locale);

                    $('.addresses-list').on('animationend', function () {
                        $(this).removeClass("animate__animated animate__fadeInLeft");
                    });
                }, 500);
            }
        },
        showEditAddressForm(homepage = false, type, index) {
            if (type && index) {
                this.editIndex = index;
                type = type == "billing" ? "billing_address" : "shipping_address";
                const address = this.addresses.data[type].find(item => item.id === index);

                if (address) {
                    this.editAddressData = address;
                    this.editAddressSelectedCountry = address.country;

                    const telInput = this.iti3;
                    if (address.phone != null && address.phone != "") {
                        try {
                            const incomingPhoneNumber = "+" + address.phone;
                            const phoneNumber = parsePhoneNumber(incomingPhoneNumber);
                            telInput.setCountry(phoneNumber.country);
                            this.editAddressData.inputPhone = phoneNumber.nationalNumber;
                            telInput.setNumber(phoneNumber.nationalNumber);
                        } catch (e) {
                            this.editAddressData.inputPhone = "+" + address.phone;
                        }
                        
                    }
                }
            }

            if (homepage == false && type && index) {
                $(".addresses-list").addClass("animate__animated animate__fadeOutRight");
                setTimeout(() => {
                    $(".addresses-list").removeClass("animate__animated animate__fadeOutRight").hide();

                    var data = {
                        step: "checkout/editAddress",
                        data: { operation: "view16", stepNumber: this.currentStep }
                    };
                    this.$sendFunnel(data, this.$i18n.locale);

                    $(".edit-address").show().addClass("animate__animated animate__fadeInLeft");
                }, 500);
            } else {
                $(".edit-address").addClass("animate__animated animate__fadeOutRight");
                setTimeout(() => {
                    $(".edit-address").removeClass("animate__animated animate__fadeOutRight").hide();

                    var data = {
                        step: "checkout/addressSelect",
                        data: { operation: "view17", stepNumber: this.currentStep }
                    };
                    this.$sendFunnel(data, this.$i18n.locale);

                    $(".addresses-list").show().addClass("animate__animated animate__fadeInLeft");
                }, 500);
            }
        },
        async fetchAccountData(token) {
            const {
                success,
                data
            } = await getAccount(token);
            if (success) {
                this.accountDetails = data;
            }
        },
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from
        })
    },
    unmounted() {
        clearInterval(this.interval);
    },
    async mounted() {
        if (this.token != null && this.userId != null) {
            const {
                success,
                data
            } = await getCartData(this.token);
            if (success == true) {
                this.fetchAccountData(this.token);
                if (data.data.cartQuantity == 0) {
                    var cartData = localStorage.getItem('cart');
                    if (!cartData) {
                        if (this.prevRoute && this.prevRoute.path) {
                            this.$router.push('/Purchase');
                        }
                    }
                } else {
                    try {
                        const {
                            success,
                            data,
                            message
                        } = await getAddresses(this.token);
                        if (success) {
                            this.addresses = data;
                        } else {
                            this.$showToast("Zerduz", message, 5000);
                        }
                    } catch (error) {
                        this.$showToast("Zerduz", this.$t("errors.api.getAddresses.error"), 5000);
                    }
                }
            } else {
                this.$store.dispatch('logout');
                localStorage.removeItem('token');
                localStorage.removeItem('userId');
                localStorage.removeItem('expirationDate');
                localStorage.removeItem('cart');
                this.$store.dispatch('changeCartItem', {
                    cart: undefined
                });
                if (this.$route.meta.requiresLogin) {
                    this.$router.push('/login');
                }
            }
        } else {
            const cartData = localStorage.getItem('cart');
            if (cartData == null) {
                if (this.prevRoute && this.prevRoute.path) {
                    this.$router.push('/Purchase');
                }
            }
        }
        this.cardNumberTemp = this.otherCardMask;

        if (this.Countries) {
            this.addressData.countries = this.Countries;
            this.addressData.countries.sort((a, b) => {
                const nameA = a.name.common.toLowerCase();
                const nameB = b.name.common.toLowerCase();
                return nameA.localeCompare(nameB);
            });
        }

        var input = document.querySelector(this.phoneInputID);
        this.iti = window.intlTelInput(input, {
            autoPlaceholder: "polite",
            customPlaceholder: function(placeholder) {
                if (placeholder.charAt(0) === '0') {
                return placeholder.slice(1);
                } else {
                return placeholder;
                }
            },
            initialCountry: "auto",
            preferredCountries: ["tr"],
            geoIpLookup: callback => {
                fetch("https://ipapi.co/json")
                    .then(res => res.json())
                    .then(data => {
                        this.currentCountryISO3 = String(data.country_code_iso3).toLowerCase() || "tur";
                        callback(data.country_code);
                    })
                    .catch(() => {
                        this.currentCountryISO3 = "";
                        callback("tr");
                    });
            },
            utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/18.1.5/js/utils.js"
        });

        input = document.querySelector(this.phoneInputID2);
        this.iti2 = window.intlTelInput(input, {
            autoPlaceholder: "polite",
            customPlaceholder: function(placeholder) {
                if (placeholder.charAt(0) === '0') {
                return placeholder.slice(1);
                } else {
                return placeholder;
                }
            },
            initialCountry: "auto",
            preferredCountries: ["tr"],
            geoIpLookup: callback => {
                fetch("https://ipapi.co/json")
                .then(res => res.json())
                .then(data => callback(data.country_code))
                .catch(() => callback("tr"));
            },
            utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/18.1.5/js/utils.js"
        });

        input = document.querySelector(this.phoneInputID3);
        this.iti3 = window.intlTelInput(input, {
            autoPlaceholder: "polite",
            customPlaceholder: function(placeholder) {
                if (placeholder.charAt(0) === '0') {
                return placeholder.slice(1);
                } else {
                return placeholder;
                }
            },
            initialCountry: "auto",
            preferredCountries: ["tr"],
            geoIpLookup: callback => {
                fetch("https://ipapi.co/json")
                .then(res => res.json())
                .then(data => callback(data.country_code))
                .catch(() => callback("tr"));
            },
            utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/18.1.5/js/utils.js"
        });
        $('input[type=radio][name=flexRadioDefault]').change(function () {
            var verginoTxtBox = $('#verginoTxtBox');
            var vergidairesiTxtBox = $('#vergidairesiTxtBox');
            verginoTxtBox.css('animation-duration', '0.5s');
            vergidairesiTxtBox.css('animation-duration', '0.5s');
            if (this.id == 'personalAccount') {
                verginoTxtBox.removeClass('animate__animated animate__fadeIn');
                vergidairesiTxtBox.removeClass('animate__animated animate__fadeIn');
                verginoTxtBox.addClass('animate__animated animate__fadeOut');
                vergidairesiTxtBox.addClass('animate__animated animate__fadeOut');
            } else if (this.id == 'corporateAccount') {
                verginoTxtBox.css('display', 'block');
                vergidairesiTxtBox.css('display', 'block');
                verginoTxtBox.removeClass('animate__animated animate__fadeOut');
                vergidairesiTxtBox.removeClass('animate__animated animate__fadeOut');
                verginoTxtBox.addClass('animate__animated animate__fadeIn');
                vergidairesiTxtBox.addClass('animate__animated animate__fadeIn');
            }
        });

        if (this.$store.getters.isAuthenticated) {
            $('#loading-spinner').fadeOut(async () => { 
                $('#multi-step-form').find('.step').slice(1).hide();
                this.displayStep(2, false);
            });
        } else {
            // console.log("2")
            $('#loading-spinner').fadeOut(async () => { 
                $('.login').fadeIn(async () => {
                   $('#multi-step-form').find('.step').slice(1).hide();
                    var funnelData = {
                        step: "checkout/login",
                        data: { operation: "view12", stepNumber: this.currentStep }
                    };
                    this.$sendFunnel(funnelData, this.$i18n.locale); 
                });
            });
        }
    }
};
</script>

<style scoped src="../assets/css/checkout.css"/>
<style scoped>
@import '../assets/css/intlTelInput.css';
</style>
