import axios from 'axios';
import { authTimedOut } from "@/helpers/funcs";
import { i18n } from '@/main';
const BASE_URL = 'https://backend.zerduz.com';
const ZERDUZ_API = 'https://api.zerduz.com/back';
const headers = {
  'Content-Type': 'application/json'
};

var lang = (data) => { 
  return i18n.global.t(data);
};

const authTimedOutErrors = ["Invalid token", "User not authorized or auth time out", "customer not activated", "Token has expired"];

export const login = async (email, phone, password) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/customer/login`, { email, phone, password }, { headers: headers });
    return { success: true, message: lang("errors.api.login.success"), token: response.data.data.token, userId: response.data.data.user.id, first_name: response.data.data.user.first_name, last_name: response.data.data.user.last_name, zid: response.data.data.user.zid, cart: response.data.data.cart, payorder_id: response.data.data.cart };
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      if (error.response.data.error === "user email not activated, link has been sent") {
        return { success: false, message: lang("errors.api.login.emailNotActivated") };
      } else if (error.response.data.error === "previous link is still valid, check your email, if you need new link enter your email.") {
        return { success: false, message: lang("errors.api.login.previosLinkIsStillValid") };
      } else if (error.response.data.error === "User not found" && error.response.status === 500) {
        return { success: false, message: lang("errors.api.login.userNotFound") };
      } else if (error.response.data.error === "email or password mismatch") {
        return { success: false, message: lang("errors.api.login.emailOrPasswordMismatch") };
      } else if (error.response.data.error === "wrong password or email") {
        return { success: false, message: lang("errors.api.login.emailOrPasswordMismatch") };
      } else {
        return { success: false, message: lang("errors.api.login.anErrorOccuredWhenLogin"), error: error };
      }
    } else {
      return { success: false, message: lang("errors.api.login.anErrorOccuredWhenLogin"), error: error };
    }
  }
};

export const register = async (name, surname, email, password, password_confirmation, tcno, phone, accounttype, taxno, taxoffice, pore) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/customer/register`, { name, surname, email, password, password_confirmation, tcno, phone, pore, accounttype, taxoffice, taxno }, { headers: headers });
    if (pore == 1) {
      return { success: true, message: lang("errors.api.register.success[0]"), data: response.data.data };
    } else {
      return { success: true, message: lang("errors.api.register.success[1]"), data: response.data.data };
    }
  } catch (error) {
    if (error.response.data.error == "not same password") {
      return { success: false, message: lang("errors.api.register.passwordAreNotSame"), error: error };
    } else if (error.response.data.error == "user with the same email already exists") {
      return { success: false, message: lang("errors.api.register.sameEmailAlreadyExists"), error: error };
    } else if (error.response.data.error == "user with the same phone already exists") {
      return { success: false, message: lang("errors.api.register.samePhoneAlreadyExists"), error: error };
    } else if (error.response.data.error == "user already exist !") {
      return { success: false, message: lang("errors.api.register.sameUserAlreadyExists"), error: error };
    } else if (error.response.data.error == "Internal error ") {
      return { success: false, message: lang("errors.api.register.internalError"), error: error };
    } else if (error.response.data.error == "user with TCNO already exists") {
      return { success: false, message: lang("errors.api.register.sameIDAlreadyExists"), error: error };
    } else if (error.response.status === 500) {
      return { success: false, message: lang("errors.api.register.internalError"), error: error };
    } else {
      return { success: false, message: lang("errors.api.register.internalError"), error: error };
    }
  }
};

export const getCartData = async (token) => {
  try {
    var headerWithToken = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    const response = await axios.get(`${BASE_URL}/api/customer/cart`, { headers: headerWithToken });
    return { success: true, message: lang("errors.api.getCart.success"), data: response.data, response: response };
  } catch (error) {
    if (authTimedOutErrors.includes(error.response.data.error)) {
      authTimedOut();
    }
    return { success: false, message: lang("errors.api.getCart.error"), error: error };
  }
};

export const addToCart = async (token, product_id, quantity, payorder_id, operation) => {
  try {
    var headerWithToken = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    const response = await axios.post(`${BASE_URL}/api/customer/cart`, { product_id, quantity, payorder_id, operation }, { headers: headerWithToken });
    return { success: true, message: lang("errors.api.addToCart.success"), payorder_id: response.data.payorder_id };
  } catch (error) {
    if (authTimedOutErrors.includes(error.response.data.error)) {
      authTimedOut();
    }
    return { success: false, message: lang("errors.api.addToCart.error"), error: error };
  }
};

export const updateCart = async (token, product_id, quantity, payorder_id, operation) => {
  try {
    var headerWithToken = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    const response = await axios.post(`${BASE_URL}/api/customer/cart`, { product_id, quantity, payorder_id, operation }, { headers: headerWithToken });
    return { success: true, message: lang("errors.api.updateCart.success"), token: response.data.token, payorder_id: response.data.payorder_id };
  } catch (error) {
    if (authTimedOutErrors.includes(error.response.data.error)) {
      authTimedOut();
    }
    return { success: false, message: lang("errors.api.updateCart.error"), error: error };
  }
};

export const requestPasswordReset = async (email) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/customer/reset-password`, { email }, { headers: headers });
    return { success: true, message: lang("errors.api.requestPasswordReset.success"), response: response };
  } catch (error) {
    if (error.response && error.response.status === 400) {
      return { success: false, message: lang("errors.api.requestPasswordReset.error[0]"), error: error };
    } else {
      return { success: false, message: lang("errors.api.requestPasswordReset.error[1]"), error: error };
    }
  }
};

export const confirmCode = async (code) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/customer/confirm-code`, { code }, { headers: headers });
    return { success: true, message: lang("errors.api.confirmCode.success"), response: response };
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return { success: false, message: lang("errors.api.confirmCode.error[0]") };
    } else {
      return { success: false, message: lang("errors.api.confirmCode.error[1]"), error: error };
    }
  }
};

export const resetPassword = async (token, password) => {
  try {
    var headerWithToken = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    const response = await axios.post(`${BASE_URL}/api/customer/new-password`, { password }, { headers: headerWithToken });
    return { success: true, message: lang("errors.api.resetPassword.success"), code: response };
  } catch (error) {
    if (authTimedOutErrors.includes(error.response.data.error)) {
      authTimedOut();
    }
    if (error.response && error.response.status === 400) {
      return { success: false, message: lang("errors.api.resetPassword.error[0]"), error: error };
    } else {
      return { success: false, message: lang("errors.api.resetPassword.error[1]"), error: error };
    }
  }
};

export const verifyEmail = async (code) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/customer/verify-email`, { code }, { headers: headers });
    return { success: true, message: lang("errors.api.verifyEmail.success"), response: response };
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return { success: false, message: lang("errors.api.verifyEmail.error[0]"), response: error.response };
    } else {
      return { success: false, message: lang("errors.api.verifyEmail.error[1]"), response: error.response };
    }
  }
};

export const verifySms = async (code) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/customer/verify-sms`, { code }, { headers: headers });
    return { success: true, message: lang("errors.api.verifySms.success"), response: response };
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return { success: false, message: lang("errors.api.verifySms.error[0]"), response: error.response, error: error };
    } else {
      return { success: false, message: lang("errors.api.verifySms.error[1]"), response: error.response, error: error };
    }
  }
};

export const resendSmsVerification = async (phone) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/customer/resend-verify-sms`, { phone }, { headers: headers });
    return { success: true, message: lang("errors.api.resendSmsVerification.success"), response: response };
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return { success: false, message: lang("errors.api.resendSmsVerification.error[0]"), response: error.response, error: error };
    } else {
      return { success: false, message: lang("errors.api.resendSmsVerification.error[1]"), response: error.response, error: error };
    }
  }
};

export const resendEmailVerification = async (code) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/customer/resend-verify-email`, { code }, { headers: headers });
    return { success: true, message: lang("errors.api.resendEmailVerification.success"), response: response };
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return { success: false, message: lang("errors.api.resendEmailVerification.error[0]"), response: error.response, error: error };
    } else {
      return { success: false, message: lang("errors.api.resendEmailVerification.error[1]"), response: error.response, error: error };
    }
  }
};

export const getApps = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/api/customer/apps`, { headers: headers });
    return { success: true, message: lang("errors.api.getApps.success"), apps: response.data };
  } catch (error) {
    return { success: false, message: lang("errors.api.getApps.error"), error: error };
  }
};

export const logout = async (token) => {
  try {
    var headerWithToken = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    const response = await axios.get(`${BASE_URL}/api/customer/logout`, { headers: headerWithToken });
    return { success: true, message: lang("errors.api.logout.success"), response: response };
  } catch (error) {
    if (authTimedOutErrors.includes(error.response.data.error)) {
      authTimedOut();
    }
    return { success: false, message: lang("errors.api.logout.error"), error: error };
  }
};

export const getAccount = async (token) => {
  try {
    var headerWithToken = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    const response = await axios.get(`${BASE_URL}/api/customer/account`, { headers: headerWithToken });
    return { success: true, message: lang("errors.api.getAccount.success"), data: response.data.data };
  } catch (error) {
    if (authTimedOutErrors.includes(error.response.data.error)) {
      authTimedOut();
    }
    return { success: false, message: lang("errors.api.getAccount.error"), error: error };
  }
};

export const getOrders = async (token) => {
  try {
    var headerWithToken = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    const response = await axios.get(`${BASE_URL}/api/customer/orders`, { headers: headerWithToken });
    return { success: true, message: lang("errors.api.getOrders.success"), data: response.data.data };
  } catch (error) {
    if (authTimedOutErrors.includes(error.response.data.error)) {
      authTimedOut();
    }
    return { success: false, message: lang("errors.api.getOrders.error"), error: error };
  }
};

export const getOrder = async (token, payorder_id) => {
  try {
    var headerWithToken = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    const response = await axios.get(`${BASE_URL}/api/customer/orders/${payorder_id}`, { headers: headerWithToken });
    return { success: true, message: lang("errors.api.getOrder.success"), data: response.data.data };
  } catch (error) {
    if (authTimedOutErrors.includes(error.response.data.error)) {
      authTimedOut();
    }
    return { success: false, message: lang("errors.api.getOrder.error"), error: error };
  }
};

export const getCompanyInfo = async (token) => {
  try {
    var headerWithToken = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    const response = await axios.get(`${BASE_URL}/api/customer/account/company`, { headers: headerWithToken });
    return { success: true, message: lang("errors.api.getCompanyInfo.success"), data: response.data };
  } catch (error) {
    if (authTimedOutErrors.includes(error.response.data.error)) {
      authTimedOut();
    }
    return { success: false, message: lang("errors.api.getCompanyInfo.error"), error: error };
  }
};

export const getAddresses = async (token) => {
  try {
    var headerWithToken = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    const response = await axios.get(`${BASE_URL}/api/customer/adressess`, { headers: headerWithToken });
    return { success: true, message: lang("errors.api.getAddresses.success"), data: response };
  } catch (error) {
    if (authTimedOutErrors.includes(error.response.data.error)) {
      authTimedOut();
    }
    return { success: false, message: lang("errors.api.getAddresses.error"), error: error };
  }
};

export const updateAddress = async (token, id, address_name, type, address1, city, state, district, zipcode, country_code, first_name, last_name, phone, country, defaultVar) => {
  try {
    var headerWithToken = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    const response = await axios.post(`${BASE_URL}/api/customer/adressess/update/${id}`, { address_name, type, address1, city, state, district, zipcode, country_code, first_name, last_name, phone, country, default: defaultVar }, { headers: headerWithToken });
    return { success: true, message: lang("errors.api.updateAddress.success"), data: response };
  } catch (error) {
    if (authTimedOutErrors.includes(error.response.data.error)) {
      authTimedOut();
    }
    return { success: false, message: lang("errors.api.updateAddress.error"), error: error };
  }
};

export const deleteAddress = async (token, index) => {
  try {
    var headerWithToken = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    const response = await axios.get(`${BASE_URL}/api/customer/adressess/delete/${index}`, { headers: headerWithToken });
    return { success: true, message: lang("errors.api.deleteAddress.success"), data: response };
  } catch (error) {
    if (authTimedOutErrors.includes(error.response.data.error)) {
      authTimedOut();
    }
    return { success: false, message: lang("errors.api.deleteAddress.error"), error: error };
  }
};

export const getCountries = async () => {
  try {
    const response = await axios.get('https://restcountries.com/v3.1/all?fields=name,translations,cca3', { headers: headers });
    // const response = await axios.get('https://restcountries.com/v3.1/all', { headers: headers });
    return { success: true, data: response.data };
  } catch (error) {
    if (authTimedOutErrors.includes(error.response.data.error)) {
      authTimedOut();
    }
    return { message: lang("errors.api.getCountries.error"), error: error };
  }
};

export const updatePassword = async (token, oldpassword, password) => {
  try {
    var headerWithToken = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    const response = await axios.post(`${BASE_URL}/api/customer/update-password`, { oldpassword, password }, { headers: headerWithToken });
    return { success: true, message: lang("errors.api.updatePassword.success"), response: response };
  } catch (error) {
    if (authTimedOutErrors.includes(error.response.data.error)) {
      authTimedOut();
    }
    if (error.response.data.error == "Old password incorrect") {
      return { success: false, message: lang("errors.api.updatePassword.error[0]"), error: error };
    } else { 
      return { success: false, message: lang("errors.api.updatePassword.error[1]"), error: error };
    }
  }
};

export const updateAccount = async (token, first_name, last_name, tcno, email, phone, business_type, tax_no, tax_office, profession, account_type) => {
  try {
    var headerWithToken = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    const response = await axios.post(`${BASE_URL}/api/customer/account`, { first_name, last_name, tcno, email, phone, business_type, tax_no, tax_office, profession, account_type }, { headers: headerWithToken });
    return { success: true, message: lang("errors.api.updateAccount.success"), response: response };
  } catch (error) {
    if (authTimedOutErrors.includes(error.response.data.error)) {
      authTimedOut();
    }
    if (error.response.data.error == "Phone number is already in use") {
      return { success: false, message: lang("errors.api.updateAccount.error[0]"), error: error };
    } else {
      return { success: false, message: lang("errors.api.updateAccount.error[1]"), error: error };
    }
  }
};

export const getZerduzCurrencySettings = async (token, zid) => {
  try {
    var headerWithToken = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    const response = await axios.get(`${ZERDUZ_API}/getsettings/${zid}`, { headers: headerWithToken });
    return { success: true, message: lang("errors.api.getZerduzCurrencySettings.success"), response: response };
  } catch (error) {
    if (authTimedOutErrors.includes(error.response.data.error)) {
      authTimedOut();
    }
    return { success: false, message: lang("errors.api.getZerduzCurrencySettings.error"), error: error };
  }
};

export const updateZerduzCurrencySettings = async (token, zid, user_currency ) => {
  try {
    var headerWithToken = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    const response = await axios.post(`${ZERDUZ_API}/updatesettings/${zid}`, { user_currency }, { headers: headerWithToken });
    return { success: true, message: lang("errors.api.updateZerduzCurrencySettings.success"), response: response };
  } catch (error) {
    if (authTimedOutErrors.includes(error.response.data.error)) {
      authTimedOut();
    }
    return { success: false, message: lang("errors.api.updateZerduzCurrencySettings.error"), error: error };
  }
};

export const updateCompany = async (token, company, brand, subscribe, company_phone ) => {
  try {
    var headerWithToken = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    const response = await axios.post(`${BASE_URL}/api/customer/account/company`, { company, brand, subscribe, company_phone  }, { headers: headerWithToken });
    return { success: true, message: lang("errors.api.updateCompany.success"), response: response };
  } catch (error) {
    if (error.response && error.response.length > 0) {
      if (error.response) {
        if (error.response.data.error) {
          if (authTimedOutErrors.includes(error.response.data.error)) {
            authTimedOut();
          }
        }
      }
    }
    return { success: false, message: lang("errors.api.updateCompany.error"), error: error };
  }
};

export const updateLogo = async (token, logo ) => {
  try {
    var headerWithToken = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    };
    const response = await axios.post(`${BASE_URL}/api/customer/account/logo`, { logo  }, { headers: headerWithToken });
    return { success: true, message: lang("errors.api.updateLogo.success"), response: response };
  } catch (error) {
    if (error.response && error.response.length > 0) {
      if (error.response) {
        if (error.response.data.error) {
          if (authTimedOutErrors.includes(error.response.data.error)) {
            authTimedOut();
          }
        }
      }
    }
    return { success: false, message: lang("errors.api.updateLogo.error"), error: error };
  }
};

export const addAddress = async (token, address_name, type, address1, city, state, district, zipcode, country_code, user_id, first_name, last_name, phone, defaultVar, country) => {
  try {
    var headerWithToken = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    const response = await axios.post(`${BASE_URL}/api/customer/adressess`, { address_name, type, address1, city, state, district, zipcode, country_code, user_id, first_name, last_name, phone, default: defaultVar, country }, { headers: headerWithToken });
    return { success: true, message: lang("errors.api.addAddress.success"), response: response };
  } catch (error) {
    if (authTimedOutErrors.includes(error.response.data.error)) {
      authTimedOut();
    }
    return { success: false, message: lang("errors.api.addAddress.error"), error: error };
  }
};

export const createOrder = async (token, user_id, ship_address, bill_address, product_id, quantity, unit_price, total_price, NewAddr) => {
  try {
    var headerWithToken = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    if (NewAddr) {
      var response = await axios.post(`${BASE_URL}/api/customer/orders`, { user_id, ship_address, bill_address, product_id, quantity, unit_price, total_price, NewAddr }, { headers: headerWithToken });
    } else {
      response = await axios.post(`${BASE_URL}/api/customer/orders`, { user_id, ship_address, bill_address, product_id, quantity, unit_price, total_price }, { headers: headerWithToken });
    }
    return { success: true, message: lang("errors.api.createOrder.success"), response: response };
  } catch (error) {
    if (authTimedOutErrors.includes(error.response.data.error)) {
      authTimedOut();
    }
    return { success: false, message: lang("errors.api.createOrder.error"), error: error };
  }
};

export const checkout = async (token, product_id, quantity, unit_price, kargo_price, ip, ship_address, bill_address, ship_bill, payorder_id, usd_kur, currency) => {
  try {
    var headerWithToken = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    const response = await axios.post(`${BASE_URL}/api/customer/checkout`, { product_id, quantity, unit_price, kargo_price, ip, ship_address, bill_address, ship_bill, payorder_id, usd_kur, currency }, { headers: headerWithToken });
    return { success: true, message: lang("errors.api.checkout.success"), response: response };
  } catch (error) {
    if (authTimedOutErrors.includes(error.response.data.error)) {
      authTimedOut();
    }
    return { success: false, message: lang("errors.api.checkout.error"), error: error };
  }
};

export const checkoutOnly = async (token, product_id, quantity, unit_price, kargo_price, ship_address, bill_address, ship_bill, payorder_id, usd_kur, currency) => {
  try {
    const ipData = await fetch("https://ipapi.co/json").then(res => res.json());
    const { success, message: checkoutMessage, response, error } = await checkout(token, product_id, quantity, unit_price, kargo_price, ipData.ip, ship_address, bill_address, ship_bill, payorder_id, usd_kur, currency);
    if (success) {
      //$('body').append(response.data.payform);
      return { success: true, message: checkoutMessage, response: response };
    } else {
      return { success: false, message: checkoutMessage, response: error };
    }
  } catch (error) {
    if (authTimedOutErrors.includes(error.response.data.error)) {
      authTimedOut();
    }
    console.error('Error fetching data:', error);
    return { success: false, message: lang("errors.api.checkoutOnly.error"), error: error };
  }
}

export const checkoutResult = async (token, id) => {
  try {
    var headerWithToken = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    const response = await axios.post(`${BASE_URL}/api/customer/checkout/result`, { id }, { headers: headerWithToken });
    return { success: true, message: lang("errors.api.checkoutResult.success"), response: response };
  } catch (error) {
    if (authTimedOutErrors.includes(error.response.data.error)) {
      authTimedOut();
    }
    return { success: false, message: lang("errors.api.checkoutResult.error"), error: error };
  }
};

export const getOpenPositions = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/api/customer/positions`, { headers: headers });
    return { success: true, message: lang("errors.api.getOpenPositions.success"), response: response };
  } catch (error) {
    return { success: false, message: lang("errors.api.getOpenPositions.error"), error: error };
  }
};

export const sendContactForm = async (first_name, last_name, email, message) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/customer/contact`, { first_name, last_name, email, message }, { headers: headers });
    return { success: true, message: lang("errors.api.sendContactForm.success"), response: response };
  } catch (error) {
    return { success: false, message: lang("errors.api.sendContactForm.error"), error: error };
  }
};

export const jobApply = async (first_name, last_name, email, phone, pid, cv) => {
  try {
    var fileHeader = {
      'Content-Type': 'multipart/form-data',
    };
    const response = await axios.post(`${BASE_URL}/api/customer/applyjob`, { first_name, last_name, email, phone, pid, cv }, { headers: fileHeader });
    return { success: true, message: lang("errors.api.jobApply.success"), response: response };
  } catch (error) {
    return { success: false, message: lang("errors.api.jobApply.error"), error: error };
  }
};

export const USDCurrency = async (currency) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/customer/tcmb?c=${currency}`, { headers: headers });
    return { success: true, message: lang("errors.api.USDCurrency.success"), currency: response.data.KUR };
  } catch (error) {
    return { success: false, message: lang("errors.api.USDCurrency.error"), error: error };
  }
};

// USER TRACKING

export const addTrackingData = async (data, token, tracking_id, user_id) => {
  try {
    var headerWithToken = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    const response = await axios.post(`${BASE_URL}/api/customer/usertracking`, { data, tracking_id, user_id }, { headers: headerWithToken });
    return { success: true, message: 'Tracking data eklendi.', payorder_id: response.data.payorder_id };
  } catch (error) {
    // if (authTimedOutErrors.includes(error.response.data.error)) {
    //   authTimedOut();
    // }
    return { success: false, message: 'Tracking data eklenemedi.', error: error };
  }
};

// get price

export const getPrices = async (id, country) => {
  try {
    var query = `?p=${id}`;
    if (country) {
      if (country != 'select') {
        query += `&c=${country}`;
      }
    }
    const response = await axios.get(`${BASE_URL}/api/customer/prices${query}`, { headers: headers });
    return { success: true, message: "price success", data: response.data };
  } catch (error) {
    if (authTimedOutErrors.includes(error.response.data.error)) {
      authTimedOut();
    }
    return { success: false, message: "price error", error: error };
  }
};

export const getGuides = async (lang) => {
  try {
    if (lang == "tr") {
      lang = "tur";
    } else if (lang == "en") {
      lang = "eng";
    } else if (lang == "fr") {
      lang = "fra";
    } else if (lang == "ar") {
      lang = "ara";
    }

    const response = await axios.get(`${BASE_URL}/api/customer/guidelang/${lang}`, { headers: headers });
    return { success: true, message: "guides success", response: response };
  } catch (error) {
    if (authTimedOutErrors.includes(error.response.data.error)) {
      authTimedOut();
    }
    return { success: false, message: "guides error", error: error };
  }
};

export const getGuide = async (lang, slug) => {
  try {
    if (lang == "tr") {
      lang = "tur";
    } else if (lang == "en") {
      lang = "eng";
    } else if (lang == "fr") {
      lang = "fra";
    } else if (lang == "ar") {
      lang = "ara";
    }

    const response = await axios.get(`${BASE_URL}/api/customer/guidelangslug/${lang}/${slug}`, { headers: headers });
    return { success: true, message: "guide success", response: response };
  } catch (error) {
    if (authTimedOutErrors.includes(error.response.data.error)) {
      authTimedOut();
    }
    return { success: false, message: "guide error", error: error };
  }
};