<template>
<div>
    <div v-if="!loading">
        <div class="px-4 pt-2 text-center">
            <h1 class="text-center mt-5 p-1">{{ guideData?.title }}</h1>
            <div class="col-lg-6 d-flex justify-content-center mx-auto">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item mx-auto">
                            <router-link to="/Guide">{{ $t('guidePage.header') }}</router-link>
                        </li>
                        <li class="breadcrumb-item active mx-auto" aria-current="page">{{ guideData?.title }}</li>
                    </ol>
                </nav>
            </div>
        </div>

        <div class="container mb-4 mx-auto">
            <div>
                <div class="row flex-lg-row-reverse align-items-center p-5">
                    <div class="col-10 col-sm-8 col-lg-6 mx-auto">
                        <iframe class="shadow" width="100%" height="315" :src="guideData?.video + '?modestbranding=0&showinfo=0'" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    <div class="col-lg-6" v-html="guideData.content"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="text-center mt-5 loading-section" v-if="loading">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">{{ $t('loadingText') }}</span>
        </div>
    </div>
</div>
</template>

<script>
import {
    getGuide
} from '@/helpers/api';

export default {
    name: "GuideDetails",
    data() {
        return {
            guideData: null,
            loading: true
        }
    },
    async mounted() {
        const slug = this.$route.params.slug;

        if (slug) {
            const {
                success,
                response
            } = await getGuide(this.$i18n.locale, slug);

            if (success) {
                if (response && response.data) {
                    this.guideData = response.data;

                    this.$nextTick(() => {
                        this.loading = false;

                        document.title = this.guideData.title + " - Zerduz";
                    });
                } else {
                    this.$router.push('/Guide');
                }
            } else {
                this.$router.push('/Guide');
            }
        } else {
            this.$router.push('/Guide');
        }
    }
};
</script>

<style scoped src="../assets/css/guide.css"/>
