import router from '../router'
import DetectOS from 'detectos.js'
import { addTrackingData } from '../helpers/api'
const Detect = new DetectOS();

var key = [];
var metadata = [];
var uuid = null;

document.onkeydown = function (e) {
    e = e || window.event;
    var charCode = e.keyCode || e.which;
    var charStr = String.fromCharCode(charCode);
    key = [{ keyCode: charCode, keyStr: charStr, multipleKey: [{ctrl: e.ctrlKey ? 'ctrl' : '', shift: e.shiftKey ? 'shift' : '', alt: e.altKey ? 'alt' : '', meta: e.metaKey ? 'meta' : '', key: ["Control", "Shift", "Alt", "Meta"].includes(e.key) ? "" : (e.keyCode < 58 && e.keyCode > 47 ? e.keyCode - 48 : String.fromCharCode(e.keyCode))}] }];
};

document.onkeyup = function () {
    key = [];
};

export const isMobile = () => {
    var check = false;
    (function (a) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw(n|u)|c55\/|capi|ccwa|cdm|cell|chtm|cldc|cmd|co(mp|nd)|craw|da(it|ll|ng)|dbte|dcs|devi|dica|dmob|do(c|p)o|ds(12|d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(|_)|g1 u|g560|gene|gf5|gmo|go(\.w|od)|gr(ad|un)|haie|hcit|hd(m|p|t)|hei|hi(pt|ta)|hp( i|ip)|hsc|ht(c(| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i(20|go|ma)|i230|iac( ||\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|[a-w])|libw|lynx|m1w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|mcr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|([1-8]|c))|phil|pire|pl(ay|uc)|pn2|po(ck|rt|se)|prox|psio|ptg|qaa|qc(07|12|21|32|60|[2-7]|i)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h|oo|p)|sdk\/|se(c(|0|1)|47|mc|nd|ri)|sgh|shar|sie(|m)|sk0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h|v|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl|tdg|tel(i|m)|tim|tmo|to(pl|sh)|ts(70|m|m3|m5)|tx9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas|your|zeto|zte/i.test(a.substr(0, 4)))
            check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
};

export const checkTraceable = (node, i) => {
    var result = true;

    if (!result && node.parentNode != null && i <= 5) {
        return checkTraceable(node.parentNode, i++);
    }
    return result;
};

export const sendEvent = async (event) => {
    // console.log("sending: ", event);
    // const { message } = await addTrackingData(JSON.stringify(event), window.localStorage.getItem('token'), event.id, parseInt(window.localStorage.getItem('userId')));
    await addTrackingData(JSON.stringify(event), window.localStorage.getItem('token'), event.id, parseInt(window.localStorage.getItem('userId')));
    // console.log("Msg:", message);
};

export const getAnalyticsBody = (type, funnel, locale) => {
    const leaveTime = new Date();
    const timeSpent = leaveTime - window.enterTime;
    const timeSpentSeconds = Math.round(timeSpent / 1000);

    // console.log("time spent", timeSpentSeconds);

    var eventTime = window.enterTime.toISOString().slice(0, 21);

    var data = {
        "id": uuid,
        "url": window.location.href,
            "time": {
                date: String(window.enterTime),
                timestamp: Date.now(),
                formattedDate: window.enterTime.toLocaleString((metadata ? (metadata.languages && metadata.languages != "") : false) ? metadata.languages.split(',').length > 0 ? metadata.languages.split(',')[0] : 'tr-TR' : 'tr-TR', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                    timeZoneName: 'short'
                }),
                enterTime: window.enterTime,
                leaveTime: leaveTime,
                timeSpent: timeSpentSeconds
            },
            "event": {
                "event_id": document.title + "_" + type + "_" + eventTime,
                "event_type": type,
                "event_time": window.enterTime.toISOString(),
            },
            "page_title": document.title,
            "page_path": router.currentRoute.value.path,
            "page_fullpath": router.currentRoute.value.fullPath,
            "user": {
                "user": { token: window.localStorage.getItem("token"), userId: window.localStorage.getItem("userId") },
                "cart": JSON.parse(window.localStorage.getItem("cart")),
                "user_agent": window.navigator.userAgent,
                "user_os": window.navigator.platform,
                "user_exact_os": Detect.OS,
                "user_browser": Detect.browser,
                "user_browser_version": Detect.version,
                "locale": locale ? locale : null
            },
            "is_mobile": isMobile(),
            "funnel": funnel ? funnel : null
    };

    if (metadata && metadata != [] && metadata != null) {
        data.user.user_ip = metadata.ip;
        data.user.user_city = metadata.city;
        data.user.user_country_code = metadata.country_code;
        data.user.user_country_name = metadata.country_name;
        data.user.user_country_region = metadata.region;
        data.user.user_continent = metadata.continent_code;
        data.user.user_timezone = metadata.timezone;
        data.user.user_languages = metadata.languages;
        data.user.user_in_eu = metadata.in_eu;
    }
    return data;
};

export const sendViewEvent = (locale) => {
    var data = getAnalyticsBody("view", null, locale);
    sendEvent(data);
};

export const sendFunnel = (funnel, locale) => {
    var data = getAnalyticsBody("funnel", funnel, locale);
    sendEvent(data);
    window.enterTime = new Date();
};

export const sendClickEvent = (e) => {

    if (checkTraceable(e.target, 5)) {
        var data = getAnalyticsBody("click");

        data.cursor = { x: e.clientX, y: e.clientY };
        data.keyCode = key;

        data.event.click_text = (e.target.textContent.trim() !== '' && e.target.firstChild.nodeType === Node.TEXT_NODE) ? e.target.firstChild.textContent.trim() : '';
        data.event.click_id = e.target.id;
        data.event.click_class = e.target.className;

        sendEvent(data);
    }
}

export const fetchUserData = (userUUID) => {
    uuid = userUUID;
    fetch('https://ipapi.co/json').then(function (response) {
        return response.json();
    }).then(d => {
        metadata = d;
        
        // var data = getAnalyticsBody("view");

        // sendEvent(data);
    }).catch(e => {
        console.error("IP Error: ", e);
        metadata = [];
        
        // var data = getAnalyticsBody("view");

        // sendEvent(data);
    });
};